<template>
    <div>
        <div v-if="!editKeyOpenLabel">
            <h2 style="text-align: center;">Trend Options</h2>
        <table style="height: 100%; width: 100%;">
            <tr>
                <th v-for="col of columns">
                    {{ col }}
                </th>
            </tr>
            <tr v-for="key of keys" >
                <td @click="handleeditLabel(key['key'], key['label'])"  v-for="col of columns">
                    <div class="" v-if="col==''">
                        <input v-model="selectedKey" type="radio" name="keys" :value="key.key"/>
                    </div>
                    <div style="cursor: pointer;" v-else-if="col=='key'">
                        {{ key[col.toLowerCase()] }}
                    </div>
                    <!-- <div class="" v-else-if="col=='label*'">
                    </div> -->
                    <div v-else>
                        {{ key[col.toLowerCase()] }}
                    </div>
                </td>
            </tr>
        </table>
        <div class="" style="margin-top: 40px;"></div>
        <span  v-if="selectedKey"  style="margin-top: 45px;font-size: 14px;font-weight: 600;">Label</span>
        <span style="color: red !important;">*</span>
        <input v-if="selectedKey"  v-model="newLabel" style="min-height: 50px;display: block;padding: 8px 40px 0 8px;border-radius: 5px;border: 1px solid #e8e8e8;background: #fff;margin-top: 10px; font-size: 14px;margin-right: auto;width: 100%;" placeholder="Enter label" type="text"/>

        <div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 7%;" class="">
            <button @click="handleSave()" style="height: 35px;width: 120px;border-radius: 8px;">Save</button>

        </div>
        </div>
        <div v-else>
            <h2>Edit Label</h2>
            <div style="width: 100%; margin-bottom: 20px;">
                <div>accounts - {{ currentKey }}</div>
                <div>Label</div>
                <input  v-model="newLabel" style="min-height: 50px;display: block;padding: 8px 40px 0 8px;border-radius: 5px;border: 1px solid #e8e8e8;background: #fff; font-size: 14px;margin-right: auto;width: 100%;" placeholder="Enter label" type="text"/>
            </div>
            <div style="display: flex; align-items: center; justify-content: end;">
                <button @click="handleSave()" style="width: 130px; cursor: pointer; padding: 6px; background: rgb(118, 118, 255); color: white; border: none; border-radius: 10px; font-size: 16px; height: 40px;">Save</button>
            </div>
        </div>
      
    </div>
</template>
<script>
import Server from '../../components/API/Server'
export default {
    data() {
        return {
            keys: [],
            selectedKey:"",
            editKeyOpenLabel: false,
            currentKey: '',
            newLabel: '',
            columns:["","Key","Label",'Category']
        }
    },
    async mounted() {
        const dt = (await Server.getCustomAccountfields()).data.data
        const timeSerieskeys = dt.filter(d => d.category == "timeseries")
        this.keys = timeSerieskeys.map(d => {
            return {
                category: d.category,
                key: d.performOperationOnKey,
                label:d.name
            }
        })
        this.keys.push({
            category: "System",
            key: "ticketTrends",
            label:"Ticket Trends"
        })
        this.keys.push({
            category: "System",
            key: "npspiechart",
            label:"NPS Pie Chart"
        })
    },
    methods: {
        handleeditLabel(key, label){
            console.log(key, label);
            this.currentKey = key;
            this.selectedKey=key
        },
        async handleSave(){
            if(!this.newLabel || !this.selectedKey){
                this.$toasted.error("Cannot Save Trends! Please fill the label")
                return
            }
            console.log(this.newLabel);
            this.$emit('close', {value:this.newLabel,key:this.selectedKey});
        }
    }
}
</script>
<style lang="scss" scoped>
.options {
    display: flex;
    align-items: center;
}
td{
    padding: 12px;
}
</style>