<template>
    <div>
        <stripe-pricing-table pricing-table-id="prctbl_1NaHtRJuGEa1CQeZrSifatIo"
            publishable-key="pk_live_51LpqwKJuGEa1CQeZwYt0YniOFru3bD1zbrfaYSm7rPRFEyleb32RoIlt1DvBKaevquc8fJMn7i79EycM9E5vsokU00Cv2T6tMQ">
        </stripe-pricing-table>
    </div>
</template>

<script>

import moment from 'moment';
import Server from './API/Server';
import Modal from './Modal.vue';

export default {
    data() {
        return {
            plans: {},
            selectedPlan: -1,
            usersLength: 0,
            showModal: false,
            showSuccessModal: false
        };
    },
    methods: {

        showValidity() {

            if (this.plans.created_date && this.plans.validity) {
                const createdDate = moment.unix(this.plans.created_date).add(this.plans.validity, "days").format("Do MMM, YYYY")
                return createdDate
            } else {
                return "_"
            }
        },

        async handlePay(leng) {
            const q = leng
            const data = await Server.selectPlan(q, this.selectedPlan);
            window.open(data.data);
        },
        async handleselectPlan(index) {
            this.selectedPlan = index;
            const userData = await Server.getAllUsers();
            this.usersLength = userData;
            this.temp = []
            this.usersLength.data.map((d, i) => {
                if (d.plan_name != "Professional") {
                    this.temp.push(d)
                }
            })
            this.usersLength.data = this.temp
            this.showModal = true


        },
        async handlePlanSelect(indx) {
            this.selectedPlan = indx;
        },
        hideButtom() {
            this.selectedPlan = -1;
        },
        handleClose() {
            this.showModal = false;
            this.showSuccessModal = false;
        }
    },
    async mounted() {
        try {
            console.log(this.$route.url)
            if (this.$route.query && this.$route.query.code == 200 || this.$route.query.code == 300) {
                const res = await Server.getSubscriptions();
                this.plans = res.data;
                this.showSuccessModal = true
            }
            const res = await Server.getPlans();
            if (res && res.code == 200) {

            }
        }
        catch (e) {
            console.log(e);
        }
    },
    components: { Modal }
}
</script>
<style lang="scss" scoped>
.one-right-border {
    border-right: 1px solid #e8e8e8;
}

.pricing-section-two .pr-table-wrapper .price {
    font-size: 40px;
    color: black;
    padding: 25px 0 15px;
}

.pricing-section-two .pricing-table-area .pr-bg {
    transition: 0.2s ease-in-out;
    padding: 20px;

    &:hover {

        cursor: pointer;
        scale: 1.01;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        transition: 0.2s ease-in-out;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.container {
    // max-width: 800px;
}

.pricing-section-two .pricing-table-area {

    position: relative;

}

.pricing-section-two .pricing-table-area .tab-content {
    background: #fff;
}

.pricing-section-two .pr-table-wrapper {
    border-bottom: 1px solid #f1f1f1;

    .header-div {
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 50px;
        margin-bottom: 20px;
    }
}


.pricing-section-two .pr-table-wrapper {
    padding: 20px 0 40px;
    text-align: center;
    width: 100%;
}

.pricing-section-two .pr-table-wrapper .bill-cycle {
    color: #000;
    font-size: 16px;
    margin-bottom: 22px;
}

.bill-cycle-features {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: Arial;
    padding-bottom: 5px;


}

li ::after {
    display: block;
    color: red;
}





.pricing-section-two .pricing-table-area .pr-bg {}

.no-gutters {
    margin-left: 0;
    margin-right: 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pricing-section-two .pr-table-wrapper .pack-name {
    font-size: 14px;
}

.pricing-section-two .pr-table-wrapper .pack-name {
    color: black;
    font-family: Cascadia Mono;
    font-size: 14px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
}

.pricing-section-two .pr-table-wrapper .pr-feature {
    padding: 10px 50px 50px;
    text-align: left;
    position: relative;

    button {
        position: absolute;
        bottom: -40px;
        width: 300px;
        left: 23%;
        border-radius: 5px;
    }

}

li {

    margin-top: 10px;
    list-style-image: url("../assets/newtick.svg");

}

.div-wrapper_user_count {
    display: flex;
    flex-direction: row;

    button {
        position: absolute;
        width: 300px;
        bottom: 20px;
        display: block;
        margin: auto;
        left: 22%;
        border-radius: 5px;
    }

    .left_div-wrapper_user_count {
        width: 50%;

        img {
            width: 200px;
        }
    }

    .right_div-wrapper_user_count {
        width: 50%;

        .price_div {
            font-size: 22px;
            border-style: solid;
            border-width: 1px 0px 1px 0px;
            margin-bottom: 20px;

            .price_span {
                color: black;
            }
        }
    }
}

.success-div-plans {
    * {
        padding: 0;
        margin: 0
    }

    .wrapper {
        height: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #7ac142;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
    }

    .checkmark {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
    }

    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0
        }
    }

    @keyframes scale {

        0%,
        100% {
            transform: none
        }

        50% {
            transform: scale3d(1.1, 1.1, 1)
        }
    }

    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px #7ac142
        }
    }
}

.success_div_wrapper {
    display: flex;
    flex-direction: row;

    .left_success_div_wrapper,
    .right_success_div_wrapper {
        width: 50%;
        min-width: 200px
    }

    .success_data_wrapper {

        p {
            margin-bottom: 10px;
        }

        span {
            font-size: 15px !important;
            color: #000;
            font-weight: 500;
        }

    }


}</style>


