<template lang="">
    <div>
        <div style="padding:10px;min-height: 75vh;padding:20px" class="card">
            <div style="display: flex; align-items: 'center'">
                <img style="height: 2.5vw; margin-right: 1vw;" src="../assets/img/User Management.png">
                <h2 id="user_header"  style="margin-top:10px;margin-bottom:60px">Users</h2>
            </div>
            <div style="width: 100%; display: flex; align-items: center; justify-content: space-between; ">
                <GlobalTabs  @selectTab="handleSelectTab($event)" :notIdx="false" :tabs="['Users', 'Profiles']"/>
                <AddButtonGlobal v-if="selectedTabIdx==0" @button="handleInviteUsersClick($event)" class="invite_user_button" />
                <AddButtonGlobal v-else @button="handleProfileClick($event)" class="invite_user_button" />
            </div>
            <GlobalTable v-if='selectedTabIdx==0 && !loading' :columns="columns" :tableData="activeList" @handleAction="handleAction($event)"/>
            <div v-else-if="selectedTabIdx==1 && !loading">
            <Department :openWithUserMgmt='true' @change="openDepartmentProfileAdd=!openDepartmentProfileAdd;" :showOpenAddProfile="openDepartmentProfileAdd" />
            </div>
            <div v-else style="display: flex; align-items: center; justify-content: center;">
                <img src="../assets/loadingRipple.svg" style="height: 200px;" alt="">
            </div>
        </div>
        <UserConfiguration v-if="showUserConfigure" @save="showUserConfigure = !showUserConfigure; configureUserData = {}; initData();" @removeUser="handleRemoveUser($event)" @close="showUserConfigure = !showUserConfigure; configureUserData = {};" :userData="configureUserData"/>
        <Modal class="invite_user_modal" @close="handleClose()" v-if="openInviteModal" :show="openInviteModal">
        <i @click="handleClose()" class="tim-icons close-icon icon-simple-remove"></i>
        <template v-if="!showSuccess">
                <h3 id="user_modal_header" style="text-align:center">Invite Users</h3>
                <div class="invite_wrapper_div">
                <div class="invite_left_div">
                <img src="../assets/inviteUser.svg" />
                </div>
                <div class="invite_right_div">
                <!-- 
                <div :style="{ 'border': selectedIndex.includes(i) ? '1px solid green' : '' }" @click="handleClick(i)"
                    style="margin-top: 7px;cursor: pointer;padding: 10px;" v-for="org, i of myOrgs">
                    <img height="30px" style="border-radius: 50%;margin-right: 10px;" :src="getIcon(org.domain)" />
                    {{ org.domain }}
                </div> -->
                <span id="user_modal_email_to_invite" class="invite_user_label">Email's to Invite</span>
                <div class='tag-input'>
                    <div v-for='(tag, index) in enteredUserEmails' :key='tag' class='tag-input__tag'>
                        {{ tag }}
                        <span @click='removeTag(index)'>x</span>
                    </div>
                    <input
                    type='text'
                    placeholder="Enter Emails"
                    class='tag-input__text'
                    @paste='addTagPaste'
                    @keydown.enter='addTag'
                    @keydown.delete='removeLastTag'
                    @blur='addTag'
                    
                    />
                
                </div>
                <span id="user_modal_profile_invite" class="invite_user_label">Select Profile</span>
                <div style="display:flex;justify-content:center">
                    <Multiselect id="mySelect1"  select-label="" class="multiStyleInvite" :taggable="true" :hide-selected="true" label="name"
                    :options="segments" :searchable="false" :allow-empty="false" v-model="selectedProfileName" >
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
        </Multiselect>
        </div>

        <div>
            <span style="color:red;font-size:12px;max-height:50px;margin:5px" v-if="error!=''">{{error}} </span>
        </div>

        <div style="display: flex;align-items: center; justify-content: center;">
            <button style="height: 40px; width: 10rem; margin-top: 20px;" @click="handleInviteClick" :class="{'withMargin':(error=='') }">
                Invite
            </button>
        </div>
        </div>
        </div>
        </template>
        <template v-if="showSuccess">
                <img src="../assets/inviteUser.svg" 
                    style="width: 40%;
                    display: block;
                    margin: auto;"/>
                <span style="text-align:center;width:100%;display:block;color:green;font-size:20px">{{msg}}</span>
            </template>
    </Modal>
    </div>
</template>

<script>
import { Tab, Tabs } from "vue-tabs-component"
import { BigHead } from "vue-bigheads";
import Multiselect from "vue-multiselect";
import Server from "../components/API/Server";
import Modal from "../components/Modal.vue";
import TagInput from "../components/TagInput.vue"
import GlobalTabs from "../layout/dashboard/GlobalTabs.vue";
import Department from "./Department.vue";
import AddButtonGlobal from "../layout/dashboard/AddButtonGlobal.vue";
import GlobalTable from "../layout/dashboard/GlobalTable.vue";
import UserConfiguration from "../components/UserConfiguration.vue";
import moment from "moment";

export default {
    components: {
        Tab,
        Tabs,
        BigHead,
        Multiselect,
        AddButtonGlobal,
        Department,
        GlobalTabs,
        GlobalTable,
        UserConfiguration,
        Modal,
        TagInput
    },
    data() {
        return {
            users: [],
            commaCheck: false,
            isAdmin: false,
            openInviteModal: false,
            loading: false,
            managerselect:-1,
            defaultProfile: '',
            selectedTabIdx: 0,
            enteredEmail: '',
            msg: '',
            error: '',
            editUserRole: -1,
            showProfileChange: -1,
            showSuccess: false,
            openAppEQUserConfirm: false,
            openDepartmentProfileAdd: false,
            appeqAgentData: {},
            enteredUserEmails: [],
            usersEmail: [],
            activeList: [],
            selectedProfileName: {},
            segments: {},
            columns: [
                {key: 'email_id',title: 'Name',width: '15%', parameter: ['email', 'name', 'logo']},
                {key: 'status',title: 'Status',width: '15%'},
                {key: 'type',title: 'Type',width: '15%'},
                {key: 'role',title: 'Role',width: '15%'},
                {key: 'timeCreated',title: 'Created',width: '15%'},
                {key: 'lastLogin',title: 'Last Active',width: '15%'},
                {key: 'action',title: 'Action',width: '15%'}
            ],
            validation: [{
                classes: 'min-length',
                rule: tag => tag.text.length < 8,
            }, {
                classes: 'no-numbers',
                rule: /^([^0-9]*)$/,
            }, {
                classes: 'avoid-item',
                rule: /^(?!Cannot).*$/,
                disableAdd: true,
            }, {
                classes: 'no-braces',
                rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
            }],
            configureUserData: {},
            showUserConfigure: false
        }
    },
    methods: {
        handleSelectTab(idx){
            this.selectedTabIdx = idx;
        },
        getDateFormat(date){
            if(!date || date == ''){
                return "";
            }
            return moment(date).format("DD MMM YYYY")
        },
        handleAction(action){
            switch (action[0]) {
                case 'openConfigure':
                    this.openConfigureUsers(action[1]);
                    this.openDepartmentProfileAdd = false;
                    break;
            
                default:
                    break;
            }
        },
        openConfigureUsers(data){
            this.configureUserData = data;
            this.showUserConfigure = true;
        },
        handleRemoveUser(data, idx){
            console.log(data);
            this.appeqAgentData = data;
            this.handleRemoveAppEQUser(idx);
            this.showUserConfigure = !this.showUserConfigure; 
            this.configureUserData = {};
        },
        getProfileNAme(profileName) {
            if (profileName) {
                if (Array.isArray(profileName)) {
                    return profileName[0];
                }
                else {
                    return profileName;
                }
            } else {
                return '';
            }
        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        handleEditProfileClick(index) {
            console.log(index);
            if (index == this.showProfileChange) {
                this.showProfileChange = -1;
            }
            else {
                this.showProfileChange = index;
            }

        },
        async openRemoveConfirmAppEQ(data) {
            this.openAppEQUserConfirm = true;
            this.appeqAgentData = data;
        },
        async handleRemoveAppEQUser(idx) {
            let response = await Server.deleteAppEQAgent(this.appeqAgentData['org_id'], this.appeqAgentData['email_id']);
            console.log(response);
            this.initData();
            this.activeList.splice(idx, 1);
        },
        async handleEditSandboxClick(sanbox, index) {
            console.log(sanbox);
            console.log(index);
            console.log(this.users);
            console.log(this.users[index]['email_id']);
            console.log(this.users[index]['role']);
            let emailID = this.users[index]['email_id'];
            let role = this.users[index]['role'];
            let response = await Server.changeUsersRole(emailID, role, sanbox);
            console.log(response);
            // await Server.changeUsersRole()
        },
        handleEditUserRole(index) {
            this.editUserRole = index;
        },
        handleEditManager(index) {
            const data = this.users.filter(d => d.role == 'Manager' || d.isAdmin)
            this.usersEmail = data.map(d => d.email_id)
            this.usersEmail.push("None")
            this.managerselect = index;

        },
        async makeManager(user) {
            // user['manager']=user['manager']['email_id']
            if (user.manager == "None") {
                const data = await Server.makeManager(user.email_id, user.selected, '')
                this.managerselect = -1;
                return
            }
            const data = await Server.makeManager(user.email_id, user.selected, user.manager)
            this.managerselect = -1;
        },
        async changePermission(user) {
            try {
                const data = await Server.changeUsersRole(user.email_id, user.selected)
                if (data.code != 200) {
                    this.$toasted.error(data.message)
                } else {
                    this.$toasted.success("Updated user")
                    const data = await Server.getAllUsers()
                    console.log(data)
                    this.isAdmin = JSON.parse(localStorage.getItem("IS_ADMIN"))
                    data.data.map(d => { d.isAdmin ? d['selected'] = 'System Admin' : d['selected'] = 'User' })
                    data.data.map(d => {
                        d.isAdmin ? d['selected'] = 'System Admin' : d['selected'] = d['role'] ? d['role'] : 'User';
                    });
                    this.users = data.data
                    this.editUserRole = -1
                }
            } catch (error) {

            }
        },
        getNames(name) {
            var regex = /\d/g;
            let no = (name.match(regex));
            if (no != null)
                no = no[0]



            let len = name.split(" ")
            if (len.length > 1) {
                name = len[0][0] + "" + len[1][0]
                if (no !== null)
                    name = name + no

            }
            else if (len.length > 0 && len.length <= 1) {
                name = len[0][0]
                if (no !== null)
                    name = name + no
            }
            if (name)
                name = name.toUpperCase()
            return name
        },
        ValidateEmail(mail) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/.test(mail)) {
                try {
                    let userMail = localStorage.getItem('ADMIN_CREDS');
                    if (userMail) {
                        userMail = JSON.parse(userMail);
                        userMail = userMail['__email__id'];
                        if (userMail.split('@')) {
                            let userDomain = userMail.split('@')[1];
                            let enteredDomain = mail.split('@')[1];
                            if (userDomain != enteredDomain) {
                                this.$toasted.error("Different Email Domain. Please Enter Emails From Your Domain Only.");
                                this.error = mail + " is from different domain. Please Enter Emails From Your Domain Only.";
                                return false;
                            }
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
                return (true)
            }
            this.$toasted.error("You have entered an invalid email address. Please enter a valid email.");
            return (false)
        },

        handleInviteUsersClick() {
            this.openInviteModal = true;
            this.selectedProfileName = this.defaultProfile;
        },
        handleProfileClick(){
            console.log(this.openDepartmentProfileAdd, 'console here')
            this.openDepartmentProfileAdd = !this.openDepartmentProfileAdd;
        },
        handleClose() {
            this.openInviteModal = false;
            this.enteredUserEmails = [];
            this.showSuccess = false;
            this.msg = '';
            this.error = '';
            this.selectedProfileName = '';
            this.enteredEmail = '';
            this.selectedProfileName = this.defaultProfile;
        },
        async handleInviteClick() {
            if (this.enteredUserEmails && this.enteredUserEmails.length != 0) {

                if (this.selectedProfileName && this.selectedProfileName.name) {

                    const inviteResponse = await Server.sendInvite(this.enteredUserEmails, this.selectedProfileName);

                    if (inviteResponse && inviteResponse.data) {
                        if (inviteResponse.data.code && inviteResponse.data.code == 200) {
                            this.$toasted.success(inviteResponse.data.msg);
                            this.msg = inviteResponse.data.msg;
                            this.showSuccess = true;
                            this.getUsers();
                        }
                        else
                            this.$toasted.error("Something went wrong please try again later");
                    } else {
                        this.$toasted.error("Something went wrong please try again later");
                    }

                } else {
                    this.error = "Profile name can not be empty. Please select profile";
                }

            } else {
                this.error = "Emails can not be empty. Please enter valid email";
            }
        },
        addTagPaste() {

            event.preventDefault();

            let val = (event.originalEvent || event).clipboardData.getData("text/plain");


            console.log((event.originalEvent || event).clipboardData.getData("text/plain"));

            if (val == '') {
                return;
            }
            try {
                let emailList = val.split(",");
                if (emailList && emailList.length > 0) {
                    emailList.map(email => {
                        email = email.trim();
                        if (this.ValidateEmail(email)) {
                            this.enteredUserEmails.push(email)
                        }
                    })
                    event.target.value = ''
                }
            } catch (e) {
                this.$toasted.error(e);
            }

        },
        addTag() {

            event.preventDefault();
            let val = event.target.value.trim();

            if (val == '') {
                return;
            }
            try {
                let emailList = val.split(",");
                if (emailList && emailList.length > 0) {
                    emailList.map(email => {
                        email = email.trim();
                        if (this.ValidateEmail(email)) {
                            this.enteredUserEmails.push(email)
                        }
                    })
                    event.target.value = ''
                }
            } catch (e) {
                this.$toasted.error(e);
            }
        },
        removeTag(index) {
            this.enteredUserEmails.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
                this.removeTag(this.enteredUserEmails.length - 1)
            }
        },

        handleKeyDown(event) {
            event = event || window.event;
            var key = event.which || event.keyCode;
            if (key == 86) {
                let val = event.target.value.trim();
                if (val == '') {
                    return;
                }
                try {
                    let emailList = val.split(",");
                    if (emailList && emailList.length > 0) {
                        emailList.map(email => {
                            email = email.trim();
                            if (this.ValidateEmail(email)) {
                                this.enteredUserEmails.push(email)
                            }
                        })
                        event.target.value = ''
                    }
                } catch (e) {
                    this.$toasted.error(e);
                }
            }
        },
        async getUsers() {
            const data = await Server.getAllUsers()
            this.isAdmin = JSON.parse(localStorage.getItem("IS_ADMIN"))
            console.log(typeof (this.isAdmin), "--gaurav")
            data.data.map(d => { d.isAdmin ? d['selected'] = 'System Admin' : d['selected'] = 'User' })
            this.users = data.data;
            this.users.map(data => {
                data.segment_name = this.getProfileNAme(data.segment_name);
                data['initials'] = this.getNames(data.segment_name);
            });

            this.users = [];

            data.data.map(d => {
                d.isAdmin ? d['selected'] = 'System Admin' : d['selected'] = d['role'];
                if (!('passcode' in d)) {
                    d.time_created = '';
                    d.segment_name = 'Invited';
                    this.users.push(d);
                }
            });

            data.data.map(d => {
                d.isAdmin ? d['selected'] = 'System Admin' : d['selected'] = d['role'];
                if (('passcode' in d)) {
                    this.users.push(d);
                }
            });

        },
        async initData(){
            this.loading = true;
            const data = await Server.getAllUsers()
            console.log(data)
            this.activeList = [];
            this.users = [];
            this.users = data.data;
            console.log(this.users)
            this.users.sort(function (a, b) {
                return new Date(a.time_created) - new Date(b.time_created)
            })
            console.log(this.users, 'users ki actual list');
            for(let i=0;i<this.users.length;i++){
                this.activeList.push({...this.users[i], 'type': (this.users[i].isAdmin) ? 'System Admin':'User' , 'status': 'Active', 'timeCreated': this.getDateFormat(this.users[i].time_created), 'lastLogin': this.getDateFormat(this.users[i].last_login), 'action': [{'img':'ConfigureIcon.png', 'action': 'openConfigure', 'hover': 'Configure'}]})
            }
            try {
                const data = await Server.getUserSemnets();
                this.segments = data.data
                console.log(this.segments)

                this.segments.map(data => {
                    if (data.name == 'Customer Success') {
                        this.selectedProfileName = data;
                        this.defaultProfile = data;
                    }
                })
                this.loading = false;
            } catch (e) {
                this.loading = false;
                console.log(e);
            }
        }
    },
    async mounted() {
        this.initData();
    }
}
</script>
<style scoped>
.table th{
    border-top: none;
}
</style>
<style lang="scss">
.tabs-component-tabs {
    margin-top: 20px;
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

a[aria-selected="true"] {
    font-weight: 800;
    margin-top: -2px;
    border-bottom: 2px solid black;
}

.tabs-component-tab-a {
    color: black !important;
}

.tabs-component-tab {
    color: black !important;
    padding-right: 10px;

    &:first-child {
        margin-left: 0px;
    }

    margin-left: 40px;
}

.emailN {
    border-radius: 50%;
    background: pink;
    width: 25px;
    text-align: center;
    font-weight: 700;
    height: 25px;
    margin-right: 20px;
}

//for tag container height. it will be applied everywhere as it is not scoped
.multiselect__tags {
    max-height: 200px;

}

button.invite_user_button {
    width: 90px;
    height: 30px;
    border-radius: 5px;
    position: absolute;
    right: 35px;
    top: 70px;
    font-size: 12px;
}

.invite_wrapper_div {
    display: flex;
    flex-direction: row;

    .invite_left_div,
    .invite_right_div {
        width: 50%;
    }

    .invite_left_div {
        img {
            width: 300px;
            display: block;
            margin: auto;
        }
    }

    .invite_right_div {

        .invite_user_label {
            display: block;
            margin-top: 30px;
        }

        .invite_user_email {}

        button {
            border-radius: 5px;
            margin-top: 10px;
        }

        .summaryNameSpan {
            padding: 10px;
            display: block;
            border: 0.1px solid #c8c8c8;
            width: 310px;
            border-radius: 5px;
            margin-top: 10px;
            min-height: 45px;
        }

        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap');

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100vh;
            background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(102, 126, 234, 1) 50%, rgba(69, 252, 250, 1) 100%);
        }

        a {
            position: absolute;
            right: 15px;
            bottom: 15px;
            font-weight: bold;
            text-decoration: none;
            color: #00003a;
            font-size: 20px;
        }


        /*tag input style*/

        .tag-input {
            width: 100%;
            border: 1px solid #D9DFE7;
            background: #fff;
            border-radius: 4px;
            font-size: 0.9em;
            min-height: 45px;
            box-sizing: border-box;
            padding: 0 10px;
            font-family: "Roboto";
            margin-bottom: 10px;
            max-height: 100px;
            overflow: scroll;

        }

        .tag-input__tag {
            height: 24px;
            color: white;
            float: left;
            font-size: 14px;
            margin-right: 10px;
            background-color: #667EEA;
            border-radius: 5px;
            margin-top: 10px;
            line-height: 24px;
            padding: 0 8px;
            font-family: "Roboto";
        }

        .tag-input__tag>span {
            cursor: pointer;
            opacity: 0.75;
            display: inline-block;
            margin-left: 8px;
        }

        .tag-input__text {
            border: none;
            outline: none;
            font-size: 1em;
            line-height: 40px;
            background: none;
            width: 100%;
        }
    }
}

.multiStyle2 {
    margin-right: 10px;
    margin-top: 0px !important;
}
</style>