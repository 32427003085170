<template>
    <div class="card" style="padding: 5px;">
        <div v-if="!loading" class="summaryPage">
            <div style="padding: 10px;" class="card">
                <h2 id="summary_creator_header" style="text-align:center;margin-top:0px;margin-bottom: 4px;">Summary Widget</h2>
                <span style="text-align:center;margin-top:0px">Summary widgets will be shown on top of your inbox and crm platforms with the selected templates</span>

                <div class="showSummaryExapmleDiv" v-if="store.summaryList && store.summaryList.length == 0 ">
                    <div class="detailText">
                        <span>Hello <span style="font-size: 20px;font-weight: 700;text-transform: capitalize;">{{name }} , </span>
                    Welcome to the summary configurator
                    We have already defined some summary templates for you . Choose any one of the templates to get started or create your custom template. This template will be shown on top of inbox and CRM platforms.
                    </span>
                    </div>
                        <img  src="https://www.appeq.ai/images/assets/feature-img-01.gif"/>
                
                </div>
                
                
                <div style="display: flex;align-items: center;">
                    <h4 id="summary_creator_template" @click="showHideTemplate" style="margin-top:10px;cursor: pointer;color: #6f55ff !important;">{{ 'Add'}} a new  Summary &nbsp; &nbsp;
                        <i style="font-size: 12px;font-weight: 700;" v-if="!showSummaryTemplateDiv" class="tim-icons icon-minimal-down"></i>
                        <i style="font-size: 12px;font-weight: 700;" v-if="showSummaryTemplateDiv" class="tim-icons icon-minimal-up"></i>
                    </h4>
                </div>
                    <span v-if="showSummaryTemplateDiv">Choose from the pre-defined templates or create your own</span>
                   

                <hr class="hr-line" v-if="!showSummaryTemplateDiv" style="width:90%;display: block;margin-left: auto;margin-right: auto;margin-top: 5px;">
                
                <Transition name="fade">
                    <div v-if="showSummaryTemplateDiv" class="staticTemplates">
                        <div @click="openModalForStatic(sum)" style="margin-right: 10px;" class="summaryTemplateTile"
                            v-for="sum, index of store.staticTemplates">
                            <Summary :static="true" :configured="false" :summary="sum" :index="index"></Summary>
                        </div>
                        <div class="addBtn">
                            <div @click="handleCustomSumm()" class="queries__addproduct">
                                <img src="../../assets/thin-add-button-svgrepo-com.svg">
                                <span>Add</span>
                            </div>
                        </div>
                    </div>
                </Transition>
                

                <h4 id="summary_creator_configured_summary" style="margin-top:30px;width:fit-content">Currently Configured Summary</h4>
                <div v-if="store.summaryList.length==0" style="display: flex;align-items: center;justify-content: center;width: 100%;flex-direction: column;;">
                    <img  style="width:500px;display: flex;align-items: center;justify-content: center;flex-direction: column;" src="../../assets/nodata.svg"/>
                    <h4>No Summary Template Configured</h4>

                </div>
                <div style="display: grid;grid-template-columns: 1fr 1fr;">
                    <div style="display:block;margin:auto;min-width: 500px;margin-bottom:50px;margin-top:50px;max-width: 570px;"
                        v-for="sum, index of store.summaryList">
                        <Summary :summary="sum" :static="false" :configured="true" :index="index"
                            :ticketAdded="ticketAdded"></Summary>
                    </div>
                </div>
            </div>
        </div>
        <modal v-if="openEditModal" class="summaryClass" :show="openEditModal" :centered="true" @close="checkClose()"
            :show-close="true">
            <i @click="checkClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <h3 style="text-align: center;">{{ staticSum.summary_name }}</h3>
            <div style="display: flex;justify-content: center;margin-bottom: 30px;">
                <span style="margin-right: 10px;" class="material-symbols-outlined">
                    tips_and_updates
                </span>
                <span> This is a sample template for showing {{ staticSum.summary_name.toString().split(" ")[0] }} hightlights on top of inbox and salesforce.
                 </span>
            </div>
            <div class="headersForModal" >
                <div style="display: flex;">
                    <div style="display: flex;
                                            align-items: center;
                                            flex-direction: column;
                                            justify-content: center;
                                        ">
                        <Summary :static="true" :configured="false" :summary="staticSum"></Summary>
                    </div>
                    <div style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 10px;flex: 1;">
                        <template v-for="detailRow in store.staticTemplatesDetails"
                                 v-if="detailRow && detailRow.summary_name == staticSum.summary_name">
                            <ul class="modalUlForSummaryTemplate">
                                <template v-for="dataRow in  detailRow.data">
                                    <li>
                                        <span style="display: block;line-height: 20px;">
                                            <b>{{dataRow.label}} : </b>{{ dataRow.detail }}
                                        </span>
                                    </li>
                                </template>
                            </ul>                                
                        </template>
                        <p>To select this template click on the button below</p>
                        
                    </div>
                </div>
                <button @click="store.saveSummary(staticSum,false,false);checkClose();closeThisModal()" 
                style="margin-top: 20px;width:400px;border-radius:10px;">
                    Configure this template
                </button>
              
            </div>
        </modal>
        <modal class="summaryClass" :show="editModalForVisibility" :centered="true" @close="checkClose()" :show-close="true">
            <i @click="checkClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <div class="headersForModal" >
               <h3 style="margin-top: 30px;">New Summmary</h3>
               <span>Enter summary name </span>
               <br/>
               <br/>
               <div class="inputDiv">
                    <input placeholder="Your summary name"  />
               </div>
               <br>
            </div>
        </modal>
        <modal class="summaryClass" :show="customSummaryModal" :centered="true" @close="checkClose()" :show-close="true">
            <i @click="checkClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <Summary :summary="customSummary"  static="true"></Summary>
            
        </modal>
    </div>
</template>

<script>
import { store } from "./Store";
import Modal from "@/components/Modal";
import Server from "../../components/API/Server";
import Multiselect from "vue-multiselect";
import Summary from "./TemplateDesigner.vue"
import LocalUtils from "../../components/Utils/LocalUtils";

export default {

    data() {
        return {
            store,
            isAdmin: true,
            showModal: false,
            name:'',
            summaryName: '',
            ticketAdded: false,
            editModalForVisibility: false,
            segmenst: [],
            profileName: '',
            showSummaryTemplateDiv:false,
            customSummaryModal:false,
            msg: '',
            openEditModal: false,
            loading: false,
            columns: [
                "Summary Name",
                "Profile Name",
                "Actions"
            ],
            ticketHighLights: false,
            staticSum:null,
            customSummary:{
                            data: [
                                {
                                    appeq_summary_row: [
                                        {
                                            appeq_summary_column: {
                                                appeq_summary_label: "Total tickets",
                                            
                                            },
                                        },
                                    ],
                                },  
                            ],
                            summary_name: "Enter Summary Name",
                            summary_template_name: "Enter Summary Name",
                            },
            
        };
    },
    beforeRouteLeave(to, from, next) {
        // react to route changes...
        if (store.summaryList.findIndex(data => data.custom)>-1) {
            const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
            if (answer) {
                next()
            } else {
                next(false)
            }
        }else{
            next()
        }

    }
    ,
    methods: {
        handleCustomSumm() {
            this.store.summaryList.push({
                data: [{
                    appeq_summary_row: [
                        {
                            appeq_summary_column: {
                                appeq_summary_label: "Total tickets",
                                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                                appeq_summary_type: "feedsLink",
                                query: "{}",
                            },
                        },

                    ],
                },
            
                {
                    appeq_summary_row: [
                        {
                            appeq_summary_column: {
                                appeq_summary_label: "Total tickets",
                                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                                appeq_summary_type: "feedsLink",
                                query: "{}",
                            },
                        },

                    ],
                },
                {
                    appeq_summary_row: [
                        {
                            appeq_summary_column: {
                                appeq_summary_label: "Total tickets",
                                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                                appeq_summary_type: "feedsLink",
                                query: "{}",
                            },
                        },

                    ],
                },
                {
                    appeq_summary_row: [
                        {
                            appeq_summary_column: {
                                appeq_summary_label: "Total tickets",
                                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                                appeq_summary_type: "feedsLink",
                                query: "{}",
                            },
                        },

                    ],
                }
            ],
                summary_name: "Untitled",
                custom: true
            })
            window.scrollTo(0, document.body.scrollHeight);

        },

        openModalForStatic(sum) {
            this.staticSum = sum
            this.summaryName = sum.summary_name
            this.openEditModal = true
        },
        async handleDelete(u) {
            try{
                const data = await Server.delSumm(u['summary_name'], u['profile_name'])
                if (data.data.code != 200) {
                    this.$toasted.error(data.data.msg)
                } else {
                    const data = await Server.getSummaryJson();
                    if (data && data.code == 200) {
                        this.store.summaryList = []
                        data.data.map(d => {
                            this.store.summaryList.push(d);
                        })
                    }
                }
            }catch(e){
                console.log(e);
            }
            
        },
        showHideTemplate(event) {
            this.showSummaryTemplateDiv = ! this.showSummaryTemplateDiv;
        },
        checkClose() {
            this.showModal = false;
            this.customSummaryModal = false;
            this.openEditModal = false;
            this.editModalForVisibility = false;
            this.summaryName = '';
            this.msg = '';
        },
        saveSummaryName() {
            if (this.store.summaryList.filter(d => d.summary_name == this.summaryName).length < 1) {
                this.store.summaryList.push({ summary_name: this.summaryName, profile_name: this.profileName.name });
                this.showModal = false;
            }
            else {
                this.msg = 'Summary with this name already exists';
            }

        },
        closeThisModal(){
            setTimeout(()=>{
                window.location.reload();
            },1000);

        },
        
        goToSummary(name, prof) {
            this.store.currentSummaryName = name;
            console.log(this.store.currentSummaryName);
            // localStorage.setItem('currentSummaryName',name);
            // localStorage.setItem('profileName',prof);

            this.$router.push("template-designer");
        },
        isForced(item) {
            return (item && item.summary_name == 'default') ? true : false;

        },
        async headerClickedCheck(item) {
            await this.headerClicked(item);
        },
        async headerClicked(item) {
            console.log("header is clicked");
            if (item && item.item && item.item.$el) {
                let heading = item.item.$el.querySelectorAll('#heading');
                if (heading && heading.length > 0) {
                    let name = heading[0].textContent;
                    if (name && name != '') {
                        this.store.currentSummaryName = name;
                        console.log(this.store.currentSummaryName);
                        await this.store.findMaxColsandRows();
                    }

                }
            }
        }
    },
    beforeMount() {
    },
    async mounted() {
        this.name=LocalUtils.getEmail().toString().split("@")[0]
        this.loading = true;
        this.store.summaryList = [];
        const data = await Server.getSummaryJson();
        if (data && data.code == 200 && data.data) {
            await data.data.map(d => {
                this.store.summaryList.push(d);
            })
        }
        console.log(this.store.summaryList);
        this.loading = false;

        if(this.store.summaryList.length > 0){
            this.showSummaryTemplateDiv = false;
        }else{
            this.showSummaryTemplateDiv = true;
        }

        const res = await Server.getUserSemnets();
        this.store.profileList=[]
        if (res && res.data) {
            res.data.map(d => this.store.profileList.push(d.name));
        }



        try {
            const res = localStorage.getItem('occupiedBuisnessObj');
            if (res && res.includes('ticket')) {
                this.ticketAdded = true;
            }
        } catch (e) {
            console.log(e);
        }

    }
    , components: {
        Modal,
        Multiselect,
        Summary
    }
}
</script>

<style lang="scss" >
.summaryPage {
    padding-bottom: 100px;
    overflow: auto;
}

.inputDiv {
    border: 1px solid #dce2ea;
    display: flex;
    height: 40px;
    align-items: center;


    input {
        border: none;
        margin-left: 10px;
        width: 90%;
    }
}

.buttonDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.queries_data {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    background: white;
    color: black;
    display: flex;
    height: 86px;
    border-radius: 20px;
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    width: 200px;
    transition: 0.2s ease-in-out;


    &:hover {
        background: #4463ea;
        color: white;
        scale: 1.05;
        transition: 0.2s ease-in-out;
    }

}

.headersForModal {
    text-align: center;
}

.editItemDiv {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);
    height: 20px;
}

.editItemDiv:hover {
    scale: 1.2;
    transition: 0.2s ease-in-out;

}

.deleteItemDiv {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    height: 19px;
    margin-left: 15px;

    &:hover {
        scale: 1.2;
        transition: 0.2s ease-in-out;

    }
}

.dataRow {
    margin-top: 15px;
    display: flex;
    min-height: 100px;
    /* width: 80%; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 20px;
    padding: 20px;
    transition: ease-in-out;

    &:hover {
        background: #e3e6fa7a;
        transition: ease-in-out;
    }
}

div.summaryPage>.card {
    min-height: 75vh;
    padding: 20px !important;
}

.vsa-list {
    max-width: 100%;
    margin-top: 30px;

    border: none;
}

.vsa-item {
    background-color: rgba(246, 246, 255, 0.446);
    margin: 20px;
    min-height: 70px;
    border-radius: 15px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    &__trigger {
        margin-top: 7px;
    }

    &--is-active {
        .vsa-item__trigger__icon {
            .openIconForAccoridian {
                display: none;
            }

            .closeIconForAccoridian {
                display: block;
            }
        }
    }

    &__trigger__icon {
        .openIconForAccoridian {
            display: block;
        }

        .closeIconForAccoridian {
            display: none;
        }
    }
}

.vsa-item__trigger:focus,
.vsa-item__trigger:hover {
    background: transparent;
    color: black;
    border-radius: 15px;
}

.vsa-item__trigger__content {
    text-transform: capitalize;
    font-weight: 550;
    font-size: 1rem;
}

span.vsa-item__trigger__content {
    width: 100%;
}

.headingAccordianDiv {
    display: flex;
    justify-content: space-between;
}

span.labelSpanForAccordian {
    color: #7e7e80;
}

.text-span {
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 40px;
    background: rgb(255, 249, 230);
    color: rgb(99, 99, 99);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;

    i {
        margin-right: 10px;
        margin-left: 5px;
        color: rgb(202, 143, 0);
    }

}

.staticTemplates {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    transition: 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    border-radius: 5px;
    padding: 20px;
    transition: 0.5s ease-in-out;


    &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
    }

    &::-webkit-scrollbar-thumb {
        width: 0px !important;
        height: 0px !important;
        display: none !important;
    }

}


.queries__addproduct {
    display: flex;
    border: 2px solid #004D94;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border: 0.5px solid grey;
    border-radius: 20px;
    padding: 15px 11px;
    width: 100%;
    height: 180px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px #c8c8c8;
    transition: 0.2s ease-in-out;
    color: #6f55ff;

    img {
        height: 20px;
        filter: invert(27%) sepia(80%) saturate(2262%) hue-rotate(238deg) brightness(107%) contrast(101%);
    }

    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 20px 40px -20px, rgba(0, 0, 0, 0.3) 0px 10px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        transition: 0.2s ease-in-out;
        background: #6f55ff;
        color: white;
        border: 2px dotted rgb(136, 134, 134);

        img {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(116deg) brightness(98%) contrast(106%);
        }
    }
}

.addBtn {
    transition: 0.2s ease-in-out;
    min-width: 150px;
    margin-right: 50px;
    display: block;
    margin: auto;

    &:hover {
        scale: 1.08;
        transition: 0.2s ease-in-out;

    }
}

.summaryTemplateTile {
    transition: 0.2s ease-in-out;
    scale: 0.9;

    &:hover {
        scale: 0.95;
        cursor: pointer;
        transition: 0.2s ease-in-out;


    }
}

.configured {
    justify-content: space-between !important;
}


.close-icon {
    position: absolute;
    cursor: pointer;
    right: 27px;
    top: 24px;
    outline: 0;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #555555;

    &:hover {
        scale: 1.09;
        color: black;
    }
}

.modalUlForSummaryTemplate{

    max-height: 40vh;
    overflow: scroll;
    overflow-x: hidden;

    li{
        line-height: 33px;
        color: #4f5568 !important;
        text-align: left;
    }
}
.showSummaryExapmleDiv{
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
    

   .detailText{
        width: 50%;
        font-size: 18px;
    }

    img{
        width: 40%;
    }
}
.newBar{
    .container {
        display: inline-block;
        cursor: pointer;
    }

    .bar1, .bar2, .bar3 {
        width: 25px;
        height: 3px;
        background-color: #333;
        margin: 3px 0;
        transition: 0.4s;
    }

    .change .bar1 {
        transform: translate(0, 6px) rotate(-45deg);
    }

    .change .bar2 {opacity: 0;}

    .change .bar3 {
        transform: translate(0, -6px) rotate(45deg);
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transition:0.5s ease-in-out;
}
</style>