<template>
    <div class="card" :style="{'box-shadow': (!openType) ? '0 1px 20px 0px rgba(0, 0, 0, 0.1)' : 'none', 'margin-bottom': (!openType) ? '30px' : '0px', 'padding': (!openType) ? '20px':'0px'}" style="padding: 20px; margin-top: 1rem;">

      
        <!-- <template v-else>
            <h2 id="summary_creator_header" style="text-align:center;margin-top:0px;margin-bottom: 4px;">Configure Your
                Summary Widget</h2>
            <span style="text-align:center;margin-top:0px">The summary template can be selected from a predefined list or
                can be customized</span>
        </template> -->


        <!-- <button v-if="!addSummary && !openType" @click="openAddsumm"
            style="width: 150px;border-radius: 5px;height: 33px;margin-left: 20px;position: absolute;right: 20px;top:70px;">
            <i class="tim-icons icon-simple-add"></i>
            Add
        </button> -->

        <table :style="{'margin-top': (!openType) ? '':'0px'}" class="table tablesorter summary_table">
            <thead>
                <slot name="columns">
                    <th v-for="column in columns" :key="column">
                        <span :id="'my_summary_' + column">
                            {{ column }}
                        </span>
                    </th>
                </slot>
            </thead>
            <div v-if="loading" style="display: flex; justify-content: center; align-items: center;">
                <img class="loadingRipple" src="../../assets/loadingRipple.svg">
            </div>
            <tbody v-if="!loading" style="font-size: 0.7vw;">

                <tr style="cursor: pointer;" v-for="u, index of summaryData">

                    <td v-for="column in columns" :key="column">
                        <div v-if="column == 'Name'" style="color: #525f7f;">
                            {{ u.summary_name ? u.summary_name : '-' }}

                        </div>
                        <div v-if="column == 'Profile Name'">
                            <div style="display: flex;" v-if="u.profile_name && u.profile_name.length > 0">
                                <div v-tooltip.bottom-end="mem" :style="{
                                    background: '#4f60ff', height: '25px', width: '25px',
                                    textAlign: 'center', color: 'white', borderRadius: '50%', marginLeft: '10px', display: 'flex',
                                    justifyContent: 'center', alignItems: 'center', fontSize: '12px'
                                }" v-for="mem of u.profile_name">
                                    {{ getNames(mem) }}
                                </div>
                            </div>
                            <div v-else>
                                Unassigned
                            </div>


                        </div>
                        <!-- <div v-if="column == 'AI'">
                            {{ u.ai && u.ai == true ? 'Enabled' : 'Disabled' }}
                        </div> -->
                        <div v-if="column == 'Status'">
                            {{ u.profile_name && u.profile_name.length > 0 ? 'Live' : '-' }}


                        </div>
                        <div v-if="column == 'Last Modified'" style="color: #525f7f;">
                            {{ getHumanDate(u.modifiedDate) }} ago


                        </div>
                        <div style="display: flex;align-items: center;" v-if="column == 'Action'">
                            <img style="height: 1vw; cursor: pointer;" v-tooltip.bottom-end="'Edit summary'" @click="handleEdit(u)"
                                class="summary_action_visibility" src="../../assets/ConfigureIcon.png" />
                            <img v-tooltip.bottom-end="'Delete summary'" @click="handleDelete(u)" style="height: 1vw; cursor: pointer; margin-left: 0.4vw;"
                                class="summary_action_visibility" src="../../assets/delete.svg" />
                            <!-- <span @click="handleCopySumm(u)" v-tooltip.bottom-end="'Copy summary'"
                                style="color: green;margin-left: 6px;"
                                class="material-symbols-outlined summary_action_visibility">
                                content_copy
                            </span> -->

                        </div>

                    </td>

                </tr>

            </tbody>
        </table>

        <div class="summaryConf"  v-if="addSummary">
            <template >
           
            <!-- <h2 id="summary_creator_header" style="text-align:center;margin-top:0px;margin-bottom: 4px;">Summary Widget</h2> -->
            <!-- <span style="text-align:center;margin-top:0px">Summary widgets will be shown on top of your inbox and crm
                platforms
                with the selected templates</span> -->
        </template>
            <div @click="checkBack" style="display: flex;align-items: center;justify-content: end; cursor: pointer;">
                <img src="../../icons/Close.svg" style="height: 0.8vw; cursor: pointer;" alt="">
            </div>
            <div  class="summary__header">
                <span style="font-size: 35px; display: contents; color: grey;">
                    [ <span style="background: grey; padding: 15px; height: 30px; display: flex; border-radius: 50%;"></span> ]
                </span>
                            <!-- <img class="playbook__header__img" src="../assets/playbook.png" /> -->
                            <div style="width: 60%;margin-left: 20px;" class="">
                                <h2 class="summary__header__title">Summary</h2>
                                <span class="summary__header__desc">Enable in-the-moment quick summary based on your enterprise data</span>
                </div>
            </div>

            <div style="display: flex;align-items: center;margin-top: 20px;">
                <div class="mainTemplate">
                    <div style="width: 60%;" v-if="selectedSummTemp">
                        <Summary :summary="selectedSummTemp" :static="false" @trends-options="handleTrends($event)" :trendsEdit="this.trends" :ticketAdded="true" :configured="true">
                        </Summary>
                    </div>
                    <div v-if="selectedSummTemp == null" class="noSummSelected">
                        <h4>Start with a <a @click="handleBlackSummarySelectClick()">blank summary</a> or select from
                            predefined templates</h4>
                    </div>
                </div>
                <div v-if="true && !isEditing" class="templatessss">
                    <div class="templates_header_div">
                        <h4 style="text-align: center;margin-bottom: 0px;">Templates</h4>
                    </div>
                    <div class="overflow_div_templates">
                        <div @click="handleSummaryClick(temp, i)" v-for="temp, i of tempSumm"
                            class="summary_templates_static" :class="{ active: i == selectedIndex }">
                            <img v-if="i == 2" src="../../assets/Support Summary.png" />
                            <img v-if="i == 0" src="../../assets/Support + Invoices Summary.png" />
                            <img v-if="i == 1" src="../../assets/Support + Opportunities Summary.png" />
                            <input type="text" v-model="temp.summary_name" />
                        </div>
                    </div>
                </div>
                <div v-if="false" class="templatessss">
                    <div @click="selectedSummTemp = null">Back</div>
                    <div
                        style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 10px;flex: 0.6;padding: 25px;">
                        <template v-for="detailRow in store.staticTemplatesDetails"
                            v-if="detailRow && detailRow.summary_name == selectedSummTemp.summary_name">
                            <ul class="modalUlForSummaryTemplate">
                                <template v-for="dataRow in  detailRow.data">
                                    <li>
                                        <span style="display: block;line-height: 20px;">
                                            <b>{{ dataRow.label }} : </b>{{ dataRow.detail }}
                                        </span>
                                    </li>
                                </template>
                            </ul>
                        </template>
                        <p>To select this template click on the button below</p>

                    </div>
                </div>

                <div v-if="selectedSummTemp != null" class="new_summary_view_button"
                    style="display: flex;align-items: center;justify-content: flex-start;">
                    <button @click="handleClear" v-if="!isEditing"
                        style="width: 150px;border-radius: 5px;background-color: transparent;color: #4e5fff;border: 1px solid #4e5fff;height: 40px;">Clear</button>
                    <button @click="handleSave"
                        style="width: 150px;border-radius: 5px;margin-left: 10px;height: 40px;">Save</button>

                </div>
            </div>

        </div>
        <!-- <modal class="summary_show_modal" :show="showSummaryModal" :centered="true" @close="checkClose()" :show-close="true">
            <i @click="checkClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <Summary :summary="selectedSummary"  static="true"></Summary>  
        </modal> -->

    </div>
</template>

<script>

import Modal from "@/components/Modal";
import { store } from "../Configuration/Store"
import Server from "../../components/API/Server";
import Summary from "./TemplateDesigner.vue"
import LocalUtils from "../../components/Utils/LocalUtils";

export default {

    data() {
        return {
            isEditing: false,
            summaryData: [],
            oldSumm: '',
            oldSummaryData:{},
            store,
            trends: [],
            selectedSummTemp: null,
            loading: false,
            showSummaryModal: -1,
            selectedIndex: -1,
            selectedSummary: '',
            addSummary: false,
            tempSumm: [],
            columns: [
                "Name",
                "Profile Name",
                "Last Modified",
                "Action"
            ]

        };
    },
    props: {
        openType: false
    },

    methods: {
        checkBack(){
            // const answer = window.confirm('Do you really want to leave? you may have unsaved changes!')
            this.addSummary=false
        },
        handleEdit(sum) {
            setTimeout(() => {
                document.querySelector(".summaryConf").style.right = "0px"
              
            }, 400)
            console.log('sum is', sum)
            this.oldSummaryData=sum
            if(sum.trends){
                this.trends = sum.trends;
            }else{
               
            }
            this.oldSumm = sum.summary_name
            this.isEditing = true;
            this.addSummary = true
            store.thirdPartyHigh=sum.third_party_data
            this.selectedSummTemp = sum
            this.selectedSummary = sum
        },
        async handleDelete(sum) {
            const res = await this.store.deleteSummary(sum)
            if (res[0]) {
                this.$toasted.success(res[1]);
            }
            else {
                this.$toasted.error(res[1]);
                return
            }
            const rest = await Server.getSummaryJson();

            this.summaryData = rest.data;
            this.summaryData.map(data => { data['openedSummary'] = false })
            this.summaryData = this.summaryData.sort(function (a, b) {
                return new Date(b.modifiedDate) - new Date(a.modifiedDate);
            });
            console.log(this.summaryData.data);


        },
        async handleCopySumm(sum) {
            sum.summary_name+="(copied)"
            sum.profile_name=[]
            await this.store.saveSummary(sum, sum.ai, trends, false, this.summaryData)

            this.$toasted.success("Successfully Copied summary ")
            const res = await Server.getSummaryJson();

            console.log(res.data);

            if (res && res.code == 200) {
                this.summaryData = res.data;
                this.summaryData.map(data => { data['openedSummary'] = false })
                this.summaryData = this.summaryData.sort(function (a, b) {
                    return new Date(b.modifiedDate) - new Date(a.modifiedDate);
                });
                console.log(this.summaryData.data);
            }
        },
        async handleSave() {
            console.log('this oen',this.trends);
            if (this.isEditing)
                await Server.editSummaryName(this.oldSumm, this.selectedSummTemp.summary_name)
            if (this.isEditing)
                await this.store.saveSummary(this.selectedSummTemp, this.selectedSummTemp.ai, this.trends, this.isEditing, this.summaryData)
            else
                await this.store.saveSummary(this.selectedSummTemp, this.selectedSummTemp.ai, this.trends, false, this.summaryData)

            this.addSummary = false;
            this.$toasted.success("Successfully Added summary ")
            this.tempSumm = store.staticTemplates
            const res = await Server.getSummaryJson();

            console.log(res.data);

            if (res && res.code == 200) {
                this.summaryData = res.data;
                this.summaryData.map(data => { data['openedSummary'] = false })
                this.summaryData = this.summaryData.sort(function (a, b) {
                    return new Date(b.modifiedDate) - new Date(a.modifiedDate);
                });
                console.log(this.summaryData.data);
            }
        },
        handleTrends(value){
            this.trends = value;
        },
        handleSummaryClick(sum, i) {
            if (this.isEditing) {
                const prof = this.selectedSummTemp.profile_name
                this.selectedSummTemp = {
                    profile_name: prof,
                    ...sum
                }
            } else {
                this.selectedSummTemp = sum;

            }
            console.log(sum)
            this.selectedIndex = i;
        },

        getHumanDate: function (date) {
            var date = new Date((date))
            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = Math.floor(seconds / 31536000);

            if (interval > 1) {
                return interval + " years";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return interval + " months";
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return interval + " days";
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return interval + " hours";
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return interval + " minutes";
            }
            return Math.floor(seconds) + " seconds";
        },

        openAddsumm() {
            console.log("OPENONG ADD SUM")
            this.oldSumm = ''

            this.addSummary = true;
            this.isEditing = false;
            this.selectedSummTemp = null
            setTimeout(() => {
                document.querySelector(".summaryConf").style.right = "0px"
              
            }, 400)
        },

        getNames(name) {
            var regex = /\d/g;
            let no = (name.match(regex));
            if (no != null)
                no = no[0]



            let len = name.split(" ")
            if (len.length > 1) {
                name = len[0][0] + "" + len[1][0]
                if (no !== null)
                    name = name + no

            }
            else if (len.length > 0 && len.length <= 1) {
                name = len[0][0]
                if (no !== null)
                    name = name + no
            }

            name = name.toUpperCase()
            return name
        },

        handleShowSummary(summary, i) {
            if (this.showSummaryModal == i) {
                this.showSummaryModal = -1
                return
            }
            this.showSummaryModal = i;
            this.selectedSummary = summary;
            this.summaryData.push({ "view": "" })
            summary['openedSummary'] = true
        },

        handleOpenModal() {

            this.selectedSummary = {};
            this.showSummaryModal = true;
        },

        handleRedirect() {
            this.$router.push("department");
        },

        handleBlackSummarySelectClick() {
            this.selectedSummTemp = {
                data: [
                    {
                        appeq_summary_row: [
                            {
                                appeq_summary_column: {
                                    appeq_summary_label: "Enter Label",
                                    appeq_summary_value: "",
                                    appeq_summary_type: "",
                                    query: "{}",
                                },
                            },
                        ],
                    },
                ],
                summary_name: "Untitled",
                summary_template_name: "Untitled",

            }
        },

        handleClear() {
            this.selectedSummTemp = null;
            this.selectedIndex = -1
        },

        checkClose() {
            this.selectedSummary = '';
            this.showSummaryModal = false;
        }
    },
    beforeRouteLeave(to, from, next) {
        // react to route changes...
        if (this.addSummary) {
            // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
            next()
        }else{
            next()
        }

    },

    async mounted() {
        this.loading = true;
        const resa = await Server.getUserSemnets();
        this.store.profileList = []
        if (resa && resa.data) {
            resa.data.map(d => this.store.profileList.push(d.name));
        }

        this.tempSumm = store.staticTemplates
        const res = await Server.getSummaryJson();

        console.log(res.data);

        if (res && res.code == 200) {
            this.summaryData = res.data;
            this.summaryData.map(data => { data['openedSummary'] = false })
            this.summaryData = this.summaryData.sort(function (a, b) {
                return new Date(b.modifiedDate) - new Date(a.modifiedDate);
            });
            console.log(this.summaryData.data);
        }
this.loading = false;
    }
    , components: {
        Modal,
        Summary,
    }
}
</script>

<style lang="scss" scoped>
.summary_table {
    margin-top: 50px;

    .summary_action_visibility {
        cursor: pointer;
        font-weight: 300;

        &:hover {
            scale: 1.1;
            font-weight: 400;
            transition: 0.2s ease-in-out;
        }
    }
}

.mainTemplate {
    position: relative;
    flex: 0.7;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.templatessss {
    flex: 0.3;
    max-width: 400px;

}

.templates_header_div {
    min-height: 50px;
}

.overflow_div_templates {
    height: 60vh;
    cursor: pointer;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 50px;
    box-shadow: 1px 1px 10px rgb(216, 216, 216) inset;
    background: rgb(244, 244, 244);

}

.summary_templates_static {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0px;
    transition: 0.2s ease-in-out;

    img {
        height: 130px;
        border: 0.1px solid grey;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    &:hover {
        scale: 1.01;
        transition: 0.2s ease-in-out;

        img {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            border: 0.1px solid green;
            transition: 0.2s ease-in-out;
        }
    }
}

.summary_templates_static.active {

    font-weight: 700;
    scale: 1.1;

    img {
        border: 0.2px solid green;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    &:before {
        position: absolute;
        right: 58px;
        bottom: 34px;
        height: 10px;
        width: 3px;
        background-color: green;
        content: "";
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
    }

    &:after {
        position: absolute;
        right: 35px;
        bottom: 35px;
        height: 3px;
        width: 25px;
        background-color: green;
        content: "";
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
    }

}

.noSummSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
        font-weight: 700;
        color: #f974a6;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: blue;
            transition: 0.2s ease-in-out;
        }
    }

}

.new_summary_view_button {
    position: absolute;
    bottom: 10px;
    right: 1%;
}
.table thead th{
    border: none;
}
.table > tbody > tr{
    box-shadow: none;
}
.table th{
    padding: 0.5rem;
}
.summary{
    padding: 14px;

    &__body {
        width: 100%;
        margin-top: 20px;
        margin-left: 25px;
        margin-right: 30px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 25px;

        &__img {
            height: 55px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }
}
.summaryConf{
    right: 0px;
    width: 80vw;
    height: 100%;
    top: 0px;
    position: fixed;
    right: -92vw;
    transition: right 1s ease-in-out;
    width: 92vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 999;
    border-radius: 20px 0px 0px 20px;
    padding: 20px 25px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
    border: 1px solid rgb(236, 236, 236);
}
</style>