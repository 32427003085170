<template >
    <div>   
        <h2 style="text-align: center;">Details</h2>
        <!-- <div style="height: 250px;width: 300px;"> -->
            <!-- <BarChart :options="options"/> -->
        <!-- </div> -->
        <MYOrg :user="user" :showInModal="true"/>
    </div>
</template>
<script>
import BarChart from "../components/Charts/BarChart"
import MYOrg from "./MyOrganization.vue"
export default {
    components:{
        BarChart,
        MYOrg
    },
    props:{
        user:{
            type:String
        }
    },
    data(){
        return {options:{
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
    }
}
</script>
<style lang="">
    
</style>