<template lang="html">
    <div>
        <div>
            <div style="min-height: 75vh;">
                <!-- <h2 id="profile_header" style="text-align:center;margin-top:10px">Profiles</h2>
                <button @click="toggleProfile()" class="addProfileButton">
                    <i class="tim-icons icon-simple-add"></i>
                    Add
                    </button> -->

                <table class="table tablesorter">
                    <thead>
                        <slot name="columns">
                            <th v-for="column in columns" :key="column">
                                <span :id="'profile_table_'+column">{{ column }}</span>
                            </th>
                        </slot>
                    </thead>
                    <tbody>
                        <tr v-for="u, index of segments">
                            <td v-for="column in columns" :key="column">
                                <div v-if="column == 'Profile Name'">
                                    {{ u['name'] }}
                                </div>
                              
                                <div style="display: flex;" v-if="column == 'Members'">
                                    <div v-tooltip.bottom-end="mem"
                                        :style="{ background: getRandomColor(), height: '20px', width: '20px', textAlign: 'center', color: 'white', borderRadius: '50%', marginLeft: '10px' }"
                                        v-for="mem of u['members']">
                                        {{ mem[0].toString().toUpperCase() }}
                                    </div>
                                </div>
                                <div v-if="column == 'Summary Name'" style="width: 310px;display: flex;align-items: center;">
                                
                                    <span style="flex: 0.7;" v-if="index!=showSummaryNameChange">{{u['summary_name']}} </span>

                                    <i v-if="index!=showSummaryNameChange" v-tooltip="'Click to Edit Profile'" @click="handleEditProfileClick(index)"
                                        src="../../assets/penciledit.svg" style="cursor: pointer;"  class="edit tim-icons icon-pencil"></i>

                                    <div v-if="index==showSummaryNameChange" style="display:flex;align-items:center">
                                        
                                        <Multiselect  select-label="" class="multiStyle2"  :hide-selected="true" 
                                        :options="summaryList" :searchable="false"  v-model="selectedProfileName" >

                                        </Multiselect>

                                        <i   @click="handleEditProfileClick(index)" class="tim-icons icon-simple-remove"></i>
                                    </div>
                                </div>
                                <div v-if="column == 'Actions'">
                                   
                                        <i v-tooltip="'Delete Profiles'"  @click="deleteSeg(u, index)" style="color: red;cursor: pointer;"
                                class="tim-icons icon-trash-simple"></i>
                                  
                                        <i  v-tooltip="'Edit Entry'" g @click="addSeg(u)" style="font-size: 16px;margin-left: 10px;cursor:pointer;" class="tim-icons icon-pencil"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <modal :show-close="true" @close="closeModal()" v-if="showProfile" :show="showProfile">
            <i @click="closeModal()" class="tim-icons close-icon icon-simple-remove"></i>
            <div style="height: 100%;">
                <span id="profile_modal_header"
                    style="font-size: 18px;font-weight: 600;text-align: center;width: 100%;display: flex;justify-content: center;">Create
                    a new Profile</span>
                <br />
                <span style="font-size: 14px;font-weight: 600;text-align: center;width: 100%;margin-top: 10px;">Enter
                    profile
                    name</span><span style="color: red;margin-left:10px">*</span>
                <input v-model="segment.name" style="margin-top: 10px;margin-bottom: 10px;" class="inp"
                    placeholder="Enter profile Name" />
                <span id="profile_modal_user_name" style="font-size: 14px;font-weight: 600;text-align: center;width: 100%;margin-top: 20px;">Select
                    users</span>
                <span class="select_users">
                    <Multiselect v-model="segment.members" :multiple="true" style="margin:auto;margin-bottom: 10px;" :close-on-select="false"
                    placeholder="Select users you want to be a part" :hide-selected="true" :options="users">
                </Multiselect>
                </span>
                <span id="profile_modal_summary_name" style="font-size: 14px;font-weight: 600;text-align: center;width: 100%;margin-top: 20px;">Select
                    Summary</span><span style="color: red;margin-left:10px">*</span>
                <Multiselect v-model="segment['summary_name']" :options="summaryList" :close-on-select="false"
                    style="margin:auto;margin-bottom: 10px;">
                </Multiselect>

                <!-- <span style="font-size: 14px;font-weight: 600;text-align: center;width: 100%;padding-top: 20px;">Select
                    permission</span> -->
                <!-- <div style="display: flex;align-items: center;">
                    <input @change="handleCheckBox('summary', $event)"
                        style="margin-top: 10px;margin-bottom: 10px;margin-right: 10px;" type="checkbox" /> Summary
                    <input @change="handleCheckBox('launchpad', $event)"
                        style="margin-top: 10px;margin-bottom: 10px;;margin-left: 10px;margin-right: 10px"
                        type="checkbox" />Launch Pad
                </div> -->

                <button style="margin-top: 20px;" @click="saveSegment()"
                    v-if="this.segment.summary_name != '' && this.segment.name != ''">
                    Save
                </button>
                <button style="margin-top: 20px;background-color: grey !important;" v-else>Save</button>

            </div>

        </modal>
        <modal :show-close="true" @close="closeModal()" v-if="showCommandMembersModal" :show="showCommandMembersModal">
            <div style="height: 100%;">
                <span id="profile_modal_alert_heading"
                    style="font-size: 18px;font-weight: 600;text-align: center;width: 100%;display: flex;justify-content: center;">Common
                    Profile Alert</span>
                <br />
                <span> The following members exists in some profile already </span>
                <br />
                <br />
                <div style="display: flex;" v-for="mem of commonMembers">
                    <div v-tooltip.bottom-end="mem"
                        :style="{ background: getRandomColor(), height: '20px', width: '20px', textAlign: 'center', color: 'white', borderRadius: '50%', marginRight: '10px', paddingBottom: '24px' }">
                        {{ mem[0].toString().toUpperCase() }}
                    </div>
                    {{ mem }}
                </div>
                <br />

                <span> Do you want to force move them ?</span>
                <div style="display: flex;align-items: flex-end;justify-content: flex-end;">
                    <button style="margin-top: 20px;width: 20%;margin-right: 10px;border-radius: 20px;height: 40px;"
                        @click="forceSave()">
                        Yes
                    </button>

                    <button @click="showCommandMembersModal=false" style="margin-top: 20px;width: 20%;border-radius: 20px;height: 40px;">
                        No
                    </button>
                </div>


            </div>

        </modal>
        <modal :show-close="true" @close="closeModal()" v-if="showDeleteModaL" :show="showDeleteModaL">
            <div style="height: 100%;">
                <span
                    style="font-size: 18px;font-weight: 600;text-align: center;width: 100%;display: flex;justify-content: center;">Profile Delete Alert</span>
                <br />
                <span> {{ msg }}</span>
                <br />
                <br />
                <div style="display: flex;align-items: flex-end;justify-content: flex-end;">
                    <button style="margin-top: 20px;width: 20%;margin-right: 10px;border-radius: 20px;height: 40px;"
                        @click="forceDelete()">
                        Yes
                    </button>

                    <button @click="showDeleteModaL=false" style="margin-top: 20px;width: 20%;border-radius: 20px;height: 40px;">
                        No
                    </button>
                </div>


            </div>

        </modal>
    </div>
</template>
<script>
import Server from '../components/API/Server';
import Modal from "../components/Modal.vue"
import Multiselect from 'vue-multiselect';
import axios from 'axios';
export default {
    components: {
        Modal,
        Multiselect
    },
    props: {
        openWithUserMgmt: false,
        showOpenAddProfile: false
    },
    watch: {
    showOpenAddProfile(newVal) {
      if (newVal) {
        this.showProfile = true;
      }
    }
  },
    data() {
        return {
            segments: [],
            commonMembers: [],
            showCommandMembersModal: false,
            showDeleteModaL:false,
            deleteSegment:{},
            msg:'',
            showSummaryNameChange:-1,
            update: false,
            segment: {

                name: '',
                permission: [

                ],
                members: [

                ],
                "summary_name": [""]
            },
            users: [],
            summaryList: [],
            showProfile: false,

            columns: [
                "Profile Name",
                // "Launch Pad",
                // "Summary",
                "Members",
                "Summary Name",
                "Actions"
            ]
        }
    },
    async mounted() {
        this.summaryList = (await Server.getSummaryJson()).data
        this.summaryList = this.summaryList.map(d => d.summary_name)

        //for auto enabling this features
        this.segment.permission.push('summary');
        this.segment.permission.push('launchpad');

        const data = await Server.getUserSemnets();
        this.segments = data.data
        console.log(this.segments)
        this.users = (await Server.getAllUsers()).data
        this.users = this.users.map(data => data.email_id)
        if(this.showOpenAddProfile){
                this.showProfile = true;
        }
    },
    methods: {
        async addSeg(seg) {
            console.log(seg);
            this.update = true;
            this.showProfile = !this.showProfile
            this.users = (await Server.getAllUsers()).data
            this.users = this.users.map(data => data.email_id)
            this.segment.name = seg.name
            this.segment.members = seg.members
            this.segment.summary_name = seg.summary_name
            this.segment.permission.push(...seg.permission)
            this.$emit('change');
        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        async toggleProfile() {
            this.update = false;
            this.showProfile = !this.showProfile
            this.users = (await Server.getAllUsers()).data
            this.users = this.users.map(data => data.email_id)
        },
        async forceDelete(){
            
            const data = await Server.deleteSegments({...this.deleteSegment,method:"force"});
            if (data.code == 200){
                this.showDeleteModaL=false
                const data = await Server.getUserSemnets();
                this.segments = data.data
            }
            else{
                this.showDeleteModaL=false
                this.msg=data.msg
                this.$toasted.error(data.msg)
            }
        },
        handleCheckBox(name, event) {
            this.segment.permission=[]
            if (event.target.checked) {
                this.segment.permission.push(name)
            }
        },
        async updateSeg(seg, e) {
            seg['summary_name'] = e
            const data = await Server.forceSaveSeg(seg);
            if (data.code != 200) {
                this.$toasted.error(data.msg)
            } else {
                this.showProfile = false;
                this.$toasted.success("Added successfully")
                const data = await Server.getUserSemnets();
                this.segments = data.data
            }
        },
        handleEditProfileClick(ind){
            console.log(ind);
            if(ind==this.showSummaryNameChange){
                this.showSummaryNameChange = -1;
            }
            else{
                this.showSummaryNameChange = ind;
            }
        },
        async forceSave() {
            try {
                const data = await Server.forceSaveSeg(this.segment,true);
                if (data.code != 200) {
                    this.$toasted.error(data.msg)
                } else {
                    this.$toasted.success("Successfully saved the summary")
                    this.showCommandMembersModal = false;
                }
                const res = await Server.getUserSemnets();
                this.segments = res.data


            } catch (error) {
                this.showCommandMembersModal = false;
            }
        },
        async saveSegment() {
            console.log(this.segment)
            let data;
            if (this.update)
                data = await Server.forceSaveSeg(this.segment,true);
            else
                data = await Server.saveSegment(this.segment);
            if (data.code != 200) {
                this.$toasted.error(data.msg)
                if (data.commonMembers) {
                    this.commonMembers = data.commonMembers
                    this.showCommandMembersModal = true
                    this.showProfile = false
                }
            } else {
                this.showProfile = false;
                this.$toasted.success("Added successfully")
                const data = await Server.getUserSemnets();
                this.segments = data.data
            }
            this.$emit('change');
        },
        async deleteSeg(seg, i) {
            const data = await Server.deleteSegments(seg);
            if (data.code == 200)
                this.segments.splice(i, 1)
            else if(data.code==404){
                this.$toasted.error(data.msg)

            }else{
                this.showDeleteModaL=true
                this.deleteSegment=seg
                this.msg=data.msg
                this.$toasted.error(data.msg)
            }

        },
        closeModal() {
            this.showProfile = !this.showProfile;
            this.$emit('change');
            this.update = false;
            this.users = []
            this.segment = {

                name: '',
                permission: [],
                members: [],
                "summary_name": ""
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.table th{
    border-top: none;
}
.inp {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
}

.multiselect__tags {
    border: none !important;
    border-bottom: 1px solid #e8e8e8 !important;
}

.addProfileButton {
    width: 150px;
    height: 30px;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    top: 25px;
}

.multiStyle2 {
    margin-right:10px;
    margin-top: 0px !important;
    width: 13rem !important;
}
</style>
<style>
.select_users{
    .multiselect__tags-wrap{
        min-height: 40px !important;
            max-height: 193px !important;
        display: block !important;
        overflow: scroll !important;
    }   
}
</style>