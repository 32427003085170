<template>
    <div class="card" style="padding: 12px 20px;">
        <Modal @close="openPreviewPopup = false" class="preview_user_modal" id="searchModal" :centered="true"
            :show-close="true" :show="openPreviewPopup" v-if="openPreviewPopup">
            <img style="position: absolute;right: 20px;top: 20px;" src="../icons/Close.svg" @click="openPreviewPopup = false" />
            <div style="height: 100%;" class="">
                <div v-html="addWidgetData.content"
                    style="height: 100%;overflow-y: scroll;overflow-x: hidden;padding: 20px;" class="noscroll">
                </div>
            </div>
        </Modal>
        <div style="margin: 0px 0px; display: flex; justify-content: space-between; align-items: center;">
            <div class="widgetType__header">
                <img src="../icons/widgetContent.png" style="height: 60px;" alt="">
                <div style="width: 60%;" class="">
                    <h2 class="widgetType__header__title">Widget Content</h2>
                    <span class="widgetType__header__desc">Enable in-app 360 degree aggregated view widget for your
                        enterprise data</span>
                </div>
            </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: 1rem;">
            <GlobalTabs @selectTab="changeTab($event)" :notIdx="true" :tabs="tabs"/>
            <div>
                <AddButtonGlobal v-if="!(selectedTab == tabs[2].toLowerCase()  || selectedTab==tabs[3].toLowerCase())" @button="handleAddOption($event)"/>
            </div>
        </div>
        <div style="height: 68vh;">
            <GlobalTable v-if="widgetData.length > 0 && !loading && selectedTab!='account summary'" :showAllTooltips="true" :columns="columns" :tableData="widgetData" @handleAction="handleAction($event)" />
            <div v-else-if="!loading && selectedTab=='account summary'">
                <NewSummaryView ref="childComponent" :openType="fromWidgetContent" />
            </div>
            <div v-else-if="loading" style="display: flex; justify-content: center; align-items: center; height: 50vh; overflow: scroll;">
                <img src="../icons/loadingripple.svg" style="height: 200px;" alt="">
            </div>
            <div v-else-if="!loading && widgetData.length == 0"
                style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
                <img src="../icons/noData.svg" style="height: 200px;" alt="">
            </div>
        </div>
        <div class="widgetTypeConfiguration">
            <h2 style="text-align: center;">Widget Configuration</h2>
            <div class="" style="position: absolute;right: 0px;top: 0px;">
                <img style="cursor: pointer;" src="../assets/img/feed_close.svg" @click="close" />
            </div>
            <div>
                <div class="tabs" style="display: flex; align-items: center;">
                    <div class="tab selectedTab" style="border-bottom: 2px solid blue; font-weight: 700;">
                        Configuration
                    </div>
                </div>
                <div class="body"
                    style="padding: 20px; display: flex; align-items: flex-start; justify-content: space-between;">
                    <div class="leftbar"
                        style="width: 35%; display: flex; align-items: flex-start; flex-direction: column; margin-top: 10px;">
                        <input id="nudgename" class="nudgename"
                            style="padding: 4px; outline: none; width: 100%; font-size: 12px; border: none; border-bottom: 1px solid #dbd7d7;"
                            v-model="addWidgetData.name" type="text" placeholder="Enter a unique name" />
                        <textarea id="nudgeMsg" class="nudgedesc"
                            style="margin-top: 25px; width: 100%; font-size: 12px; outline: none; border: none; border-bottom: 1px solid #dbd7d7;"
                            v-model="addWidgetData.description" rows="5" type="text"
                            placeholder="Enter description for the playbook. This will be visible for the playbook participants when they get the alert" />
                        <div class="" v-if="addWidgetData.type=='combo'">
                            <div      draggable="true"
           @dragstart="dragStart(list, $event)" class="" v-for="list of listData">
                                {{ list.name }}
                                {{ list.type }}
                            </div>
                        </div>
                        <!-- <Multiselect style="margin-top: 2rem !important;" placeholder="Select Widget Type" :options="['announcement', 'data object list']" v-model="addWidgetData.type"/> -->
                    </div>
                    <div class="rightbar"
                        style="padding: 20px; margin-left: 25px;  width: 60%; height: 80vh; border-radius: 12px;padding-bottom: 20px;">
                        <div style="float: right; margin: 10px;" v-tooltip="'Delete Widget'">
                            <img v-if="isEditing" @click="handleDeleteWidget" src="../icons/del.svg" style="filter: invert(11%) sepia(94%) saturate(7149%) hue-rotate(359deg) brightness(98%) contrast(110%); cursor: pointer; height: 18px;" alt="">
                        </div>
                        <div v-if="addWidgetData.type == 'announcement'">
                            <!-- <textarea name="" style="height: 100%; width: 95%; margin: 20px; outline: none; border: 1px solid rgb(232, 232, 232);" id="" cols="30" rows="10"></textarea> -->
                            <!-- <ckeditor type="inline" :config="editorConfig" ></ckeditor> -->

                            <vue-editor 
                            :editorToolbar="customToolbar"
                            v-model="addWidgetData.content"
                                style=" overflow: scroll; background-color: white; width: 97%; margin-left: 1rem;"></vue-editor>
                            <div class="tags">
                                <div class="tag" v-for="(tag,idx) of addWidgetData.variables">
                                    {{tag.name}}
                                    <img @click="handleRemoveElement(tag._id,idx)" src="../icons/feed_close.svg" style="height: 18px;"/>
                                </div>
                            </div>
                            <div class="" style="position: relative;">
                                <div class="openMoreOptions" v-if="openMoreOptions">
                                    <div @click="handleAddList(list)" class="options" v-for="list of objectListData">{{
                list.name }}</div>
                                </div>
                                <img v-tooltip="'Insert Dynamic Variable'" src="../assets/brackets.png"
                                    style="height: 22px;margin-left: 10px; cursor: pointer;"
                                    @click="openMoreOptions = !openMoreOptions;" alt="">

                            </div>
                            <!-- <Multiselect v-model="listSelect" @select="handleAddList" :options="objectListData" label="name"/> -->
                            <!-- <button @click="">Add List</button> -->
                        </div>
                        <div class="" v-else-if="addWidgetData.type=='combo'">
                            <div 
                            style="height: 100%;"
        @drop="dropItem"
                            >
                            <GridLayout
        class="grid-container" 

                           :class="{ 'empty-grid': addWidgetData.layout.length === 0 }"
      :layout.sync="addWidgetData.layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      ref="gridLayout"
      :use-css-transforms="true"
     

    >
      <GridItem 
      class="griditem"
      v-for="item in addWidgetData.layout"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :key="item.i">
        <!-- Your widget content -->
        <div >{{ item.name }}</div>
      </GridItem>
      <div   class="" v-if="addWidgetData.layout.length==0">
        <h2>Drag Widgets Here</h2>
    </div>
    </GridLayout>
</div>
                        </div>
                        <div v-else>
                            <div>
                                <Multiselect style="width: 20rem !important;" :options="businessObjsList"
                                    v-model="addWidgetData.businessObj" :placeholder="'Select Business Object'" />
                            </div>
                            <div v-if="addWidgetData.businessObj" style="display: flex; align-items: center; justify-content: space-between;">
                                <div style="flex: 0.6;">
                                    <!-- <div style="font-size: 18px;">Edit form view</div>
                                    <p style="color: black;">Widget Fields can be updated through AppEQ interface and
                                        bi-directional synced to underlying CRM in real time. We support upto 5 fields
                                        for updation through AppEQ.</p> -->
                                </div>
                                <div v-if="addWidgetData.businessObj" @click="handleFilter" style="color: blue; font-weight: 600; cursor: pointer;">+ Add
                                    filter condition</div>
                            </div>
                            <div style="height: 100px;">
                                <h3 v-if="addWidgetData.filter.length > 0" style="margin-bottom: 4px;margin-left: 8px;">
                                    Filter Condition</h3>
                                <div v-if="addWidgetData.filter.length > 0" class="row"
                                    style="height: 80px; overflow: scroll; margin-left: 5px;margin-top: 0px !important;">
                                    <div style="margin:3px 6px;display: flex;font-size: 16px;align-items: center;"
                                        v-for="(fil, idx) of addWidgetData.filter">
                                        <div style="position: relative; height: 35px !important;" class="filerBox">
                                            {{ fil.str }}
                                            <img @click="deleteFilterCond(idx)" class="delete"
                                                src="../assets/img/feed_close.svg"
                                                style="height: 20px;position: absolute;right: 0px;top: 0px;" />
                                        </div>
                                        <div class="" v-if="idx < addWidgetData.filter.length - 1">
                                            and
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div v-if="addWidgetData.businessObj != ''"
                                style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="flex: 0.6;">
                                 
                                </div>
                                <div style="color: blue; font-weight: 600; cursor: pointer;"
                                    @click="showConfigureKeysModal = true;">
                                    Configure Keys
                                </div>
                            </div>
                            <div class="tableForFeedListColumn">
                                <div v-if="addWidgetData.view.length != 0" class="rowConfigure">
                                    <div class="item"
                                        style="background: white; font-size: 12px; padding: 4px; border-radius: 6px;"
                                        v-for="key of addWidgetData.view">
                                        {{ key }}
                                    </div>
                                </div>
                                <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;"
                                    class="" v-else>
                                    <img src="../assets/nodata.svg" style="height: 70px;flex-direction: column;" />
                                    <div class="">
                                        Not Configured. System selected fields will be displayed.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style="padding: 20px; display: flex; align-items: center; justify-content: flex-end; width: 100%; position: absolute; bottom: 5px; right: 25px;">
                <button @click="handlePreview({content:addWidgetData.content})"
                    style="flex: 0.1 1 0%; height: 35px; width: 37px; border-radius: 8px;margin-right: 20px;background: white;color: #4e5fff;border: 1px solid #4e5fff">Preview</button>
                <button @click="handleSave"
                    style="flex: 0.1 1 0%; height: 35px; width: 37px; border-radius: 8px;">Save</button>
            </div>
        </div>
        <div v-if="showAddFilterModal"
            style="height: 100%; width: 100%; position: fixed; top: 0px; left: 0px; z-index: 9999999999999; background-color: rgba(0, 0, 0, 0.2); border-radius: 9px;">
            <FilterModal @save="handleSaveFilter($event)" @close="showAddFilterModal = false;"
                :business_object_name="addWidgetData.businessObj" :nudgeFilter="true" />
        </div>
        <div v-if="showConfigureKeysModal"
            style="height: 100%; width: 100%; position: fixed; top: 0px; left: 0px; z-index: 9999999999999; background-color: rgba(0, 0, 0, 0.2); border-radius: 9px;">
            <KeysConfigurationModal :bObj="addWidgetData.businessObj" :selectedKeys="addWidgetData.view"
                @save="handleUpdatedKeys($event)" @close="showConfigureKeysModal = false;" />
        </div>
    </div>
</template>
<script>
import MyServer from '../components/API/Server';
import { VueEditor } from "vue2-editor";
import { GridLayout, GridItem } from 'vue-grid-layout';
import AddButtonGlobal from "../layout/dashboard/AddButtonGlobal.vue";
import Modal from '../components/Modal.vue';
// import { Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar } from '@ckeditor/ckeditor5-image';
import Multiselect from "vue-multiselect";
import FilterModal from '../components/FilterModal.vue';
import KeysConfigurationModal from "../components/KeysConfigurationModal.vue";
import LocalUtils from '../components/Utils/LocalUtils';
import NewSummaryView from './Configuration/newSummaryView.vue';
import GlobalTabs from '../layout/dashboard/GlobalTabs.vue';
import GlobalTable from '../layout/dashboard/GlobalTable.vue';
export default {
    async mounted() {
        this.loading = true;
        this.widgetData = this.announcementData;
        await this.initData('announcement');
        this.loading = false;
    },
    data() {
        return {
            openMoreOptions: false,
            showSummaryComp: false,
            fromWidgetContent: false,
            businessObjsList: [],
            widgetData: [],
            dashData: [],
            customToolbar: [
            [{ list: "ordered" }, { list: "bullet" }],
            [ { align: "center" }, { align: "left" }],
            ["bold", "italic","color"],
        ["image","link","video"]
      ],
            editorConfig: {
                removePlugins: "elementspath,exportpdf,magicline,resize,justify",
                extraPlugins: ["divarea", "stylesheetparser", "sourcearea"],
                toolbar: [
                    {
                        items: [
                            "Source",
                            "-",
                            "Bold",
                            "Italic",
                            "RemoveFormat",
                            "-",
                            "SpecialChar",
                        ],
                    },
                ],
            },
            selectedTab: 'Announcement',
            openAddOptionModal: false,
            addWidgetData: {
                name: '',
                variables: [],
                layout: [],
                description: '',
                type: 'announcement',
                content: '<h3>Some initial content</h3>',
                businessObj: '',
                filter: [],
                view: []
            },
            loading: false,
            isEditing: false,
            openPreviewPopup: false,
            // customToolbar: [
            //     ["bold", "italic", "underline", "strike", "heading"],
            //     [{ align: "right" }, { align: "center" }, { align: "left" }],
            //     [{ list: "ordered" }, { list: "bullet" }]
            // ],
            showURL: false,
            taskListData: [],
            dashboardData: [],
            listSelect: "",
            accountSummaryData: [],
            announcementData: [],
            objectListData: [],
            listData:[],
            showAddFilterModal: false,
            showConfigureKeysModal: false,
            columns: [
                { key: 'name', title: 'Name', width: '30%' },
                { key: 'description', title: 'Description', width: '40%' },
                // { key: 'type', value: 'Type', width: '15%' },
                { key: 'createdBy', title: 'Modifed', width: '20%' },
                { key: 'action', title: 'Action', width: '10%' }
            ],
            tabs: ['Announcement', 'Account Summary','Dashboard', 'Tasklist']
        }
    },
    methods: {
        dragStart(widget, event) {
            console.log("IN DRAG")
      event.dataTransfer.setData("text", JSON.stringify(widget));
    },
    handleAction(action){
        switch (action[0]) {
            case 'openConfigure':
                this.handleEditWidget(action[1]);
                break;
            case 'openPreview':
                this.handlePreview(action[1]);
                break;
        
            default:
                break;
        }
    },
    dropItem(event, targetItem) {
        console.log(targetItem)
        console.log("IN DROP")
        console.log(event)
        const gridRect = this.$refs.gridLayout.$el.getBoundingClientRect();
  
  // Calculate the relative position of the drop inside the grid
  const x = event.clientX - gridRect.left;
  const y = event.clientY - gridRect.top;
      const widgetData = JSON.parse(event.dataTransfer.getData("text"));
      const newItem = {
        ...widgetData,
        x: x,
        i:widgetData._id,
        y: y,
        w: 5,
        h: 5,
      };
      this.addWidgetData.layout.push(newItem);
    },
        handleRemoveElement(id,idx){
            document.getElementById(id).remove()
            this.addWidgetData.variables.splice(idx,1)
        },
        handleAddList(event) {
            this.openMoreOptions=false
            this.listSelect = event
            this.addWidgetData.variables.push(event)
            console.log("here")
            console.log(this.listSelect)
            const email = LocalUtils.getEmail()

            this.addWidgetData.content += `
                    <iframe id=${event._id} frameborder="0" allowfullscreen='true' class="ql-video" src='https://app.appeq.ai/popup?email=${email}&widget=${JSON.stringify([{ widget: this.listSelect, type: "business object list" }])}&show=no' />
            `
            // setTimeout(() => {
            //     if(document.getElementById("appeqPopup"))
            //     document.getElementById("appeqPopup").contentWindow.postMessage({ widget: [{widget:this.listSelect,type:"data object list"}], email: email }, "*")

            // }, 3000);
        },
        handlePreview(data) {
            if(data){
                this.addWidgetData.content = data.content;
            }
            this.openPreviewPopup = !this.openPreviewPopup
        },
        async initData(widgetType) {
            console.log(widgetType, 'what it is');
            this.loading = true;
            let [data, dash] = await Promise.all([MyServer.getWidgetContent(), MyServer.getSupportedApps()]);
            console.log(data, dash, 'idhar hai data');
            let respData = [];
            if (data.code == 200) {
                respData = data.data;
            }
            if(dash.data.code == 200){
                let dashes = dash.data.configs.dashboards;
                for(let i=0;i<dashes.length;i++){
                    this.dashData.push({'name': dashes[i].metabaseTitle, 'description': '', 'createdBy': ''});
                }
            }
            this.listData=respData
            this.selectedTab = widgetType;
            this.changeTab(this.selectedTab);
            this.loading = false;
        },
        handleEditWidget(data) {
            this.handleAddOption();
            this.isEditing = true;
            this.addWidgetData = data;
        },
        async handleDeleteWidget() {
            try {
                console.log(this.addWidgetData);
                let resp = await MyServer.deleteWidgetContent(this.addWidgetData);
                console.log(resp);
                this.taskListData = [];
                this.announcementData = [];
                this.accountSummaryData = [];
                this.dashboardData = [];
                this.objectListData = [];
                await this.close();
                await this.initData(this.addWidgetData.type);
                this.$toasted.success('Widget deleted successfully.')
            } catch (error) {

            }
        },
        handleFilter() {
            if (this.addWidgetData.businessObj == '' || !this.addWidgetData.businessObj) {
                this.$toasted.error('Please select business object');
                return;
            }
            this.showAddFilterModal = true;
        },
        handleSaveFilter(e) {
            console.log(e);
            this.addWidgetData.filter.push(e);
            console.log(this.addWidgetData);
            this.showAddFilterModal = false;
        },
        deleteFilterCond(idx) {
            this.addWidgetData.filter.splice(idx, 1);
        },
        handleUpdatedKeys(e) {
            console.log(e, 'keys');
            this.addWidgetData.view = e;
            console.log(this.addWidgetData)
        },
        changeTab(tab) {
            if(tab==this.tabs[5]){
                this.widgetData = [];
                this.selectedTab = ''
                this.$toasted.info("Feature Disabled contact support team")
                return
            }
            if(tab==this.tabs[1]){
                this.selectedTab = tab.toLowerCase();
                this.showSummaryComp = true;
                this.fromWidgetContent = true;
                return;
            }
            this.selectedTab = tab.toLowerCase();
            if(this.selectedTab=='dashboard'){
                this.widgetData = this.dashData;
            }else{
                this.widgetData=this.listData.filter(d=>d.type==tab.toLowerCase())
            }
            console.log(this.widgetData, 'widget data just before')
            for(let i=0;i<this.widgetData.length;i++){
                if(this.widgetData[i].type=='announcement' || this.widgetData[i].type=='business object list'){
                    this.widgetData[i]['action'] = [{'img':'View.png', 'action': 'openPreview', 'hover': 'Preview'},{'img':'ConfigureIcon.png', 'action': 'openConfigure', 'hover': 'Configure'}];
                }
            }
            if(this.selectedTab=='announcement' || this.selectedTab=='business object list'){
                this.columns = [
                    { key: 'name', title: 'Name', width: '30%' },
                    { key: 'description', title: 'Description', width: '40%' },
                    // { key: 'type', value: 'Type', width: '15%' },
                    { key: 'createdBy', title: 'Modified', width: '20%' },
                    { key: 'action', title: 'Action', width: '10%' }
                ]
            }else{
                this.columns = [
                    { key: 'name', title: 'Name', width: '30%' },
                    { key: 'description', title: 'Description', width: '40%' },
                    // { key: 'type', value: 'Type', width: '15%' },
                    { key: 'createdBy', title: 'Modified', width: '15%' }
                ]
            }
        },
        async handleAddOption() {
            if(this.selectedTab==this.tabs[1].toLowerCase()){
                console.log("HERE")
                this.$refs.childComponent.openAddsumm();

                return
            }
            this.openAddOptionModal = true;
            this.addWidgetData.type = this.selectedTab.toLowerCase()
            console.log(this.addWidgetData)
            let data = await MyServer.getBusinessDataObs();
            console.log(data);
            if (data.code == 200) {
                this.businessObjsList = data.data.map((data) => {
                    return (
                        data.business_object_name
                    )
                });
                console.log(this.businessObjsList);
            }
            setTimeout(() => {
                document.querySelector(".widgetTypeConfiguration").style.right = "0px"
              
            }, 400)
           
        },
        close() {
            this.openAddOptionModal = false;
            document.querySelector(".widgetTypeConfiguration").style.right = "-92vw"
            setTimeout(() => {
                this.addWidgetData = {
                    name: '',
                    description: '',
                    type: this.widgetType,
                    content: '<h3>Some initial content</h3>',
                    businessObj: '',
                    filter: [],
                    view: [],
                    layout:[],
                }
                this.isEditing = false;
            }, 1000);
        },
        async handleSave() {
            if (!this.addWidgetData.name || !this.addWidgetData.description || this.addWidgetData.type == '') {
                this.$toasted.error('Please fill all the fields');
                return;
            }
            if (this.isEditing) {
                let data = { ...this.addWidgetData };
                let resp = await MyServer.updateWidget(data);
                console.log(resp);
                this.$toasted.success('Widget updated successfully.')
            } else {
                let resp = await MyServer.addWidget(this.addWidgetData);
                console.log(resp);
                this.$toasted.success('Widget saved successfully.')
            }
            this.taskListData = [];
            this.announcementData = [];
            this.accountSummaryData = [];
            this.dashboardData = [];
            this.objectListData = [];
            this.close();
            await this.initData(this.addWidgetData.type);
        }
    },
    components: {
        VueEditor,
        Multiselect,
        Modal,
        FilterModal,
        NewSummaryView,
        KeysConfigurationModal,
        AddButtonGlobal,
        GlobalTabs,
        GlobalTable,
        GridItem,
        GridLayout
        // GridLayout: VueGridLayout.GridLayout,
        //    GridItem: VueGridLayout.GridItem


    }
}
</script>
<style lang="scss" scoped>
.widgetType {
    padding: 14px;

    &__body {
        width: 100%;
        margin-top: 20px;
        margin-left: 25px;
        margin-right: 30px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-top: 1vw;
        margin-left: 1.25vw;

        &__img {
            height: 55px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }
}

.widgetTypeConfiguration {
    position: fixed;
    right: -92vw;
    transition: right 1s ease-in-out;
    width: 92vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 999;
    border-radius: 20px 0px 0px 20px;
    padding: 20px 25px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
    border: 1px solid rgb(236, 236, 236);

}

.card {
    border-radius: 0.2857rem;
    background-color: white;
}

.vueEditor {
    .ql-toolbar .ql-snow {
        position: fixed;
        top: 21%;
        z-index: 999999;
    }

    .ql-container .ql-snow {
        overflow: scroll;
        max-height: 42vh;
    }
}
.tags{
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 20px;
}
.tag {
    
    border-radius: 12px;
    /* width: 40px; */
    /* height: 14px; */
    font-size: 10px;
    background: #85a4f3;
    color: #fff;
    text-align: center;
    padding: 2px 6px;
    margin-left: 4px;
}

.openMoreOptions {
    height: 12vh;
    border: 1px solid #b3b3b3;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 12vw;
    padding: 20px;
    position: absolute;
    left: 2%;
    bottom: 45px;
    background: aliceblue;
}
.options{
    cursor: pointer;
}

.rowConfigure {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    justify-content: space-between;
}
.griditem{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef

}
</style>
<style>
#quill-container {
    height: 60vh;
}

.ql-image,
.ql-video {
    /* display: none; */
}

p {
    color: black !important;
}
</style>
<style lang="scss">
iframe {
    &.ql-video {
        height: 100% !important;

    }
}

.ql-editor .ql-video {
    height: 100%
}
.ql-snow .ql-tooltip{
    left: 120px !important;
}
.ql-snow .ql-tooltip[data-mode=video]::before{
    content: "Enter Url" !important;
}
.empty-grid {
  border: 2px dashed #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-container{
    min-height: 250px !important;
    background-color: red;
    max-height: 400px;
}

</style>