<template>
    <div class="user_configuration">
        <h2 style="text-align: center;margin-top: 20px;margin-bottom: 10px;">{{ getUserName }}</h2>
        <div class="" style="position: absolute;right: 0px;top: 0px;">
            <img style="cursor: pointer;" src="../assets/img/feed_close.svg" @click="close" />
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;padding-right: 25px;">
            <div class="tabs">
                <GlobalTabs :tabs="['Configuration', 'Analytics']" @selectTab="changeTab($event)"/>
            </div>
        </div>
        <div v-if="selectedTabIdx==0 && !loading">
            <div style="height: 75vh;">
                <div style="display: flex; flex-direction: column; justify-content: center; height: 70%;">
                <div style="display: flex; justify-content: space-between; align-items: center; flex-direction: column; height: 50%;">
                    <div style="display: flex; justify-content: space-between; width: 50%; align-items: center;">
                        <div style="display: flex; justify-content: space-between; align-items: center; width: 19%;">
                            <label for="" style="font-size: 1.15rem;">Status</label>
                            <label class="switch">
                                <input type="checkbox" v-model="status">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center; width: 40%;">
                            <label for="" style="font-size: 1.15rem;">Type</label>
                            <Multiselect style="width: 13vw !important;" :options="['User','System Admin']" v-model="type" />
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;align-items: center;width: 50%;">
                        <label for="" style="font-size: 1.15rem;">Role</label>
                        <div style="width: 90%; padding: 20px; border: 1px solid gainsboro; display: flex; justify-content: space-around; border-radius: 10px;">
                            <div style="display: flex; align-items: center; justify-content: space-between;width: 25%;">
                                <input type="radio" value="Account Owner" name="" v-model="role" id="">
                                <span style="font-size: 1rem;">Account Owner</span>
                            </div>
                            <div style="display: flex; align-items: center; justify-content: space-between;width: 16%;">
                                <input type="radio" value="Manager" name="" v-model="role" id="">
                                <span style="font-size: 1rem;">Manager</span>
                            </div>
                            <div style="display: flex; align-items: center; justify-content: space-between;width: 41%;">
                                <input type="radio" value="Account Owner + Manager" name="" v-model="role" id="">
                                <span style="font-size: 1rem;">Account Owner + Manager</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="needManager" style="display: flex; align-items: center; width: 50%;">
                        <label style="width: 23%; font-size: 1.15rem;" for="">Manager</label>
                        <Multiselect v-model="manager" style="width: 13vw !important;" :options="managersList"/>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; width: 50%;">
                        <label style="width: 30%; font-size: 1.15rem;" for="">KPI / OKR Sheet</label>
                        <!-- <input type="text" style="border: none; border-bottom: 1px solid grey; width: 100%;"> -->
                        <textarea v-model="sheetUrl" placeholder="https://" name="" id="" style="width: 100%; border-radius: 10px; resize: none; border: 1px solid gainsboro; padding-top: 1.1vh;padding-left: 1.1vh; height: 5.2vh;" rows="2"></textarea>
                    </div>
                </div>
            </div>
            </div>
            <div style="display: flex; justify-content: end;">
                <div style="display: flex; justify-content: space-between; align-items: center; width: 15vw;">
                    <button @click="close" style="background: white; color: black; border: 1px solid black; border-radius: 7px; width: 7vw; height: 3vh; cursor: pointer;">Cancel</button>
                    <button style="background: #6666ff; color: white; border: none; border-radius: 7px; height: 3vh; width: 7vw; cursor: pointer;" @click="handleSave">Save</button>
                </div>
            </div>
        </div>
        <div v-else-if="selectedTabIdx==1 && !loading">
            <iframe id="appeqIframe" style="height: 80vh !important;border: none;" :src="iframeSrc"></iframe>
        </div>
        <div v-else style="display: flex;align-items: center;justify-content: center;">
            <img v-if="loading" src="../icons/loadingripple.svg" style="height: 120px;" />

        </div>
    </div>
</template>
<script>
import GlobalTabs from "../layout/dashboard/GlobalTabs.vue";
import { JWT } from "node-jsonwebtoken";
import Multiselect from "vue-multiselect";
import Server from "./API/Server";
export default{
    data(){
        return {
            userCreds: {},
            loading: false,
            getUserName: '',
            selectedTabIdx: 0,
            status: false,
            iframeSrc: "",
            sheetUrl: '',
            needManager: false,
            manager: '',
            managersList: [],
            type: '',
            orgId: '',
            role: 'Account Owner',
            tabs: ['Configuration', 'Analytics']
        }
    },
    props: {
        userData: {}
    },
    async mounted(){
        this.loading = true;
        this.userCreds = this.userData;
        this.orgId = this.userCreds.org_id;
        if(this.userCreds.role){
            this.role = this.userCreds.role;
        }
        if(this.userCreds.status){
            this.status = this.userCreds.status;
        }
        if(this.userCreds.isAdmin && this.userCreds.isAdmin==true){
            this.type = 'System Admin';
        }else{
            this.type = 'User';
        }
        if(this.userCreds.kpi_sheet_url){
            this.sheetUrl = this.userCreds.kpi_sheet_url;
        }
        let id = this.userData.email_id.toString();
        this.getUserName = id.split('@')[0].charAt(0).toUpperCase() + id.split('@')[0].slice(1);
        console.log(this.userData);
        setTimeout(() => {
            document.querySelector(".user_configuration").style.right = "0px"

        }, 600)
        if(this.role == 'Account Owner' || this.type == 'User'){
            this.needManager = true;
            if(this.userData.manager){
                this.manager = this.userData.manager;
            }
            let list = (await Server.getAllUsers()).data;
            console.log(list ,'list');
            for(let i=0; i<list.length;i++){
                if(list[i].isAdmin == true){
                    this.managersList.push(list[i].email_id);
                }
            }
            console.log(this.managersList, 'manager list');
        }
        this.loading = false;
    },
    methods: {
        async changeTab(e){
            console.log(e)
            this.selectedTabIdx = e;
            if(this.selectedTabIdx==1){
                await this.openDash();
            }
        },
        removeUser(){
            this.$emit('removeUser', this.userCreds);
        },
        close(){
            this.$emit('close')
        },
        async handleSave(){
            try {
                if(!this.role || !this.sheetUrl || !this.type){
                    console.log(this.role, this.sheetUrl, this.type);
                    this.$toasted.error('All fields are required');
                    return;
                }
                console.log(this.userCreds, 'user cred data')
                let resp = await Server.updateUserData(this.sheetUrl, this.role, this.type, this.status, this.userCreds.email_id, this.manager);
                console.log(resp, 'kya hai ye');
                if(resp.data.code == 200){
                    this.$toasted.success('Successfully updated data');
                }else{
                    this.$toasted.error('Error updating data');
                }
            } catch (error) {
                console.log(error);
                this.$toasted.error('Some error occured');
            }
            this.$emit('save');
        },
        async openDash(){
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard: 133 },
                params: {
                    appeq_org_id: this.orgId,
                    email:this.userCreds.email_id
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            console.log(token,'whats this')
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            this.iframeSrc = iframeUrl;
        },
    },
    components: {
        GlobalTabs,
        Multiselect
    }
}
</script>
<style scoped lang="scss">
.user_configuration {
    position: fixed;
    right: -92vw;
    transition: right 1s ease-in-out;
    width: 92vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 999;
    border-radius: 20px 0px 0px 20px;
    padding: 13px 25px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
    border: 1px solid rgb(236, 236, 236);

}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7e7e7;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
::placeholder {
  color: grey;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: grey;
}
</style>