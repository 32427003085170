// import feeds from "../instance";
// import Constant from "../../util/constants";
// import Mixpanel from "../../analytics/mixpanel";
// import cache from "../../util/cache";
const apiURL = "https://api1.appeq.ai"
// import _ from "lodash";
import axios from "axios";
// const axios = require("axios");
// const { Base64 } = require("js-base64");
let zendex_domain = "";
let zendex_token = "";
if (
  localStorage.getItem("zendex_cred") &&
  JSON.parse(localStorage.getItem("zendex_cred"))["zendex_domain"] != ""
) {
  zendex_domain = JSON.parse(localStorage.getItem("zendex_cred"))[
    "zendex_domain"
  ];
  zendex_token = JSON.parse(localStorage.getItem("zendex_cred"))[
    "zendex_token"
  ];
}
class ApiServer {
  constructor() {
    const self = this;

    // feeds.getAllProps().then(function (response) {
    //   self.app_token = response.data[Constant.APP_TOKEN];
    //   self.nakama_userid = response.data[Constant.NAKAMA_USER_ID];
    // });
  }
  async getNotificationSettings() {
    const my_email_id = localStorage.getItem("__email__id");

    const data = { email_id: my_email_id };
    return (await axios.post(`${apiURL}/get-notification-data`, data)).data;
  }

  // utility methods.
  async post(route, data) {
    if (!data) {
      data = {};
    }
    // eslint-disable-next-line no-undef
    return await axios.post(`${apiURL}/${route}`, data, {
      headers: { "x-access-tokens": this.app_token },
    });
  }

  async appToken() {
    const chrome_props = (await feeds.getAllProps()).data;
    return chrome_props[Constant.APP_TOKEN];
  }

  async savePulse(query) {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/pulse/save`, {
        email: my_email_id,
        ...query,
      });
      return data.data;
    } catch (error) { }
  }
  async getAllQuestions(account_domain, quesid) {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/genie/all`, {
        email: my_email_id,
        account_domain: account_domain,
        id: quesid,
      });
      return data.data;
    } catch (error) { }
  }

  async getMyQuestions(account_domain) {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/genie/my-account`, {
        email: my_email_id,
        account_domain: account_domain,
      });
      return data.data;
    } catch (error) { }
  }

  async insertAnswers(account_domain, prompt, quesid, answer) {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/genie/insert-question`, {
        account_domain: account_domain,
        user: "gaurav@appeq.ai",
        promt: prompt,
        question_id: quesid,
        answer: answer,
        email: "all",
      });
      return data.data;
    } catch (error) { }
  }

  async getCoins() {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/genie/coins`, {
        email: my_email_id,
      });
      return data.data;
    } catch (error) { }
  }

  async insertAnswersforme(account_domain, prompt, quesid, answer) {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/genie/insert-question`, {
        account_domain: account_domain,
        user: my_email_id,
        promt: prompt,
        question_id: quesid,
        answer: answer,
        email: my_email_id,
      });
      return data.data;
    } catch (error) { }
  }

  async askQuest(account_domain, quesid) {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = await axios.post(`${apiURL}/genie/ask`, {
        email: my_email_id,
        account_domain: account_domain,
        ques: quesid,
      });
      return data.data;
    } catch (error) { }
  }

  async getalluniquefields(app) {
    const app_token = await this.appToken();
    const my_email_id = localStorage.getItem("__email__id");
    // console.log("Api Call", app_token, "--", my_email_id)
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/${app}/ticket/unique_fields_by_user `,
      {
        email: my_email_id,
        token: zendex_token,
        domain: zendex_domain,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async ZendeskAuthentication(email, domain, token) {
    const app_token = await this.appToken();
    // const chrome_props = (await feeds.getAllProps()).data;
    // console.log(chrome_props, "--PROPS")
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/credentials`,
      {
        email: email,
        domain: domain,
        token: token,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async filterConfig(data) {
    const app_token = await this.appToken();
    // console.log("Api Call", app_token, "--", data)
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/filter`,
      {
        config: data,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }

  async getAllUsersByOrg(user_id) {
    // const res = await this.appId();
    const app_token = await this.appToken();
    // let user_id = res['user_id'];
    // let token = res['app_token']
    let data = {
      id: user_id,
    };
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/users_by_org?v=2`,
      data,
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }

  async getAllUsers() {
    try {
      return (
        await axios.post(
          `${apiURL}/get_users_by_organization?token=app123eq123`,
          { email: localStorage.getItem("__email__id") }
        )
      ).data;
    } catch (error) { }
  }

  async fetchEmailByUserId(user_id) {
    const app_token = await this.appToken();
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/get-email-by-userid`,
      {
        user_id: user_id,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }

  async getConvByUrlAndUserid(url) {
    const app_token = await this.appToken();
    const chrome_props = (await feeds.getAllProps()).data;
    // console.log(chrome_props['__user_id'], "--PROPS")
    // console.log("Api Call", app_token, "--", url)
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/get_list_conversation_by_url`,
      {
        user_id: chrome_props["__user_id"],
        url: url,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async getConvByUrl(url) {
    const app_token = await this.appToken();
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/get_all_conversation_by_url`,
      {
        url: url,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async updateTicketstoDB() {
    const app_token = await this.appToken();
    const email = localStorage.getItem("__email__id");
    // const chrome_props = (await feeds.getAllProps()).data;
    // console.log(chrome_props, "--PROPS")
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/listall`,
      {
        email: email,
        // token: zendex_token,
        // domain: zendex_domain
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async getUserConfigs() {
    try {
      const email = localStorage.getItem("__email__id");
      return (
        await axios.post(`${apiURL}/configurator/get-user-configs`, {
          email: email,
        })
      ).data;
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAllData(query, name, page, accountFilter, openAccounts) {
    try {
      const email = localStorage.getItem("__email__id");
      //check if accountFilter has such properties for filtering feeds
      if (
        (accountFilter &&
          accountFilter["$and"] &&
          accountFilter["$and"].length != 0) ||
        (accountFilter && accountFilter.account_id) ||
        (accountFilter && accountFilter.id) || (accountFilter)
      ) {
        if (
          accountFilter &&
          accountFilter.account_id &&
          typeof accountFilter.account_id == "object"
        ) {
          accountFilter.account_id["$in"] = [
            ...new Set(accountFilter.account_id["$in"]),
          ];
        }

        return await axios.post(`${apiURL}/feeds/all`, {
          email: email,
          page_no: page,
          function: accountFilter,
          ...(openAccounts ? { view: "customer" } : {}),
          query: (query && Array.isArray(query) && query.length == 0) ? {} : query["$or"] ? query : { $or: query },
          // query && Array.isArray(query) && query.length == 0
          //   ? {}
          //   : query["$or"]
          //     ? query
          //     : { $or: query },
          config_Name: (name == "") ? 'My Accounts' : name,
        });
      }
      return await axios.post(`${apiURL}/feeds/all`, {
        email: email,
        page_no: page,
        query:
          query && Array.isArray(query) && query.length == 0
            ? {}
            : query["$or"]
              ? query
              : { $or: query },
        config_Name: name,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAllDataForOpen(name, page, accountFilter, accountId) {
    try {
      const email = localStorage.getItem("__email__id");
      //check if accountFilter has such properties for filtering feeds

      return await axios.post(`${apiURL}/feeds/all`, {
        email: email,
        page_no: page,
        query: {},
        function: {
          account_domain: accountFilter,
          business_object_name: name,
          account_id: accountId,
        },
        config_Name: "",
      });
    } catch (e) { }
  }

  async completeTasks(type, name, assignee, domain) {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(`${apiURL}/tasks/save`, {
        email: email,
        business_object_name: "tasks",
        task_type: type,
        task_name: name,
        assignee: assignee,
        account_domain: domain,
        status: "completed",
      })
    ).data;
  }

  async saveTasks(type, name, assignee, domain, desc, duedate, account_id) {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(`${apiURL}/tasks/save`, {
        email: email,
        business_object_name: "tasks",
        task_type: type,
        desc: desc,
        task_name: name,
        assignee: assignee,
        account_domain: domain,
        duedate: duedate,
        account_id: account_id,
      })
    ).data;
  }

  async editTasks(
    type,
    name,
    assignee,
    domain,
    oldTaskName,
    desc,
    duedate,
    account_id
  ) {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(`${apiURL}/tasks/save`, {
        email: email,
        business_object_name: "tasks",
        task_type: type,
        task_name: name,
        duedate: duedate,
        desc: desc,
        assignee: assignee,
        old_task_name: oldTaskName,
        account_domain: domain,
        account_id: account_id,
        method: "force",
      })
    ).data;
  }

  async fetchAllDomains() {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(`${apiURL}/summary/allowed-domains`, {
        email: email,
      })
    ).data;
  }
  async getHealth(email, list) {
    return (
      await axios.post(`${apiURL}/summary/allowed-domains`, {
        email: email,
        emails: list,
      })
    ).data;
  }

  async getSummaryIds(domain, email) {
    // const email = localStorage.getItem("__email__id");

    return (
      await axios.post(`${apiURL}/summary/find-account-id`, {
        domain: domain,
        email: email,
      })
    ).data;
  }
  async fetchTicketList(data, page_count) {
    const email = localStorage.getItem("__email__id");
    const app_token = await this.appToken();
    // const chrome_props = (await feeds.getAllProps()).data;
    // console.log(chrome_props['__user_id'], "--PROPS",email)
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/filterdata`,
      {
        email: email,
        pageNo: page_count,
        query: data,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async fetchConfiguration() {
    const email = localStorage.getItem("__email__id");
    const app_token = await this.appToken();
    // const chrome_props = (await feeds.getAllProps()).data;
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/by_saved_configuration`,
      {
        email: email,
        pageNo: 1,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async fetchCredentials() {
    const email = localStorage.getItem("__email__id");
    const app_token = await this.appToken();
    // const chrome_props = (await feeds.getAllProps()).data;
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/fetch_credentials_by_user_details`,
      {
        email: email,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }

  async getAccessToken(code) {
    try {
      // const params = new URLSearchParams();
      // params.append("grant_type", "authorization_code");
      // params.append("client_id", "6951dc5d-cab1-43b6-a76e-34a85d504058");
      // params.append("client_secret", "ad944f03-0a0f-49ae-a289-847851ed0f2e");
      // params.append("redirect_uri", "http://localhost:8080/integrations");
      // params.append("code", code);
      // await axios({
      //   method: "post",
      //   url: "https://api.hubapi.com/oauth/v1/token",
      //   data: bodyFormData,
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      // });
      return axios.post(`${apiUrl}/hubspot/getaccess`, {
        code: code,
      });
      // return (
      //   await axios.post(
      //     "https://api.hubapi.com/oauth/v1/token",
      //     {
      //       form: {
      //         grant_type: "authorization_code",
      //         client_id: "6951dc5d-cab1-43b6-a76e-34a85d504058",
      //         client_secret: "ad944f03-0a0f-49ae-a289-847851ed0f2e",
      //         redirect_uri: "http://localhost:8080/integrations",
      //         code: code,
      //       },
      //     },
      //     {
      //       headers: "content-type: application/x-www-form-urlencoded",
      //     }
      //   )
      // ).data;
    } catch (error) { }
  }

  async fetchSpreadsheetCredentials() {
    const email = localStorage.getItem("__email__id");
    const app_token = await this.appToken();
    // const chrome_props = (await feeds.getAllProps()).data;
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/sheet-credentials/all`,
      {
        email: email,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async fetchSummaryData(account_domain, id) {
    const email = localStorage.getItem("__email__id");
    // const chrome_props = (await feeds.getAllProps()).data;
    return (
      await axios.post(
        // eslint-disable-next-line no-undef
        `${apiURL}/summary/newjsonbyemail`,
        {
          email: email,
          account_domain: account_domain,
          id: id,
        }
      )
    ).data;
  }

  async saveRenewalOpps(data) {
    return (
      await axios.post(`${apiURL}/renewals/update-opportunity-probability`, data)
    ).data
  }

  async openTrendsData(domain, functionName, id) {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(
        `${apiURL}/summary/trends`,
        {
          email: email,
          account_domain: domain,
          functionName: functionName,
          id: id
        }
      )
    ).data;
  }
  async readPulseData(domain, id) {
    const my_email_id = localStorage.getItem("__email__id");
    return (
      await axios.post(`${apiURL}/summary/accounts-data`, {
        email: my_email_id,
        account_domain: domain,
        "function": "pulsesettings",
        "business_object_name": "pulse",
        id: id
      })
    ).data;
  }

  async fetchSummaryDataObj(domain, bobject, funcname, key) {
    const email = localStorage.getItem("__email__id");

    return await axios.post(`${apiURL}/summary/accounts-data`, {
      email: email,
      account_domain: domain,
      business_object_name: bobject,
      function: funcname,
      key: key,
    });
  }

  async fetchTicketHighLights(domain) {
    return await axios.post(`${apiURL}/get-sentiments`, {
      token: "peF2U6pQo8V88xnYskC8j4q6NNBjEEgDy0O4hxOG",
      email: "gaurav@appeq.ai",
      domain: "appeq",
      account_domain: domain,
    });
  }

  async fetchTicketDetails(id) {
    const app_token = await this.appToken();
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/details_by_ticket_id`,
      {
        ticket_id: id,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async fetchSpreadsheetDetails(
    email,
    sheet_url,
    sheetName,
    business_object_name,
    mapped_fields
  ) {
    const app_token = await this.appToken();
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/google-sheet/save`,
      {
        email: email,
        sheet_url: sheet_url,
        sheetName: sheetName,
        business_object_name: business_object_name,
        mapped_fields: mapped_fields,
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  async fetchCommentDetails(data) {
    const app_token = await this.appToken();
    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/zendesk/ticket/getcomments`,
      {
        ticket_id: data["ticket_id"],
        email: data["email"],
      },
      {
        headers: { "x-access-tokens": app_token },
      }
    );
  }
  //invite

  async sendInvitation(
    emails,
    invited_email_ids,
    topic,
    groupId,
    current_url,
    from_user_id,
    from_user_name,
    page_title
  ) {
    function displayName(name) {
      if (name.includes("$$$")) {
        name = name.split("$$$")[0];
        return name;
      }
      return name;
    }
    // current_url = current_url+"?__appeq__id="+groupId
    // current_url = current_url+"?__appeq__id="+groupId

    let invitedMail = [];
    invitedMail.push(emails);
    invitedMail.push(invited_email_ids);
    let display_name = displayName(from_user_name);
    // put these emails in localStorage for quick later access.
    // var invitedEmails = Storage.getInvitedEmails();
    // _.forEach(emails, (email) => {
    //   inv itedEmails[email] = invitedEmails[email] + 1 || 1;
    // });
    // Storage.setInvitedEmails(invitedEmails);
    // add users email id also inside

    return await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/invite`,
      {
        from_user_name: display_name,
        from_user_id: from_user_id,
        destination_url: current_url,
        my_email_id: invited_email_ids,
        group_id: groupId,
        invited_email_ids: invitedMail,
        conversation_topic: topic,
        title: page_title,
      },
      {
        headers: { "x-access-tokens": this.app_token },
      }
    );
  }
  async getCrunchbaseData(domain) {
    return await axios.post(`${apiURL}/clickhouse/get-logo-data`, {
      account_domain: domain,
    });
  }

  async updateAccountData(data) {
    const email = localStorage.getItem("__email__id");

    let query = { email: email, data };

    return (await axios.post(`${apiURL}/account/update`, query)).data;
  }

  async deleteConfig(name) {
    const email = localStorage.getItem("__email__id");
    return (
      await axios.post(`${apiURL}/configurator/delete-user-config`, {
        email,
        config_name: name,
      })
    ).data;
  }

  async getBusinessDataObs() {
    try {
      const my_email_id = localStorage.getItem("__email__id");
      const data = (
        await axios.post(`${apiURL}/sheet-credentials/all`, {
          email: my_email_id,
        })
      ).data;
      return data;
    } catch (error) {
      return error;
    }
  }

  async getUsersData(email_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiURL}/check-users`, {
          email_id: email_id,
        })
        .then((res) => resolve(res))
        .catch((error) =>
          reject("Oops an Error Occurred. Email us at support@appeq.ai")
        );
    });
  }

  async getNamesAccounts(query) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiURL}/fetch-name`, query)
        .then((res) => resolve(res))
        .catch((error) =>
          reject("Oops an Error Occurred. Email us at support@appeq.ai")
        );
    });
  }

  async getGenieInfo(account_domain, id) {
    try {
      const email_id = localStorage.getItem("__email__id");
      const data = (
        await axios.post(`${apiURL}/ticket-sentiments`, {
          email: email_id,
          account_domain: account_domain,
          id: id
        })
      ).data;
      return data;
    } catch (err) {
      return err;
    }
  }
  async getAllowedApps(email) {
    try {
      const data = await axios.post(
        `${apiURL}/success-pad/get-apps`,
        { email_id: email }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async saveDomainName(id, domain) {
    try {
      const data = await axios.post(
        `${apiURL}/save-org-domain`,
        {
          email: localStorage.getItem("__email__id"),
          id: id,
          domain: domain
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async triggerNotification(value, name, tasks, acc_id) {
    const my_email_id = localStorage.getItem("__email__id");
    const data = {
      message: value,
      name: value,
      tasks: tasks,
      email: my_email_id,
      account_id: acc_id
    };
    return (
      await axios.post(
        `https://api1.appeq.ai/notification/trigger-notification`,
        data
      )
    ).data;
  }
  async getCountOfTasks() {
    const my_email_id = localStorage.getItem("__email__id");
    return (await axios.post(
      // eslint-disable-next-line no-undef
      `${apiURL}/tasks/count`,
      { email: my_email_id }
    )).data;
  }
  async getSupportedApps() {
    const my_email_id = localStorage.getItem("__email__id");
    let orgName = my_email_id.split("@")[1];
    return await axios.get(
      // eslint-disable-next-line no-undef
      `${apiURL}/allowed-apps?org_name=${orgName}`,
      {}
    );
  }

  async getLatestVal(id, domain) {
    const data = await axios.post(
      `${apiURL}/audit/get-key-changed-latest`,
      {
        email: localStorage.getItem("__email__id"),
        id: id,
        domain: domain
      }
    );
    return data.data;
  }
}

export default new ApiServer();
