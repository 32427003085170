<template>
    <div style="height: 100%;padding: 10px;" class="card">
        <h2 style="text-align: center;margin-top: 10px;margin-bottom: 0px;">My Team</h2>
        <span style="text-align: center;font-size: 14px;display: flex;align-items: center;justify-content: center;margin-bottom: 20px;">Team members - You are the manager 
        </span>
        <div v-if="!loading" style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;">
            
            <div class="card" style="padding: 12px;">
                <h3>Accounts</h3>
                
                <div style="display: flex;;font-weight: 600;">

                    <span>Total Accounts : <span>{{totalCount}}</span></span>
                <span style="margin-left: 20px;">Total Revenue : <span>{{totalRev}}</span></span>

                </div>
            </div>
            <div class="card"  style="padding: 12px;margin-left: 25px;margin-right: 25px;">
                <h3>Renewals</h3>
                <div style="display: flex;font-weight: 600;">

                    <span>Total Renewals : <span>{{totalCount}}</span></span>
                    <span style="margin-left: 20px;">Total Renewal Revenue : <span>{{totalRenewalRev}}</span></span>

                </div>
            </div>
            <div class="card"  style="padding: 12px;">
                <h3>Tasks</h3>
                <div style="display: flex;;font-weight: 600;">

<span>Total Tasks : <span>{{totalTaskCount}}</span></span>
<span style="margin-left: 20px;">Total Open Tasks : <span>{{openTasks}}</span></span>

</div>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;">
                    <input v-model="searchField"  v-on:keyup.enter="searchChange"
                        style="border-radius: 20px;margin-right: 10px;border: none;border-bottom: 1px solid grey;"
                        placeholder="search accounts" v-if="openSearchBox" />
                    <span v-if="openSearchBox" @click="openSearchBox = false" style="cursor: pointer;margin-right: 10px;"
                        class="material-symbols-outlined">
                        close
                    </span>
                    <span v-if="!openSearchBox" @click="openSearchBox = true" style="cursor: pointer;margin-right: 10px;"
                        class="material-symbols-outlined">
                        search
                    </span>

                    <span @click="handleClickColumns()" style="cursor: pointer;" class="material-symbols-outlined">
                        settings
                    </span>
                </div>
        <ve-table :cell-style-option="cellStyleOption" v-if="!loading && managerData.length > 0" :columns="columns" :table-data="managerData"></ve-table>
    <Modal class="details_modal" v-if="openDetails" :show="openDetails">
        <i @click="openDetails=false" class="tim-icons close-icon icon-simple-remove"></i>
        
    <DetailsModel :user="selectedUser"/>
    </Modal>
    </div>
    
</template>

<script>
import Server from '../components/API/Server';
import VueEasytable from "vue-easytable"; // import library
import Modal from "../components/Modal.vue"
import DetailsModel from './DetailsModel.vue';

export default {
    data() {
        return {
            loading: false,
            openTasks:0,
            managerData: [],
            totalCount:0,
            selectedUser:'',
            openDetails:false,
            totalRenewal:0,
            totalRenewalRev:0,
            totalRev:0,
            totalTaskCount:0,
            cellStyleOption: {
                headerCellClass: ({ column, rowIndex }) => {
                    return "table-header-cell-class";
                },
            },
            columns: [
                {
                    field: "name",
                    key: "a",
                    title: "Name",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];
                        return (
                            <span v-tooltip={{ 'content': text }} class="text-bold" style="text-transform:capitalize">
                                {text.toString().substr(0, 25)}
                            </span>
                        );
                    },
                },
                {
                    field: "role",
                    key: "b",
                    title: "Role",
                    align: "left",

                },
                {
                    field: "accounts",
                    key: "b",
                    title: "Accounts",
                    align: "left",

                },
                {
                    field: "redAccounts",
                    key: "c",
                    title: "Red Accounts",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];
                        return (
                            <span v-tooltip={{ 'content': text }} class="text-bold" style="color:red;">
                                {text.toString().substr(0, 25)}
                            </span>
                        );
                    },


                },
                {
                    field: "revenue",
                    key: "d",
                    title: "Revenue",
                    align: "left",

                },
                {
                    field: "redrevenue",
                    key: "d",
                    title: "Red Revenue",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];
                        return (
                            <span v-tooltip={{ 'content': text }} class="text-bold" style="color:red;">
                                {text.toString().substr(0, 25)}
                            </span>
                        );
                    },

                },
                {
                    field: "tasks",
                    key: "d",
                    title: "Tasks",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];
                        return (
                            <span v-tooltip={{ 'content': text }} class="text-bold" >
                                {text.toString().substr(0, 25)}<span style={{color:'red',marginLeft:'4px'}}>({row.redTasks})</span>
                            </span>
                        );
                    },

                },
                {
                    field: "openTasks",
                    key: "d",
                    title: "Open Tasks",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];
                        return (
                            <span v-tooltip={{ 'content': text }} class="text-bold" >
                                {text.toString().substr(0, 25)}
                            </span>
                        );
                    },

                },
                {
                    field: "actions",
                    key: "d",
                    title: "Actions",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        return (
                            <div onClick={(e)=>{this.selectedUser=row.email;this.openDetails=true}} style={{color:"blue",cursor:'pointer',fontSize:'12px'}}  >
                              Details
                            </div>
                        );
                    },

                },
            ]
        }
    },
    components: {
    VueEasytable,
    Modal,
    DetailsModel
},
    mounted() {
        this.getInitData();
    },
    methods: {
        async getInitData() {
            try {
                this.loading = true
                const data = await Server.getAllManager();
                if (data.code == 200)
                    this.managerData = data.data
                else
                    this.$toasted.error("Something went wrong")
                    const currDate=new Date()
                this.managerData = this.managerData.map(data => {
                    {/* console.log(data.task_details.filter(d=>{console.log(d);if(d.duedate){const due=new Date(d.duedate); console.log(due);return due<=currDate}else{return false;}}).length) */}
                    return {
                        email:data.user.email_id,
                        name: data.user.email_id.split("@")[0],
                        accounts: data.account_details.length,
                        redAccounts: data.account_details.filter(d => d.health == "red").length,
                        renewal:data.account_details.reduce((partialSum, a) => partialSum + (a.arr ? parseInt(a.renewal) : 0), 0),
                        revenue: data.account_details.reduce((partialSum, a) => partialSum + (a.arr ? parseInt(a.arr) : 0), 0).toLocaleString(),
                        redrevenue: data.account_details.reduce((partialSum, a) => partialSum + (a.health == 'red' ? a.arr ? parseInt(a.arr) : 0 : 0), 0),
                        role:data.user.role,
                        tasks:data.task_details.length,
                        redTasks:data.task_details.filter(d=>{if(d.duedate){const due=new Date(d.duedate); console.log(due);return due<=currDate}else{return false;}}).length,
                        openTasks:data.task_details.filter(d=>d.status!='completed').length,
                          ...data,

                    }
                })
                this.totalCount = this.managerData.reduce((p, a) => (a.accounts + p), 0)
                this.totalRev = this.managerData.reduce((p, a) => (parseInt(a.revenue.replace(/,/g, '')) + p), 0).toLocaleString()
                this.totalTaskCount=this.managerData.reduce((p, a) => (a.tasks + p), 0)
                this.openTasks=this.managerData.reduce((p, a) => (a.openTasks + p), 0)
                this.totalRenewalRev=this.managerData.reduce((p, a) => (a.renewal?a.renewal:0 + p), 0)
                this.loading = false

            } catch (error) {
                console.log(error)
                this.$toasted.error("Something went wrong")

            }
        }
    }
}
</script>