<template>
    <div style="width: 100%;">
        <div v-if="showSumm" :style="{ 'min-width': (showMobile) ? '295px' : '' }" id="newSummaryDiv"
            class="summaryData" :class="this.health ? this.health : ''">
            <div class="ticket_info_headers">
                <div style="display: flex;align-items: center; justify-content: space-around;">
                    <span style="display: flex;align-items: center;">
                        <!-- <img class="logo" v-bind:src="getIcon(account_domain ? account_domain : '')" />
                    <span v-if="account_domain" class="ticket_header_name">{{ getFeedName(account_domain) }}</span> -->
                        <img class="logo" style="height: 21px; margin-bottom: -3px; margin-top: 0px;"
                            v-bind:src="getIcon(account_domain ? account_domain : '')" />
                        <div v-if="summaryIds.length != 0"
                            style="display: flex;align-items: center;margin-top: 0px;margin-left: 0px;" class="tabs"
                            :style="{ 'width': (showMobile) ? '113px' : '420px' }">
                            <div style="margin-left: 10px;text-transform: capitalize;padding: 8px 10px;cursor: pointer;"
                                @click="openSummary(sum.id, true, sum)"
                                :style="{ borderBottom: sum.id == selectedIds ? '2px solid blue' : '', fontWeight: sum.id == selectedIds ? '600' : '', color: sum.health }"
                                v-for="sum of summaryIds" class="tab" v-tooltip="sum.name"
                                data-microtip-position="right" role="tooltip">
                                {{ sum.name ? sum.name.substr(0, 15) : sum.domain.substr(0, 15) }}
                                {{ sum.name ? sum.name.length > 15 ? "..." : "" : sum.domain.length > 15 ? "..." : "" }}
                            </div>

                        </div>
                        <div :style="{ borderBottom: '2px solid blue', fontWeight: '600' }"
                            style="margin-left: 10px;text-transform: capitalize;padding: 8px 10px;cursor: pointer;"
                            v-if="summaryIds.length == 0">
                            {{ account_domain }}
                        </div>
                    </span>
                    <span>


                        <template v-if="loggedIn">
                            <div v-if="summaryIds.length == 0"
                                style="margin-left: 20px;font-weight: 700;cursor: pointer; display: none;"
                                @click="openAddCustomer" class="add-customer">
                                <span style="color: blue;cursor: pointer;">[+]</span> Add as Customer
                            </div>

                        </template>
                    </span>

                </div>
                <span class="rec"></span>
                <div class="conv_blk">
                    <img class="conv_icon" src="../icons/conv_list_icon.svg" alt="">
                </div>

                <div style="display: flex;align-items: center;">
                    <div v-if="notesEnabled"  v-tooltip="'My Notes'"  data-microtip-position="left" role="tooltip">
                        <img @click="openNotes" style="height: 20px;" src="../icons/sticky-note.png" />
                    </div>
                    <span v-if="notesEnabled" v-tooltip="'Public Notes'" data-microtip-position="right" role="tooltip">
                        <img @click="openPublicNotes"
                            style="height: 20px; filter: invert(32%) sepia(90%) saturate(1665%) hue-rotate(232deg) brightness(105%) contrast(95%);"
                            src="../icons/sticky-note.png" />
                    </span>
                    <div v-if="dashboard" v-tooltip="'Account Trends and Dashboard. Explore Now!'"
                        data-microtip-position="bottom-left" role="tooltip">
                        <img @click="openDash" style="height: 20px;" src="../icons/analytics.png" />
                    </div>
                    <!-- <div v-tooltip="'Executive dashboard'"   data-microtip-position="left" role="tooltip">
                        <img @click="openDash" style="height: 20px;filter: brightness(1) invert(1);" src="../icons/dashboard-ex.svg" />
                    </div> -->
                    <span v-if="summaryIds.length != 0 && slackEnabled"
                        v-tooltip="'Slack Channel for the Account. Check Now!'" data-microtip-position="bottom-left"
                        role="tooltip">
                        <img @click="handleOpenAccount" src="../icons/slack.png"
                            style="height: 18px;cursor: pointer;" />

                    </span>
                    <span v-if="genieEnabled" data-microtip-position="bottom-left" role="tooltip"
                        aria-label="Genie - your co-pilot. Explore Now!" @click="handleOpenLatest"
                        style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                        <img style="height: 30px;margin: 0px;" src="../icons/eqai.svg" />
                    </span>
                </div>
                <img width="35px" class="close" @click="closeSumm()" src="../icons/closePopup.svg" alt="">
            </div>

            <template v-if="!noDataFound">
                <div class="row" style="max-height: 33vh;padding-left: 15px;"
                   >
                    <div class="ticket_info_body"
                        :style="{ 'overflow': (!showMobile) ? 'hidden' : '', 'max-width': (showMobile) ? '100%' : '75%' }">
                        <div v-if="!loadingSum" style="display: flex;align-items: flex-start;font-size: 12px;"
                            v-for="(summ, idx) of summaryData">
                            <div v-if="(idx <= 5 && !expanded) || (idx <= 10 && expanded)" class="summary__data_obj"
                                :style="{ width: row.appeq_summary_column.appeq_summary_label == 'Image' ? '100%' : row.appeq_summary_column.appeq_summary_label == 'About' ? '100%' : '', alignItems: row.appeq_summary_column.appeq_summary_label == 'Image' ? 'center' : row.appeq_summary_column.appeq_summary_label == 'About' ? 'flex-start' : '' }"
                                style="display:flex; margin-top: 10px; margin-left: 10px;"
                                v-for="row of summ.appeq_summary_row">
                                <template v-if="row.appeq_summary_column.appeq_summary_label == 'Image'">
                                    <img @click="openLink('https://app.appeq.ai/')"
                                        style="height: 65px;margin-left: 0px;" src="../icons/image.png" />
                                </template>

                                <template
                                    v-else-if="row.appeq_summary_column.appeq_summary_label == 'health' || row.appeq_summary_column.appeq_summary_label == 'Health' || row.appeq_summary_column.appeq_summary_label == 'HEALTH'">

                                    <span style="font-style:italic;font-size:12px;color: #817f7f;"> {{ loadingSum ? '' :
            row.appeq_summary_column.appeq_summary_label + ' : ' }}</span>

                                    <span class="healthSpan" :class="this.health ? this.health : ''"
                                        style="font-weight: 700;">
                                        {{ loadingSum ? '' : row.appeq_summary_column.appeq_summary_value }}
                                    </span>

                                </template>

                                <template v-else>
                                    <span
                                        v-if="row.appeq_summary_column.appeq_summary_type != 'graph' && row.appeq_summary_column.appeq_summary_type != 'bargraph'"
                                        style="font-style:italic;font-size:12px;color: #817f7f;"> {{ loadingSum ? '' :
            row.appeq_summary_column.appeq_summary_label + ' : ' }}&nbsp;</span>
                                    <span 
                                       v-tooltip="convertToDate(row.appeq_summary_column.appeq_summary_value, row.appeq_summary_column)"
                                        style="margin-left: 4px;font-weight: 600;"
                                        v-if="row.appeq_summary_column.appeq_summary_type != 'graph' && row.appeq_summary_column.appeq_summary_type != 'bargraph' && row.appeq_summary_column.appeq_summary_type != 'requesters' && row.appeq_summary_column.appeq_summary_type != 'link' && row.appeq_summary_column.appeq_summary_type != 'lastTicket' && row.appeq_summary_column.appeq_summary_type != 'crunchbase' && !isURL(row.appeq_summary_column.appeq_summary_value)"
                                        v-bind:innerHTML="loadingSum ? '' : convertToDate(row.appeq_summary_column.appeq_summary_value, row.appeq_summary_column)">
                                        {{convertToDate(row.appeq_summary_column.appeq_summary_value, row.appeq_summary_column)}}
                                    </span>
                                    <span style="margin-left: 4px;font-weight: 600;cursor: pointer;"
                                        @click="openLink('https://appeq.ai/what-is-net-promoter-score-and-how-it-is-calculated/')"
                                        v-if="row.appeq_summary_column.appeq_summary_type != 'graph' && row.appeq_summary_column.appeq_summary_type != 'requesters' && row.appeq_summary_column.appeq_summary_type != 'lastTicket' && row.appeq_summary_column.appeq_summary_type != 'crunchbase' && row.appeq_summary_column.appeq_summary_type == 'link'">
                                        {{ row.appeq_summary_column.appeq_summary_value }}
                                    </span>
                                    <span style="margin-left: 4px;font-weight: 600;color: blue;cursor: pointer;"
                                        @click="openLink(row.appeq_summary_column.appeq_summary_value)"
                                        v-if="row.appeq_summary_column.appeq_summary_type != 'graph' && row.appeq_summary_column.appeq_summary_type != 'requesters' && row.appeq_summary_column.appeq_summary_type != 'lastTicket' && row.appeq_summary_column.appeq_summary_type != 'crunchbase' && isURL(row.appeq_summary_column.appeq_summary_value)">Link
                                    </span>
                                    <div @click="openLink(row.appeq_summary_column.appeq_summary_value)"
                                        style="font-size: 12px;color: blue;cursor: pointer;"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'crunchbase' && row.appeq_summary_column.appeq_summary_label == 'Org Linkedin'">
                                        Link
                                    </div>
                                    <div @click="openLink(row.appeq_summary_column.appeq_summary_value)"
                                        style="font-size: 12px;cursor: pointer;"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'crunchbase' && row.appeq_summary_column.appeq_summary_label == 'Region'">
                                        {{ row.appeq_summary_column.appeq_summary_value }}
                                    </div>
                                    <div @click="openLink(row.appeq_summary_column.appeq_summary_value)"
                                        style="font-size: 12px;cursor: pointer;color: blue;cursor: pointer;"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'crunchbase' && row.appeq_summary_column.appeq_summary_label == 'Website'">
                                        Link
                                    </div>
                                    <div style="font-size: 12px;cursor: pointer;width: 90%;display: flex;"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'crunchbase' && row.appeq_summary_column.appeq_summary_label == 'About'">
                                        <div style="width: 70%;" :style="{ 'width': (showMobile) ? '100%' : '70%' }">
                                            {{ row.appeq_summary_column.appeq_summary_value }}

                                        </div>

                                    </div>
                                    <!-- <img @click="handleRedirect(row.appeq_summary_column.query, row.appeq_summary_column.appeq_summary_bObj)"
                                        src="../icons/redirect.png"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'feedsLink'" /> -->

                                </template>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: end; margin-right: 10px;">
                            <span v-if="!expanded && summaryIds.length != 0"
                                style="display: flex; align-items: center; justify-content: center;"
                                aria-label="Show More" data-microtip-position="left" role="tooltip">
                                <!-- <img
                                    @click="expanded = true;" src="../icons/expand.png" style="height: 10px;"
                                    alt=""> -->
                                </span>
                            <span v-else-if="expanded && summaryIds.length != 0"
                                style="display: flex; align-items: center; justify-content: center;"
                                aria-label="Show Less" data-microtip-position="left" role="tooltip">
                                <!-- <img
                                    @click="expanded = false; loadingTrends = true; showTrends(); openSummary();"
                                    src="../icons/seeless.jpeg" style="height: 10px;" alt=""> -->
                                
                                </span>
                        </div>
                        
                        <div style="display:flex;align-items:center;justify-content: center;" v-if="this.loadingSum">
                            <!-- <img style="height: 100px;width: 100px;" src="../icons/loadingripple.svg" /> -->
                        </div>
                    </div>
                    <div class="ticket_info_signals"
                        style="padding-left: 10px; height: 60vh; display: flex; flex-direction: column; justify-content: space-between; margin-left: 12px;margin-top:15px;"
                        :style="{ 'border-left': (!showMobile) ? ' 2px solid rgb(168 176 253 0.7)' : 'none', 'height': (showMobile) ? '25vh' : '60vh' }">
                        <div>
                            <h2 style="margin: 5px 0px; color: #636363; font-size: 12px;">Signals</h2>
                            <div v-if="summaryIds.length != 0" class="row"
                                style="align-items: center; justify-content: space-between;">
                                <span v-tooltip="reason" data-microtip-position="right" role="tooltip"
                                    @click="openHealthAudit" v-if="account_domain" class="healthSpan"
                                    style="text-transform: uppercase;"
                                    :class="selectedDom.health == 'red' ? selectedDom.health : ''"
                                    :style="{ 'color': (selectedDom.health == 'Green') ? 'green' : '', 'padding-left': (selectedDom.health == 'Green') ? '0px' : '' }">
                                    {{ selectedDom.health }}
                                </span>
                                <span v-if="account_domain && selectedDom.health == 'red'" @click="openHealthRed()"
                                    class="ticket_header_subname" style="font-size: 10px;">{{ 'Why?' }}</span>
                                <span style="width: 65px;font-size: 10px; color: grey;">{{ reasonTime }}</span>
                                <span aria-label="Important account Insights. Keep an eye on this!"
                                    data-microtip-position="left" role="tooltip">
                                    <!-- <img src="../icons/building.png"
                                        style="height: 18px;" alt="">
                                     -->
                                    </span>
                            </div>
                            <div v-else>

                                <span style="display: flex; align-items: center; justify-content: center;"
                                    aria-label="Not a customer account" data-microtip-position="bottom" role="tooltip">
                                    <img src="../icons/noData.svg" style="height: 125px;" alt="">
                                </span>
                            </div>

                            <span v-if="summaryIds.length != 0"
                                style="display: flex; align-items: center; justify-content: space-between;">
                                <!-- {{ pulseData }} -->
                                <span
                                    style="width: 60px;  height: 20px; padding: 3px 5px; display: flex; align-items: center;"
                                    :style="{ 'display': (pulseData.length == 0) ? 'none' : 'flex' }">
                                    <span data-html="true"
                                      v-tooltip="data.reason + '\r\n' + (data.description != undefined ? data.description.substr(0, 30) + '...' : '')"
                                        data-microtip-position="bottom" role="tooltip"
                                        :style="{ background: data.health, width: '8px', height: '20px', display: 'block', marginLeft: (idx == 0) ? '0px' : '10px', cursor: 'pointer' }"
                                        v-for="data, idx in pulseData" @click="redirectToPulse(data)"></span>

                                </span>
                                <span @click="redirectToPulse('')"
                                    style="color: blue; width: 100px; font-weight: 600;font-size: 10px !important;cursor: pointer;"
                                    v-if="pulseData.length > 0">ACCOUNT PULSE</span>
                                <span @click="redirectToPulse(data)"
                                    style="color: blue; width: 120px; font-weight: 600; font-size: 10px; cursor: pointer;"
                                    v-else-if="pulseData.length == 0">SET ACCOUNT PULSE</span>
                                <span style="width: 65px; font-size: 10px; color: grey;" v-if="pulseData.length > 0">{{
            convertToDaysAgo(pulseData[pulseData.length - 1].created_date) }}</span>
                                <span aria-label="Important account Insights. Keep an eye on this!"
                                    data-microtip-position="left" role="tooltip">
                                    <img src="../icons/building.png"
                                        style="height: 18px;" alt="">
                                    </span>
                            </span>
                        </div>
                        
                    </div>
                </div>

                <div class="trends">
                    <iframe v-if="metabaseUrl" :src=metabaseUrl style="height: 225px;border: none;" />
                </div>
                <div class="ticket_info_footer">
                    <!-- <div class="loadingHigh">
                        <img class="loadingHighLights" src="../icons/highLights.svg" />
                        <span>Our Genie is generating highlights</span>
                    </div> -->
                    <!-- <span v-if="showHighLights">HighLights</span> -->
                    <span v-if="highLights && loggedIn" id="highLights">{{ highLights.substring(0, 200) }}<span
                            @click="handleOpenLatest" style="color: blue;cursor: pointer;margin-left: 4px;">Read
                            More</span></span>
                    <br />
                    <span v-if="lastUpdatedAtHighLights" style="font-size: 9px;color: #919191;">Generated On :
                        {{ lastUpdatedAtHighLights }}</span>
                </div>
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <!-- <span style="font-size: 8px;font-weight: 300;">Data from {{ appeq_supported_application }}</span> -->
                    <div id="changeDeviceSize" style="display: flex;">
                        <span aria-label="Minimize to phone size" data-microtip-position="top-right" role="tooltip">
                            <!-- <img src="../../images/phone.png" style="height: 22px; cursor: pointer;"
                                @click="changeToPhoneSize()" alt=""> -->
                        </span>
                        <span aria-label="Maximize to PC size" data-microtip-position="top-right" role="tooltip">
                            <!-- <img src="../../images/pc.png" style="height: 22px; cursor: pointer;"
                                @click="changeToPcSize()" alt=""> -->
                        </span>
                    </div>
                    <span style="font-size: 10px;font-weight: 300;margin-left: 25%;display: flex;align-items: center;"
                        :style="{ 'margin-left': (showMobile) ? '13%' : '25%' }">
                        <img @click="openLink('https://chromewebstore.google.com/detail/appeqaiin-app-customer-in/likcmamamahkjeneloakgacokbgdkfea')"
                            style="height: 10px;margin-right: 4px;cursor: pointer;filter: invert(27%) sepia(90%) saturate(6734%) hue-rotate(355deg) brightness(93%) contrast(120%);"
                            src="../icons/share.svg" />
                        Powered By <a style="margin-left: 4px;" target="_blank"
                            href="https://appeq.ai/">AppEQ.ai</a></span>
                </div>
            </template>

            <template v-else>
                <div class="no_data_div">
                    <!-- <img style="height:130px;display: block;margin: auto;" src="../icons/noData.svg" /> -->
                    <span>User Not Logged in</span>
                    <span>Please Login Now to see the summary </span>
                </div>
            </template>
        </div>
        <!-- <div v-if="!showSumm && !showSummModal && !showUserModal">
            <AddCustomer :account_domain="account_domain" />
        </div> -->

        <div v-if="showSummModal && !showUserModal">
            <InviteTeamModal />
        </div>
        <div v-if="showUserModal">
            <ReferWinModal />
        </div>
        <div v-if="showCustomerLinkModal"
            style="height: 74%; width: 98%; position: fixed; top: 7px; z-index: 1111; background-color: rgba(0, 0, 0, 0.2); border-radius: 15px;">
            <div v-if="showCustomerLinkModal"
                style="position: fixed; left: 22%; top: 14%; background-color: white; border: 0.2px solid grey; width: 50%; height: 48vh; border-radius: 20px; z-index: 9999999999; 
    padding: 5px 12px; box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; ">
                <div style="display: flex; align-items: center; justify-content: end;">
                    <!-- <img src="../icons/Close.svg"
                        style="height: 18px; cursor: pointer;" @click="showCustomerLinkModal=false;" alt=""></div> -->
                <div>
                    <CustomerLinkModal :account_domain="account_domain" @close="handleCloseCustomerLinkModal(e)" />
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import ApiServer from "./ApiServer";
import { JWT } from "node-jsonwebtoken";
import CryptoJS from 'crypto-js';
// import AddCustomer from "./AddCustomer.vue"
import moment from "moment";
import { createChart } from 'lightweight-charts';
import Multiselect from "vue-multiselect"
import LocalUtils from "../components/Utils/LocalUtils";
// import CustomerLinkModal from "../components/CustomerLinkModal.vue";

export default {

    name: "Summary",
    data() {
        return {
            metabaseUrl:"",
            appeq_supported_application: '',
            account_domain: "",
            reason: "",
            reasonTime: "",
            trendsValue: false,
            metabaseNumber: 141,
            loadingTrends: false,
            barData: {},
            expanded: false,
            genieEnabled: true,
            dashboard: false,
            summaryData: [],
            org_id: "",
            i: 0,
            summaryIds: [],
            pulseData: [],
            chartDi: null,
            searchStr: "",
            barDi: null,
            lineData: {},
            demo: false,
            health: "RED",
            notesEnabled: true,
            slackEnabled: true,
            showMobile: false,
            showCustomerLinkModal: false,
            showSumm: true,
            lastUpdatedAtHighLights: '',
            showHighBtn: false,
            noDataFound: false,
            inputNoCustomer: '',
            trends: [],
            showMoreOptions: false,
            showMoreOptionsArray: [],
            showHighLights: false,
            disableHightLighBtn: false,
            highLights: "",
            loadingSum: false,
            selectedIds: '',
            selectedDom: {},
            loadingHighLights: false,
            showSummModal: false,
            showUserModal: false,
            viewModalOrCustomer: true,
            loggedIn: false,
            loadingHighLights: false
        }
    },
    components: {
        Multiselect,
    },

    created() {
        // window.addEventListener("message", async e => {
        //     console.log(e)
        //     switch (e.data.action) {
        //         case "__appeq__domain":
        //             if (e.data.fromFeeds) {
        //                 AppEQAnalytics.actionCall("SummaryViewed", "Inbox", { account: this.account_domain })

        //                 let email = localStorage.getItem('__email__id');
        //                 this.account_domain = e.data.value
        //                 this.health = 'RED'
        //                 ApiServer.getHealth(email, [e.data.value]).then(data => {
        //                     console.log(data, "HERE1")
        //                     this.health = data.data[0].health

        //                 });

        //             } else {
        //                 AppEQAnalytics.actionCall("SummaryViewed", "Inbox", { account_domain: this.account_domain })

        //                 this.account_domain = e.data.value.domain
        //                 this.health = e.data.value.health
        //                 if (e.data.demo) {
        //                     this.demo = true;
        //                 }
        //             }
        //             // this.showHighLights = true
        //             // const dt = await ApiServer.getAllQuestions(this.account_domain, 0)
        //             // console.log(dt)
        //             // if (dt.data && dt.data.length != 0) {
        //             //     this.highLights = dt.data[0]['answer'].replace(/[/*\\]/g, '')
        //             //     console.log(this.highLights,"---answer")
        //             // }
        //             // this.lastUpdatedAtHighLights = moment(e.data.value.lastUpdatedAt).format("DD MMM, YYYY")

        //             console.log("HERE2")
        //             await this.openSummary();
        //             break;

        //         case "__appeq__add_popup":
        //             this.showSumm = false;

        //             this.account_domain = e.data.value
        //             this.viewModalOrCustomer = false;
        //             break;
        //         case "__appeq__add_modal_popup":
        //             this.showSummModal = true;
        //             this.showSumm = false;
        //             if (e.data.value) {
        //                 console.log(e.data.value);
        //                 this.showUserModal = true;
        //             }
        //             this.viewModalOrCustomer = false;
        //             break;
        //     }

        // })
    },
    async mounted() {
        Intercom('update', {
  "hide_default_launcher": true
});
        console.log("Summary has started")
        const params=this.getParamsByKey()
        console.log(params)
        this.email=params['email']
        this.account_domain=params['domain']
        console.log("Summary has started2")
        await this.openSummary();
    await this.checkDash();
                         

        // this.loggedIn = localStorage.getItem("__email__id") ? true : false;
        this.loadingTrends = true;

    },
    methods: {
        async checkDash(){
            let data = await ApiServer.getSupportedApps();
            let features=data.data.configs.features
            features.map(async data => {
                if (data && data.feature_name == "appeqTrendsAndDashboard" && data.enabled == true) {
                                    this.dashboard = true;
                                    this.metabaseNumber = data.dashboardNum
                                   this.metabaseUrl= await this.opendashforAccounts()
                                }
            })
        },
         getParamsByKey() {
    const queryString = window.location.search.slice(1); // Remove the leading '?'
    const params = new URLSearchParams(queryString);

    const filteredParams = {};
    params.forEach((key,val) => {
        console.log(key,val)
            filteredParams[val] = key;
    });

    return filteredParams;
},

        openHealthAudit() {
            window.parent.postMessage({action:"__appeq_open_account_audits", data:{ account_domain: this.account_domain, id: this.selectedIds, type: "health" }},"*");
        },
        selectCustomer(d) {
            this.summaryIds = [d]
            this.selectedIds = d.id
            this.openSummary()
        },
        handleCloseCustomerLinkModal(e) {
            this.showCustomerLinkModal = false;
            this.openSummary()
        },
        async fetchNames() {
            if (this.inputNoCustomer) {
                const allowedNames = (await ApiServer.getNamesAccounts({ email: localStorage.getItem("__email__id"), search: this.inputNoCustomer })).data
                this.showMoreOptionsArray = allowedNames.data
                return
            }
            const allowedNames = (await ApiServer.getNamesAccounts({ email: localStorage.getItem("__email__id"), search: this.account_domain.slice(0, 3) })).data
            this.showMoreOptionsArray = allowedNames.data
        },
        async opendashforAccounts(){
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            console.log("params")
            console.log(this.accountid)
            console.log(this.account_domain)
            console.log(this.metabaseNumber)
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard: parseInt(this.metabaseNumber) },
                params: {
                    domain: this.account_domain,
                    id:this.selectedIds
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            // AppEQAnalytics.actionCall("DashboardOpened","Dashboard",{
            //     dashboard_num:this.metabaseNumber
            // })    
            return iframeUrl
            // feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);

        },
        async openDash() {
            const userInfo = (await ApiServer.getUsersData(localStorage.getItem("__email__id"))).data
            this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            let email = localStorage.getItem('__email__id')

            console.log(this.account_domain + "gauravyesy")
            const token = await jwt.sign({
                resource: { dashboard: this.metabaseNumber },
                params: {
                    domain: this.account_domain,
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";

            window.parent.postMessage({action:"__appeq_open_executive_dashboard",data:iframeUrl},"*");
            // window.parent.postMessage({action:"__appeq_open_account_audits", data:{ account_domain: this.account_domain, id: this.selectedIds, type: "health" }});
            //

        },
        showTrends() {
            console.log(this.chartDi);
        },
        convertToDaysAgo(dateString) {
            const date = new Date(dateString);
            const currentDate = new Date();
            const timeDifference = currentDate - date;
            const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            return daysAgo + " days ago";
        },
        changeToPhoneSize() {
            this.showMobile = !this.showMobile;
            feeds.sendActionToTop("__appeq_minimize_summary", { state: this.showMobile });
        },
        changeToPcSize() {
            this.showMobile = false;
            feeds.sendActionToTop("__appeq_minimize_summary", { state: this.showMobile });
        },
        openTrends() {
            window.open("https://app.appeq.ai/summary-creator", "_blank");
        },
        openEditSummary() {
            const cred = {
                "__email__id": localStorage.getItem("__email__id")
            };
            const res = JSON.stringify(cred);
            const encryptedText = CryptoJS.AES.encrypt(res, "DYyAKzBo70ca9aCSLNgwzLutBhkw0EXs6Iqj7jI8").toString()
            console.log("val (sender):", encryptedText);
            const encodedToken = encodeURIComponent(encryptedText);
            let url = 'https://app.appeq.ai/summary-creator' + '/' + '?value=' + encodedToken;
            console.log(url);
            feeds.sendActionToTop("__appeq_open_summary_iframe", { 'url': url });
        },
        sendActionToTop(action,data){
            window.parent.postMessage({action:action,data:data},"*")
        },
        openNotes() {
            this.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.selectedIds, state: 'private' })
        },
        openPublicNotes() {
            this.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.selectedIds, state: 'public' })
        },
        openAddCustomer() {
            this.sendActionToTop("__appeq_open_popup_iframe_summary", { account_domain: this.account_domain });

        },
        openAudits() {
            this.sendActionToTop("__appeq_open_account_audits", { account_domain: this.account_domain, id: this.account_id });

        },
        redirectToPulse(data) {
            let final = { "account_domain": this.account_domain, "account_id": this.selectedIds }
            console.log(final);
            this.sendActionToTop("__appeq_open_ticket_iframe_pulse", final);
        },
        async openPulseInfo() {
            // let ids = await ApiServer.getSummaryIds(this.account_domain);
            if (this.selectedIds) {
                let id_ = this.selectedIds;
                let adminConfigs = localStorage.getItem('adminConfigs');
                if (adminConfigs) {
                    adminConfigs = JSON.parse(adminConfigs);
                    if (adminConfigs.business_object_name && adminConfigs.type && adminConfigs.type == 'pulse') {
                        let data = await ApiServer.fetchAllDataForOpen(adminConfigs.business_object_name, 0, this.account_domain, id_);
                        console.log(data);
                        if (data && data.data && data.data.data && data.data.data.data) {
                            this.pulseData = data.data.data.data;
                        }
                    } else {
                        let data = await ApiServer.readPulseData(this.account_domain, id_);
                        console.log(data);
                        if (data.data && Array.isArray(data.data) && data.data.length > 3) {
                            data.data = data.data.slice(0, 3)
                        }
                        this.pulseData = data.data;
                    }
                } else {
                    let data = await ApiServer.readPulseData(this.account_domain, id_);
                    console.log(data);
                    if (data.data && Array.isArray(data.data) && data.data.length > 3) {
                        data.data = data.data.slice(0, 3)
                    }
                    this.pulseData = data.data;
                }
            }
        },
        async changeTab(id) {
            this.loadingSum = true
            const crunchbaseData = {}
            console.log(id);
            console.log(this.selectedIds);
            // const crunchbaseData = (await ApiServer.getCrunchbaseData(this.account_domain)).data
            this.selectedIds = id
            const email = this.email;
            let data = (await ApiServer.fetchSummaryData(this.account_domain, this.selectedIds))
            console.log(data);

            if (data && data.length == 0) {
                this.noDataFound = true;
                this.loadingSum = false;
                return;
            }


            console.log(data)
            if (data.data && data.data[0].ai) {
                this.showHighBtn = true;
            }

            // check for members for which summary to be shown
            if (data && data.data && data.data[0].data)
                this.summaryData = data.data[0].data
            else {
                this.noDataFound = true;
                this.loadingSum = false;
                return;
            }

            // this.summaryData = data.data.data[0].data
            await Promise.all(this.summaryData.map(async res => {

                if (res.appeq_summary_row) {
                    await Promise.all(res.appeq_summary_row.map(async d => {

                        if (d.appeq_summary_column.appeq_summary_value) {
                            let val = d.appeq_summary_column.appeq_summary_value
                            let funcName = val.toString().substr(0, val.toString().indexOf("("));
                            val = val.toString().substr(val.toString().indexOf("("));

                            // d.appeq_summary_column.appeq_summary_bObj = val.split(",")[0].replace("(", "")
                            if (d.appeq_summary_column.appeq_summary_bObj == 'Crunchbase Data') {
                                console.log(val.split(",")[1].replace(")", "").includes('linkedin'), "gaurav")
                                if (val.split(",")[1].replace(")", "").includes('linkedin')) {
                                    if (crunchbaseData.entities[0].properties && crunchbaseData.entities[0].properties.linkedin && crunchbaseData.entities[0].properties.linkedin.value)
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.linkedin.value
                                    else
                                        d.appeq_summary_column.appeq_summary_value = "No Data"

                                } else if (val.split(",")[1].replace(")", "").includes('website_url')) {
                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.homepage_url

                                } else if (val.split(",")[1].replace(")", "").includes('short_description')) {
                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.short_description

                                } else {
                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.location_identifiers.map(o => o.value).join(",")

                                }
                                return;
                            }
                            // d.appeq_summary_column.appeq_summary_value = (await ApiServer.fetchSummaryDataObj(this.account_domain, val.split(",")[0].replace("(", ""), funcName, val.split(",")[1].replace(")", ""))).data
                            if (d.appeq_summary_column.appeq_summary_value.error) {
                                d.appeq_summary_column.appeq_summary_value = 'No Data'
                                d.appeq_summary_column.appeq_summary_type = ''
                                return
                            }
                            d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.data
                            if (d.appeq_summary_column.appeq_summary_type == 'graph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                this.lineData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data.date)
                                this.lineData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.tickets) }]
                                const ticketsData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                    return { time: data.date.toString(), value: data.tickets }
                                })
                                this.lineData['data'] = ticketsData
                                return;
                            }
                            if (d.appeq_summary_column.appeq_summary_type == 'lastTicket' && d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at']) {
                                let date1 = new Date()
                                var x = new moment(d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at'] ? d.appeq_summary_column.appeq_summary_value['created_at'] : null)
                                var y = new moment(date1)
                                var duration = moment.duration(x.diff(y))
                                let duration_days = Math.abs(Math.floor(duration.asDays()))
                                d.appeq_summary_column.appeq_summary_prevalue = duration_days + ' days ago by '
                                d.appeq_summary_column.query = { 'id': d.appeq_summary_column.appeq_summary_value['id'] }
                                d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value['requester_email']
                                d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value['requester_email'].toString().split("@")[0][0]
                            }
                            if (d.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value
                                d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.map(data => data.toString().split("@")[0].split(".").map(d => d[0]).join(""))
                            }
                            d.appeq_summary_column.appeq_summary_value = typeof d.appeq_summary_column.appeq_summary_value == "object" && d.appeq_summary_column.appeq_summary_type != "requesters" ? "_" : d.appeq_summary_column.appeq_summary_value;
                            if (d.appeq_summary_column.appeq_summary_label == 'Health') {
                                d.appeq_summary_column.appeq_summary_value = this.health;
                                console.log("health of it is", this.health);
                            }
                        }

                    })
                    )
                }
            }))
            this.loadingSum = false;

            await new Promise((res) => setTimeout(res, 200))
            if (this.lineData['data']) {
                const chart = createChart(document.getElementById("chartDiv"), {
                    width: 120, height: 80, grid: {
                        vertLines: {
                            color: "transparent",
                            visible: false
                        },
                        horzLines: {
                            color: "transparent"
                        },

                    },
                    leftPriceScale: {
                        visible: true,
                    },

                    timeScale: {
                        barSpacing: 9,
                    },

                });
                chart.priceScale('left').applyOptions({
                    autoScale: true,
                    visible: true,
                });
                chart.priceScale('right').applyOptions({
                    autoScale: true,
                    visible: false
                });
                const lineSeries = chart.addLineSeries();
                lineSeries.applyOptions({

                    lineType: 2, lineStyle: 0,
                    priceScale: {
                        position: 'left',

                    },


                })
                lineSeries.setData(this.lineData['data']);
                this.chartDi = chart
                if (this.highLights) {
                    this.disableHightLighBtn = true;
                    try {
                        let box = document.querySelector('#newSummaryDiv');
                        let widthh = box.offsetWidth - 10;
                        console.log(widthh);

                        var element = document.getElementById("highLights");
                        console.log(element);

                        element.setAttribute("style", `display:block;width:${widthh}px`);
                    } catch (e) {
                        console.log(e);
                    }
                    // await this.typeWriter(this.highLights)
                    this.disableHightLighBtn = false;
                }
            }
        },
        isURL(text) {
            const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
            return urlPattern.test(text);
        }
        ,
        async handleClick() {

            try {
                let box = document.querySelector('#newSummaryDiv');
                let widthh = box.offsetWidth - 10;
                console.log(widthh);

                var element = document.getElementById("highLights");
                console.log(element);

                element.setAttribute("style", `display:block;width:${widthh}px`);
            } catch (e) {
                console.log(e);
            }

            this.disableHightLighBtn = true;
            this.loadingHighLights = true;
            this.showHighLights = true;
            document.getElementById("highLights").innerHTML = '';
            this.i = 0;
            const dt = await ApiServer.getAllQuestions(this.account_domain, 0)
            if (dt.data && dt.data.length != 0) {
                this.highLights = dt.data[0]['answer'].replace(/[/*\\]/g, '')
                this.loadingHighLights = false;
                // await this.typeWriter(this.highLights)
            }
            this.lastUpdatedAtHighLights = moment(Date.now()).format("DD MMM,YYYY")
            this.disableHightLighBtn = false;


        },

        convertTo6x2(originalData) {
            const newData = [];
            let arrToadd = []
            for (let i = 0; i < originalData.length; i++) {
                const row = originalData[i].appeq_summary_row;
                const newRow1 = row.slice(0, 2);
                arrToadd.push(...row.slice(2, 4));
                if (arrToadd.length == 2) {
                    newData.push({ appeq_summary_row: arrToadd });
                    arrToadd = []
                }
                newData.push({ appeq_summary_row: newRow1 });
                // newData.push({ appeq_summary_row: newRow2 });
            }

            return newData;
        },
        async typeWriter() {
            if (this.i < this.highLights.length) {
                document.getElementById("highLights").innerHTML += this.highLights.charAt(this.i);
                this.i++;
                const wait = (ms) => new Promise((res) => setTimeout(res, ms))
                await wait(20);
                return await this.typeWriter()
            }

        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        openCompose(index, data) {
            window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${data.appeq_emails}&tf=1`)
        },
        openLink(link) {
            console.log(link)
            window.open(link, "_blank")

        },
        convertToDate(value, column) {
            if (typeof value === "string" && moment(value, moment.ISO_8601, true).isValid() && isNaN(value)) {
                let temp = new Date(value);
                var day = temp.getUTCDate();
                var month = temp.toLocaleString('default', { month: 'short' });
                var year = temp.getUTCFullYear();
                var formattedDateString = day + ' ' + month + ' ' + year;
                return formattedDateString;
            }
            console.log(value)
            return value ? value.toString().substr(0, 18) : "NA";
        },
        async openSummary(ID, mode, selectedSum) {
        console.log("Summary has started3")
            
            if (this.account_domain) {
                try {

                    localStorage.setItem("__email__id",this.email);
                    let res = await ApiServer.getSupportedApps();
                    localStorage.setItem("features",JSON.stringify(res.data.configs.features))
                    let features = localStorage.getItem("features")
                    if (features && JSON.parse(features)) {
                        features = JSON.parse(features)
                        features.map(data => {
                            if (data && data.feature_name == "appeqSlack" && data.enabled == false) {
                                this.slackEnabled = false
                            }
                            if (data && data.feature_name == "appeqNotes" && data.enabled == false) {
                                this.notesEnabled = false
                            }
                            if (data && data.feature_name == "appeqGenie" && data.enabled == false) {
                                this.genieEnabled = false
                            }
                            if (data && data.feature_name == "appeqTrendsAndDashboard" && data.enabled == true) {
                                this.dashboard = true;
                                this.metabaseNumber = data.dashboardNum
                            }
                        })
                    }
                    this.loadingSum = true;

                    const crunchbaseData = (await ApiServer.getCrunchbaseData(this.account_domain)).data
                    if (crunchbaseData.code == 500) {
                        console.log('code 500')
                        appeqAnalytics.actionCall("ThirdPartyDataNotFound", "Summary", { accountDomain: this.account_domain });
                    }
                    if (crunchbaseData && crunchbaseData.data)
                        this.appeq_supported_application = crunchbaseData.data.appeq_supported_application
                    if (this.summaryIds.length == 0) {
                        const ids = await ApiServer.getSummaryIds(this.account_domain,this.email)
                        console.log(ids,"1234")
                        console.log("!23")
                        if (ids.code == 200) {
                            if (Array.isArray(ids.data)) {
                                if (ids.data.length == 0) {
                                    this.summaryData = [
                                        {
                                            appeq_summary_row: [
                                                {
                                                    appeq_summary_column: {
                                                        appeq_summary_label: "Org Linkedin",
                                                        appeq_summary_value: "No Data",
                                                        appeq_summary_type: "crunchbase",
                                                        query: "{}",
                                                    },
                                                },
                                                {
                                                    appeq_summary_column: {
                                                        appeq_summary_label: "Website",
                                                        appeq_summary_value:
                                                            "No Data",
                                                        appeq_summary_type: "crunchbase",
                                                        query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            appeq_summary_row: [
                                                {
                                                    appeq_summary_column: {
                                                        appeq_summary_label: "Region",
                                                        appeq_summary_value:
                                                            "No Data",
                                                        appeq_summary_type: "crunchbase",
                                                        query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                                    },
                                                },

                                            ],
                                        },


                                    ]
                                    await Promise.all(this.summaryData.map(res => {
                                        res.appeq_summary_row.map(d => {
                                            if (d.appeq_summary_column.appeq_summary_type == 'crunchbase') {
                                                if (d.appeq_summary_column.appeq_summary_label == 'Org Linkedin' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.linkedin_url) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.linkedin_url
                                                }
                                                if (d.appeq_summary_column.appeq_summary_label == 'Region' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.city) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.city
                                                }
                                                if (d.appeq_summary_column.appeq_summary_label == 'Website' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.homepage_url) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.homepage_url
                                                }
                                                if (d.appeq_summary_column.appeq_summary_label == 'About' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.short_description) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.short_description
                                                }
                                            }
                                        })

                                    }))
                                    this.loadingSum = false;
                                }
                                console.log(ids)
                                this.summaryIds = ids.data
                                if (this.summaryIds.length == 0) {
                                    this.loadingSum = false;
                                    this.loadingTrends = false;
                                    return;
                                }
                                if (mode == true) {
                                    this.selectedIds = ID;
                                    this.selectedDom = selectedSum
                                } else {
                                    this.selectedIds = this.summaryIds[0].id
                                    this.selectedDom = this.summaryIds[0]

                                }
                            } else {

                            }

                        }
                    }
                    if (mode == true) {
                        this.selectedIds = ID;
                        this.selectedDom = selectedSum
                    } else {
                        this.selectedIds = this.summaryIds[0].id
                        this.selectedDom = this.summaryIds[0]

                    }
                    console.log(this.selectedIds)
                    const email = this.email;
                    let data = (await ApiServer.fetchSummaryData(this.account_domain, this.selectedIds))

                    if (data && data.length == 0) {
                        this.noDataFound = true;
                        this.loadingSum = false;
                        return;
                    }


                    console.log(data)
                    if (data.data && data.data[0].ai) {
                        this.showHighBtn = true;
                    }

                    // check for members for which summary to be shown
                    if (data && data.data && data.data[0].data)
                        this.summaryData = data.data[0].data
                    else {
                        this.noDataFound = true;
                        this.loadingSum = false;
                        return;
                    }

                    // if(this.summaryData.length!=0){
                    //     if(this.summaryData[0]['appeq_summary_row'].length>=3){
                    //         this.summaryData.push({
                    //             appeq_summary_row:[this.summaryData[0]['appeq_summary_row'][2]]
                    //         })
                    //         // delete this.summaryData[0]['appeq_summary_row'][2]
                    //     }
                    // }
                    const newData = this.convertTo6x2(this.summaryData)
                    this.summaryData = newData
                    // this.summaryData = data.data.data[0].data
                    await Promise.all(this.summaryData.map(async res => {

                        if (res.appeq_summary_row) {
                            await Promise.all(res.appeq_summary_row.map(async d => {

                                if (d.appeq_summary_column.appeq_summary_value) {
                                    let val = d.appeq_summary_column.appeq_summary_value
                                    let funcName = val.toString().substr(0, val.toString().indexOf("("));
                                    val = val.toString().substr(val.toString().indexOf("("));

                                    // d.appeq_summary_column.appeq_summary_bObj = val.split(",")[0].replace("(", "")
                                    if (d.appeq_summary_column.appeq_summary_bObj == 'Crunchbase Data') {
                                        if (val.split(",")[1].replace(")", "").includes('linkedin')) {
                                            if (crunchbaseData.data && crunchbaseData.data.data)
                                                d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.linkedin_url
                                            else
                                                d.appeq_summary_column.appeq_summary_value = "No Data"

                                        } else if (val.split(",")[1].replace(")", "").includes('website_url')) {
                                            d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.homepage_url

                                        } else if (val.split(",")[1].replace(")", "").includes('short_description')) {
                                            d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.short_description

                                        } else {
                                            d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.region

                                        }
                                        return;
                                    }

                                    // d.appeq_summary_column.appeq_summary_value = (await ApiServer.fetchSummaryDataObj(this.account_domain, val.split(",")[0].replace("(", ""), funcName, val.split(",")[1].replace(")", ""))).data
                                    if (d.appeq_summary_column.appeq_summary_value.error) {
                                        d.appeq_summary_column.appeq_summary_value = 'No Data'
                                        d.appeq_summary_column.appeq_summary_type = ''
                                        return
                                    }
                                    const link = d.appeq_summary_column.appeq_summary_value.link
                                    console.log("Link is " + link)
                                    d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.data
                                    if (link) {
                                        d.appeq_summary_column.appeq_summary_value += " ?"
                                        d.appeq_summary_column.appeq_summary_type = "link"

                                    }

                                    if (d.appeq_summary_column.appeq_summary_type == 'graph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        this.lineData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data.date)
                                        this.lineData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.tickets) }]
                                        const ticketsData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                            return { time: data.date.toString(), value: data.tickets }
                                        })
                                        this.lineData['data'] = ticketsData
                                        return;
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'bargraph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        console.log(d.appeq_summary_column.appeq_summary_value, "---bar")
                                        if (d.appeq_summary_column.appeq_summary_value.length == 0) {
                                            return;
                                        }
                                        this.barData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data._id.day + "/" + data._id.month + "/" + data._id.year)
                                        this.barData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.count) }]
                                        const barData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                            return { time: data._id.day + "/" + data._id.month + "/" + data._id.year, value: data.count }
                                        })
                                        this.barData['data'] = barData
                                        return;
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'lastTicket' && d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at']) {
                                        let date1 = new Date()
                                        var x = new moment(d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at'] ? d.appeq_summary_column.appeq_summary_value['created_at'] : null)
                                        var y = new moment(date1)
                                        var duration = moment.duration(x.diff(y))
                                        let duration_days = Math.abs(Math.floor(duration.asDays()))
                                        d.appeq_summary_column.appeq_summary_prevalue = duration_days + ' days ago by '
                                        d.appeq_summary_column.query = { 'id': d.appeq_summary_column.appeq_summary_value['id'] }
                                        d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value['requester_email']
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value['requester_email'].toString().split("@")[0][0]
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.map(data => data.toString().split("@")[0].split(".").map(d => d[0]).join(""))
                                    }
                                    d.appeq_summary_column.appeq_summary_value = typeof d.appeq_summary_column.appeq_summary_value == "object" && d.appeq_summary_column.appeq_summary_type != "requesters" ? "_" : d.appeq_summary_column.appeq_summary_value;
                                    if (d.appeq_summary_column.appeq_summary_label == 'Health') {
                                        d.appeq_summary_column.appeq_summary_value = this.health;
                                        console.log("health of it is", this.health);
                                    }
                                }

                            })
                            )
                        }
                    }))
                    if (data.data && data.data[0].third_party_data) {
                        const newsummData = [
                            {
                                appeq_summary_row: [
                                    {
                                        appeq_summary_column: {
                                            appeq_summary_label: "Org Linkedin",
                                            appeq_summary_value: "No Data",
                                            appeq_summary_type: "crunchbase",
                                            query: "{}",
                                        },
                                    },
                                    {
                                        appeq_summary_column: {
                                            appeq_summary_label: "Website",
                                            appeq_summary_value:
                                                "No Data",
                                            appeq_summary_type: "crunchbase",
                                            query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                        },
                                    },
                                ],
                            },
                            {
                                appeq_summary_row: [
                                    {
                                        appeq_summary_column: {
                                            appeq_summary_label: "Region",
                                            appeq_summary_value:
                                                "No Data",
                                            appeq_summary_type: "crunchbase",
                                            query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                        },
                                    },

                                ],
                            },


                        ]
                        await Promise.all(newsummData.map(res => {
                            res.appeq_summary_row.map(d => {
                                if (d.appeq_summary_column.appeq_summary_type == 'crunchbase') {
                                    if (d.appeq_summary_column.appeq_summary_label == 'Org Linkedin' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.linkedin_url) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.linkedin_url
                                    }
                                    if (d.appeq_summary_column.appeq_summary_label == 'Region' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.city && crunchbaseData.data.data.region) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.city + "," + crunchbaseData.data.data.region
                                    }
                                    if (d.appeq_summary_column.appeq_summary_label == 'Website' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.homepage_url) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.homepage_url
                                    }
                                    if (d.appeq_summary_column.appeq_summary_label == 'About' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.short_description) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.short_description
                                    }
                                }
                            })

                        }))
                        this.summaryData.unshift(...newsummData)
                    }
                    this.loadingSum = false;
                    let trendsFunctions = data.data[0].trends
                    await new Promise((res) => setTimeout(res, 200))
                    let functionName = [];
                    this.openPulseInfo();
                    const lastValue = await ApiServer.getLatestVal(this.selectedIds, this.account_domain)
                    console.log(lastValue, "---1234")
                    if (lastValue && lastValue.data && lastValue.data.length != 0) {
                        this.reason = "Reason : " + lastValue.data[0]['data.reason']
                        this.reasonTime = this.convertToDaysAgo(lastValue.data[0]["data.time"])

                    }
                    if (trendsFunctions && Array.isArray(trendsFunctions) && trendsFunctions.length > 0) {
                        functionName = trendsFunctions;
                        this.trendsValue = true;
                    }
                    else {
                        this.loadingTrends = false;
                        this.trendsValue = false;
                        return;
                    }
                    let selectKys = functionName.map(d => d.key).filter(d => d != null)
                    this.trends = functionName
                    console.log(this.trends, "---1234")
                    let trendsData = await ApiServer.openTrendsData(this.account_domain, selectKys, this.selectedIds);

                    this.loadingTrends = false;

                    console.log(trendsData);

                    await new Promise((res) => setTimeout(res, 200))
                    if (trendsData.data) {
                        functionName.map((d, idx) => {
                            console.log(d.key)

                            if (d.key != "npsbargraph" && d.key != "npspiechart") {
                                const ticketsData = trendsData.data[idx].map(data => {
                                    if (data.timestamp) {
                                        data.date = new Date(data.timestamp)
                                    }
                                    return { time: data.date.toString(), value: data.tickets ? data.tickets : data.value }
                                })

                                const chart = createChart(document.getElementById("chartDiv" + idx), {
                                    width: 120, height: 80, grid: {
                                        vertLines: {
                                            color: "transparent",
                                            visible: false
                                        },
                                        horzLines: {
                                            color: "transparent"
                                        },

                                    },
                                    leftPriceScale: {
                                        visible: true,
                                    },

                                    timeScale: {
                                        barSpacing: 9,
                                    },

                                });
                                chart.priceScale('left').applyOptions({
                                    autoScale: true,
                                    visible: true,
                                });
                                chart.priceScale('right').applyOptions({
                                    autoScale: true,
                                    visible: false
                                });
                                const lineSeries = chart.addLineSeries();
                                lineSeries.applyOptions({

                                    lineType: 2, lineStyle: 0,
                                    priceScale: {
                                        position: 'left',

                                    },


                                })
                                lineSeries.setData(ticketsData);
                                this.chartDi = chart
                            } else if (d.key == "npspiechart") {
                                console.log(d.key)
                                console.log(trendsData.data[idx])
                                console.log("here123")
                                const arrays = []
                                // const data = [{ value: 1, time: 1642425322 }, { value: 8, time: 1642511722 }, { value: 10, time: 1642598122 }, { value: 20, time: 1642684522 }, { value: 3, time: 1642770922, color: 'red' }, { value: 43, time: 1642857322 }, { value: 41, time: 1642943722, color: 'red' }, { value: 43, time: 1643030122 }, { value: 56, time: 1643116522 }, { value: 46, time: 1643202922, color: 'red' }];

                                const ticketsData = trendsData.data[idx].map(data => {
                                    // if(data.timestamp){
                                    //     data.date=new Date(data.timestamp)
                                    // }
                                    //         return { time: data.date.toString(), value: data.tickets?data.tickets:data.value }
                                    arrays.push({
                                        value: data.countDetractors,
                                        time: 1642425322,
                                        color: "red"
                                    })
                                    arrays.push({
                                        value: data.countPassive,
                                        time: 1642511722,
                                        color: "yellow"

                                    })
                                    arrays.push({
                                        value: data.countPromoters,
                                        time: 1642598122,
                                        color: "green"


                                    })

                                })
                                const chart = createChart(document.getElementById("barDiv"), {
                                    timeScale: {
                                        borderColor: "rgba(197, 203, 206, 0.8)",
                                        timeVisible: false,
                                        secondsVisible: false,
                                        rightBarStaysOnScroll: false,
                                    },
                                    showTooltips:false
                                });
                                
                                chart.addHistogramSeries({
                                    
                                    priceFormat: {
                                        type: 'volume',
                                    },
                                    priceScaleId: '', // set as an overlay by setting a blank priceScaleId

                                }).setData(arrays)
                            }
                        })
                    }
                    // if (this.lineData['data']) {
                    //     const chart = createChart(document.getElementById("chartDiv"), {
                    //         width: 120, height: 120, grid: {
                    //             vertLines: {
                    //                 color: "transparent",
                    //                 visible: false
                    //             },
                    //             horzLines: {
                    //                 color: "transparent"
                    //             },

                    //         },
                    //         leftPriceScale: {
                    //             visible: true,
                    //         },

                    //         timeScale: {
                    //             barSpacing: 9,
                    //         },

                    //     });
                    //     chart.priceScale('left').applyOptions({
                    //         autoScale: true,
                    //         visible: true,
                    //     });
                    //     chart.priceScale('right').applyOptions({
                    //         autoScale: true,
                    //         visible: false
                    //     });
                    //     const lineSeries = chart.addLineSeries();
                    //     lineSeries.applyOptions({

                    //         lineType: 2, lineStyle: 0,
                    //         priceScale: {
                    //             position: 'left',

                    //         },


                    //     })
                    //     lineSeries.setData(this.lineData['data']);
                    //     this.chartDi = chart
                    //     if (this.highLights) {
                    //         this.disableHightLighBtn = true;
                    //         try {
                    //             let box = document.querySelector('#newSummaryDiv');
                    //             let widthh = box.offsetWidth - 10;
                    //             console.log(widthh);

                    //             var element = document.getElementById("highLights");
                    //             console.log(element);

                    //             element.setAttribute("style", `display:block;width:${widthh}px`);
                    //         } catch (e) {
                    //             console.log(e);
                    //         }
                    //         // await this.typeWriter(this.highLights)
                    //         this.disableHightLighBtn = false;
                    //     }
                    // }


                }

                catch (err) {
                    this.loadingSum = false;
                    console.log(err);

                }
            }
        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'salesforce.com' + '&sz=128';
                else
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link + '&sz=128';
            } catch (e) {
                console.log(e);
            }
        },
        closeSumm() {
            try {
                if (this.chartDi)
                    this.chartDi.remove();
                if (this.barDi) {
                    this.barDi.remove();
                }
            } catch (e) {
                console.log(e);
            }

            if (document.getElementById("chartDiv"))
                document.getElementById("chartDiv").remove();
            if (document.getElementById("barDiv"))
                document.getElementById("barDiv").remove();
            window.parent.postMessage({action:"__appeq_close_summary", data:""},"*");
            AppEQAnalytics.actionCall("SummaryClosed", "Inbox", {})


        },
        closeModalSumm() {
            feeds.sendActionToTop("__appeq__close_modal", "");
        },
        handleRedirect(query, val) {
            if (typeof query != 'object') {
                query = JSON.parse(query)
            }
            let final = { ...query, account_domain: this.account_domain, business_object_name: val, account_id: this.selectedIds }
            console.log(final)
            feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
        },
        handleOpenAccount() {
            feeds.sendActionToTop("__appeq_open_account_collabration", { account_domain: this.account_domain, id: this.selectedIds });
        },
        handleOpenLatest() {
            feeds.sendActionToTop("__appeq_open_profile_summary", { account_domain: this.account_domain });
            AppEQAnalytics.actionCall("GenieOpened", "Inbox", { account_domain: this.account_domain })
        },
        openHealthRed() {
            let final = { health: "red", account_domain: this.account_domain, business_object_name: { "$ne": "pulse" } }
            // feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
            // window.postMessage({action:"__appeq_open_ticket_iframe_summary",data:final})
            window.parent.postMessage({action:"__appeq_open_ticket_iframe_summary",data:final},"*")
            // chrome.runtime.sendMessage({action:"__appeq_open_ticket_iframe_summary",data:final}, '')
        },
        getFeedName(name) {
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 2) {
                        companyName = companyName.toString().split(".")[1] + '.' + companyName.toString().split(".")[2];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
    },
}
</script>

<style scoped lang="css">
@import './Summary.css';

img {
    cursor: pointer;
    height: 9px;
    margin-left: 8px;
    margin-top: 2px;

}

.requesterEmail {
    background-color: green;
    color: #fff;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    /* padding: 4px; */
    margin-left: 3px;
    width: 20px;
    display: block;
    text-align: center;
}

.close {
    object-fit: cover;
}

.knowMoreHighLights {
    font-size: 14px;
    font-weight: 600;
    margin-left: -4px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.knowMoreHighLights img {
    height: 50px;
    margin-top: 0px;
    margin-left: 0px;
}

#highLights {
    font-size: 10px;
    color: rgb(129, 127, 127);
}

.loadingHighLights {
    height: 40px;
    width: 40px;
}

.loadingHigh {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.no_data_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

[role~=tooltip][data-microtip-position=right]:after,
[role~=tooltip][data-microtip-position=right]:before {
    left: 100%;
    top: 35%;
}

[aria-label][role~=tooltip]:after {
    padding: 0.5em 0.5em;
}

.tabs {
    width: 420px;
    font-size: 12px !important;
    overflow: scroll;
    overflow-y: hidden;
}

.tabs::-webkit-scrollbar-thumb {
    display: none;
    width: 0px;
}

.tabs::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.tab {
    text-wrap: nowrap;
    font-size: 12px !important;
}

.row {
    display: flex;
    align-items: flex-start;
    overflow: hidden;

}

.ticket_info_body {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    border-right: 1px solid rgb(118 129 237 0.5);
    /* width: 75%; */
    /* flex: 0 0 auto; */
    max-width: 75%;
    min-height: 18vh;
}

.trends {
    border-top: 1px solid lightgrey;
    padding-top: 8px;
}

.multiselect_notCustomer {
    .multiselect {
        width: 4rem !important;
    }

    .multiselect__tags {
        width: 4rem !important;
    }
}

.options {
    font-size: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.healthSpan {
    cursor: pointer !important;
}
.row{
    margin-left: 0px !important;
}
</style>
