<template>
    <div v-if="summary">

        <!-- <div class="addDiv">
            <div class="summaryNameDiv">
                <span>Summary Name : {{ this.store.currentSummaryName }}</span>
            </div>
        </div> -->

        <!-- <span v-if="!summary.custom" class="summaryName">{{ summary.summary_name }}</span> -->
        <input v-if="!summary.custom" v-model="summary.summary_name" />
        <div class="summary__card" :class="{ configCard: configured }">
            <div class="summary__card--heading">
                <img src="https://s2.googleusercontent.com/s2/favicons?domain_url=https://tesla.com&sz=128" />
                Tesla (Your customer name)
               
            </div>

            <div class="summary__card--body">
                <!-- <div class="aiDiv">
                <div style="display: flex;align-items: center;">
                   
                    <h4 style="margin-bottom: 0px;font-size: 15px;color: #535f7f !important;">Third Party Account summary</h4>
                </div>
                </div> -->
                <!-- <div style="margin-left: 10px;width: 100%;" class="aiDiv"> -->
                <!-- <div style="display: flex;align-items: center;">
                    <h4 style="margin-bottom: 0px;font-size: 15px;color: #535f7f !important;">Enable Third Party Features</h4>
                </div>

                <div>
                    <label style="margin-left: 10px;
                                                display: block;
                                                margin-top: auto;
                                                margin-bottom: auto;" class="switch">
                        <input type="checkbox" v-model="store.thirdPartyHigh">
                        <div class="slider round"></div>
                    </label>

                </div> -->
            <!-- </div> -->
                </div>
                <div>
                <div v-if="summary && summary.data" class="summary__card--wholerow">

                    <div v-for="(v, i) in summary.data" class="summary__card--row">
                        <template v-for="(d, ind) in v.appeq_summary_row">
                            <div class="tile-row">
                                <span class="summary__card--boldtcv">

                                    {{ d.appeq_summary_column.appeq_summary_label ?
                                        d.appeq_summary_column.appeq_summary_label : 'Not Configured' }}
                                </span> &nbsp;

                                <div style="display:flex;margin-right:20px">

                                   
                                        <i v-if="!static"  v-tooltip="'Click to Edit the Key'"  @click="openModal(i, ind)"
                                        rc="../../assets/penciledit.svg"   class="edit tim-icons icon-pencil"></i>

                                       
                              


                                    <img @click="store.addRow(summary, i, ind, currentHighLight)"
                                        v-tooltip.bottom-start="'Click To Add an Entry'"
                                        v-if="store.findMax(summary, i, ind)"
                                        src="../../assets/thin-add-button-svgrepo-com.svg"
                                        style="height: 15px;margin-left: 10px;" />
                                

                                        <img 
                                        v-tooltip.bottom-start="'Click To Delete an Entry'"
                                        @click="store.deleteRow(summary,i,ind,)"
                                        v-if="store.findMax(summary, i, ind)"
                                        src="../../assets/delete.svg"
                                        style="height: 15px;margin-left: 10px;" />
                                
                                </div>
                                   
<!-- 
                                <img v-if="!static" v-tooltip.auto-end="'Click to Edit the Key'" @click="openModal(i, ind)"
                                    src="../../assets/penciledit.svg" class="edit" /> -->
                                

                            </div>


                        </template>
                    </div>
                    <!-- <span><h3 style="margin: 0px; font-size: 15px; border-top: 0.5px solid hsla(0,0%,76.9%,.74); margin-top: 10px; padding-top: 10px; color: rgb(83, 95, 127) !important;">Trends</h3></span> -->
                    <!-- <div style="display: flex; justify-content: space-between; width: 22rem; margin-top: 0.5rem;" class="trends_multiselect">
                        <Multiselect :options="trendsOption" v-model="trends[0].value" @select="handleTrends()" />
                        <Multiselect :options="trendsOption" v-model="trends[1].value" @select="handleTrends()" />
                    </div> -->
                    <!-- <div class="" style="display: flex;align-items: center;">
                        <div class="trends-options" v-for="(trend, idx) of trends">
                            <div class="">
                                {{ trend && trend.value ? trend.value : "NA" }}
                            </div>
                            <img @click="editTrend(idx)" style="height: 24px;cursor: pointer;" src="../../assets/edit.svg" />
                        </div>
                    
                    </div> -->

                </div>

            </div>
            <!-- <div class="aiDiv">
                <div style="display: flex;align-items: center;">
                    <img style="height:30px;width: 40;" src="../../assets/eqai.svg" />
                    <h4 style="margin-bottom: 0px;font-size: 15px;color: #535f7f !important;">Enable AI Features</h4>
                </div>

                <div>
                    <label style="margin-left: 10px;
                                                display: block;
                                                margin-top: auto;
                                                margin-bottom: auto;" class="switch">
                        <input type="checkbox" v-model="currentHighLight" @change="saveAi">
                        <div class="slider round"></div>
                    </label>

                </div>
            </div> -->


        </div>


        <div v-bind:class="{ configured: configured }" style="display: flex;flex-direction: column;">
            <div v-if="!static && configured">


                <span style="position: relative;">
                    <span  style="display: flex;align-items: center;justify-content: flex-start;">
                        Visible to Profile: &nbsp;
                        
                        <span 
                            style="display: flex;align-items: center;justify-content: space-between;width: 100%;"
                            class="summaryProfileName">
                            <!-- {{ summary.profile_name && summary.profile_name.length ? summary.profile_name.join(", ") :
                            "Unassigned" }} -->

                            <div style="display: flex;" v-if="summary.profile_name && summary.profile_name.length > 0">
                                <div v-tooltip.bottom-end="mem"
                                    :style="{ background: '#4f60ff', height: '25px', width: '25px',
                                     textAlign: 'center', color: 'white', borderRadius: '50%', marginLeft: '10px',display:'flex',
                                     justifyContent:'center',alignItems:'center',fontSize:'12px' }"
                                    v-for="mem of summary.profile_name">
                                    {{ getNames(mem) }}
                                </div>
                            </div>
                            <div style="font-weight: 550;" v-else>
                                Unassigned
                            </div>
                            <!-- <div class="grid-container">
                                
                                    <template v-for = "name in summary.profile_name" v-if="summary.profile_name && summary.profile_name.length>0">
                                        <div class="grid-item list"><span class="profileBlock">{{ name }}</span></div>
                                    </template>
                                    <template v-else>
                                        jhgjhgkjhg
                                    </template>
                            
                            </div> -->
                        </span>

                        <button class="attachProfileButton"  v-tooltip.auto-end="'Click To Attach Visibility Profile'"
                                v-if="!static && !showVisibilityProfile"
                                @click="showVisibilityProfile = !showVisibilityProfile">
                            Attach Profile
                        </button>
                        
                    
                       
                       
                    </span>
                    <div class="visibilityName" v-if="showVisibilityProfile">
                        <Multiselect id="mySelect3" class="multiStyle2" select-label="" :multiple="true" :taggable="true"
                            :hide-selected="true" :options="store.profileList" v-model="selectedProfiles"
                            placeholder="Select Visibility Profile">
                        </Multiselect>
                        <span  v-if="!static && showVisibilityProfile" class="material-symbols-outlined close"  @click="showVisibilityProfile = !showVisibilityProfile"
                            v-tooltip.auto-end="'Click to Close Visibility Profile'" style="margin-left: 10px;">
                            close
                        </span>
                        <button class="saveProfileButton" @click="handleEdit()"  v-if="showVisibilityProfile">
                            save
                        </button>
                    </div>
                </span>

            </div>
            <button style="width: 30%;height: 30px;border-radius: 25px;" v-if="false"
                @click="store.saveSummary(summary, currentHighLight, trends, configured)" class="saveBtn"> <i
                    class="tim-icons icon-calendar-60"></i>Save </button>
            <!--               
                <button v-if="!isAdmin" class="saveBtnFalse" v-tooltip.auto-auto="'Only Admin Can Save Template'"> <i
                        class="tim-icons icon-calendar-60"></i>Save </button> -->
        </div>

        <modal v-if="openTrendModal" class="trendModal" :show="openTrendModal">
            <i @click="openTrendModal = !openTrendModal" class="tim-icons close-icon icon-simple-remove"></i>
            <TrendsOptions @close="handleLabelFromTrends($event)" />
        </modal>
        <modal v-if="isEditModal" class="editModal" @close="isEditModal = !isEditModal" :show="isEditModal">
            <i @click="isEditModal = !isEditModal" class="tim-icons close-icon icon-simple-remove"></i>
            <!-- <editModal @onsave="handleSave($event)" :summary="summary"
                :enteredLabel="this.summary.data[selectedI].appeq_summary_row[selectedInd]['appeq_summary_column']['appeq_summary_label']"
                :selectedObject="extractBObject(this.summary.data[selectedI].appeq_summary_row[selectedInd]['appeq_summary_column']['appeq_summary_value'])"
                :selectedKey="extractFunc(this.summary.data[selectedI].appeq_summary_row[selectedInd]['appeq_summary_column']['appeq_summary_value'])">
            </editModal> -->
             <KeySelector :selectedSumm="summary.data[selectedI].appeq_summary_row[selectedInd]['appeq_summary_column']" @onsave="handleSave($event)"/> -
        </modal>
        <modal class="modal-search" modalClasses="mymodal" modalContentClasses="modalContent"
            @close="isEditModal = !isEditModal" :show="store.isSuccess">
            <i @click="isEditModal = !isEditModal" class="tim-icons close-icon icon-simple-remove"></i>
            <div v-if="store.onLoad" class="loading">
                <img src="../../assets/infinity_blue.svg" />
            </div>
            <div class="success-div" v-if="!store.onLoad">
                <h2>Summary Template Saved Successfully.</h2>
                <img src="../../assets/img/successful.svg" />
            </div>
        </modal>
        <modal :show="openconfirmmodel" v-if="openconfirmmodel">
            <div>
                <h3>Confirmation</h3>
                <span>Are you sure you want to change the visibilty for profile ?</span>
                <br />
                <span>{{ this.msg }}</span>
                <div
                    style="display: flex;align-items: center;justify-content: flex-end;position: absolute;right: 15px;bottom: 15px;">
                    <button @click="handleConfirm()" style="width: 120px;height: 40px;border-radius: 25px;">Yes</button>
                    <button @click="openconfirmmodel = false;"
                        style="width: 120px;height: 40px;margin-left: 20px;border-radius: 25px;">No</button>
                </div>


            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../../components/Modal.vue';
import { store } from "./Store"
import Server from '../../components/API/Server';
import editModal from "../../components/EditModalforsummary.vue"
import mixpanel from '../../analytics/mixpanel';
import NavbarToggleButton from "../../components/NavbarToggleButton.vue";
import Multiselect from "vue-multiselect";
import KeySelector from '../../components/KeySelector.vue';
import TrendsOptions from './TrendsOptions.vue';

export default {
    name: "Summary",
    props: {
        index: {
            type: Number,
            default: -1
        },
        static: false,
        configured: false,
        summary: null,
        trendsEdit: []
    },
    
    data() {
        return {
            store,
            startedEditing:false,
            openconfirmmodel: false,
            isEditModal: false,
            isAdmin: true,
            msg: '',
            openTrendModal:false,
            oldSumName:'',
            selectedI: -1,
            commmonProfile: [],
            selectedInd: -1,
            showVisibilityProfile: false,
            currentHighLight: false,
            ticketHighLights: false,
            ticketHighLights: false,
            selectedProfiles:[],
            trends:[{value: ""},{value: ""}],
            currentTrendIdx: 0,
            trendsOption: []

        };
    },
    methods: {
        editTrend(value){
            this.currentTrendIdx = value;
            this.openTrendModal=!this.openTrendModal
        },
        extractFunc(data){
            if(data){
                const bObject=data.toString().split("(").length>0?data.toString().split("(")[1].split(",")[0]:""
                if(data.toString().split("(").length>0){
                    return data.toString().split("(")[1].split(",")[1].replace(")","")
                }else{
                    return ""
                }
            }
            return ""
        },
        extractBObject(data){
            if(data){
                if(data.toString().split("(").length>0){
                    return data.toString().split("(")[1].split(",")[0]
                }else{
                    return ""
                }
            }
            return ''
        },
        handleDeselect(ev){
            console.log("Deselected")
            this.$toasted.error("Cannot remove items")
        },
        handleLabelFromTrends(e){
            console.log(e)
            console.log(this.trends)
            this.trends[this.currentTrendIdx] = e;
            this.$emit('trends-options', this.trends);
            this.openTrendModal = !this.openTrendModal;
        },
        async handleEdit() {

            if(this.summary){
               if(this.summary.profile_name && this.summary.profile_name.length>0){
                    if(this.selectedProfiles.length==0){
                        this.$toasted.error("Please select a value");
                        return;
                    }
                    this.selectedProfiles.map((d)=>{
                        if(!this.summary.profile_name.includes(d)){
                            this.summary.profile_name.push(d);
                        }
                    })
               }else if(this.summary.profile_name && this.summary.profile_name.length==0){
                    this.summary.profile_name = this.selectedProfiles;
               }
               else{
                 this.$toasted.error("Something went wrong please try again after some time");
                 return;
               }
            }else{
                this.$toasted.error("Something went wrong please try again after some time");
                 return;
            }


            this.commmonProfile = this.summary.profile_name
            const d = await this.store.saveSummary(this.summary, this.currentHighLight, this.trends, this.configured, true);
            console.log(d)
            if (d && d.data && d.data.code != 200) {
                this.$toasted.error(d.data.msg);
                this.openconfirmmodel = true;
                if (d.data.data && d.data.data[0]) {
                    
                    this.msg = d.data.msg
                }
                this.showVisibilityProfile = true;
            } else {
                this.showVisibilityProfile = false;
                this.$toasted.success("Profile changed Successfully")
            }
            this.showVisibilityProfile = false;
        },
        handleTrends(){
            this.$emit('trends-options', this.trends);
        },
        async handleConfirm() {
           
            await Server.changeRoles(this.summary.summary_name, this.commmonProfile)
            this.openconfirmmodel = false
            const resData = await Server.getSummaryJson();
            this.store.summaryList = [];
            if (resData && resData.code == 200 && resData.data) {
                await resData.data.map((d) => {
                    this.store.summaryList.push(d);
                });
            }
        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        getNames(name) {
            var regex = /\d/g;
            let no = (name.match(regex));
            if (no != null)
                no = no[0]



            let len = name.split(" ")
            if (len.length > 1) {
                name = len[0][0] + "" + len[1][0]
                if (no !== null)
                    name = name + no

            }
            else if (len.length > 0 && len.length <= 1) {
                name = len[0][0]
                if (no !== null)
                    name = name + no
            }

            name = name.toUpperCase()
            return name
        },
        handleSave(event) {
            if (event.selectedObject == 'ticket' || event.selectedObject.toString().toLowerCase() == 'customer invoice' || event.selectedObject == "Opportunities" || event.selectedObject == "Net Promoter Score" || event.selectedObject =="tasks") {
                event.selectedFunc = event.selectedKey
            }
            if (event.type) {
                this.summary.data[this.selectedI].appeq_summary_row[
                    this.selectedInd
                ].appeq_summary_column["appeq_summary_type"] = event.type.type;
                if (event.type.query) {
                    this.summary.data[this.selectedI].appeq_summary_row[
                        this.selectedInd
                    ].appeq_summary_column["query"] = event.type.query;
                }
            }
            this.summary.data[this.selectedI].appeq_summary_row[
                this.selectedInd
            ].appeq_summary_column["appeq_summary_value"] = `${event.selectedFunc}(${event.selectedObject},${event.selectedKey})`;

            this.summary.data[this.selectedI].appeq_summary_row[
                this.selectedInd
            ].appeq_summary_column["appeq_summary_label"] = event.enteredLabel;
            this.isEditModal = false
            this.store.saveSummary(this.summary, this.currentHighLight, this.trends, this.configured);
        },
        openModal(i, ind) {
            this.startedEditing=true
            this.selectedI = i
            this.selectedInd = ind
            this.isEditModal = true
        },
        toggleCheckbox() {
            currentHighLight = !currentHighLight;
        },
        async deleteSummaryLocal(){

            //these response is custome response generated in store.js
            const res =  await this.store.deleteSummary(this.summary);
            
            if(res[0]){
                this.$toasted.success(res[1]);
            }
            else{
                this.$toasted.error(res[1]);
                return
            }

            const data = await Server.getSummaryJson();  
            this.store.summaryList=[]
            if(data && data.code==200 && data.data){
                await data.data.map(d=>{
                        this.store.summaryList.push(d);
                });          
            }
        },
        saveAi() {
            this.summary.ai=true
            // this.store.saveSummary(this.summary, this.currentHighLight, this.configured);
            console.log(this.currentHighLight);
        },
        saveThirdParty() {
            this.summary.thirdParty=true
            // this.store.saveSummary(this.summary, this.currentHighLight, this.configured);
            console.log(this.currentHighLight);
        },
    },
    
    
    beforeMount() {
        //this.isAdmin = localStorage.getItem("IS_ADMIN") === 'true'? true: false;
        this.isAdmin = true;
        console.log(this.isAdmin);
    },
    async mounted() {
        let data = await Server.getGraphFunction();
        if(data.data){
            for(let i=0;i<data.data.length;i++){
                if(data.data[i].function_name){
                    this.trendsOption.push(data.data[i].function_name);
                }
            }
        }
        console.log(this.summary);
        this.trends=this.trendsEdit
        // if(this.trendsEdit.length>0){
        //     this.trends[0].value = this.trendsEdit[0];
        //     this.trends[1].value = this.trendsEdit[1];
        // }
        this.oldSumName=this.summary.summary_name
        this.currentHighLight = this.summary ? this.summary.ai : false;
        
        console.log(this.trendsOption);
    }

    , components: {
    Modal, editModal,
    NavbarToggleButton,
    Multiselect,
    KeySelector,
    TrendsOptions
}
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/black-dashboard/custom/themes';

.head-text {
    margin: 10px;
    margin-bottom: 20px;
    color: $login-button-color;
}

.loading {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small_logo {
    height: 20px;
    margin-bottom: auto;
}

.success-div {
    h2 {
        text-align: center;
        font-size: 20px;
    }

    img {
        display: flex;
        margin: auto;
        height: 250px;
        width: 370px;
    }
}

.close-icon {
    position: absolute;
    cursor: pointer;
    right: 27px;
    top: 24px;
    outline: 0;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #555555;

    &:hover {
        scale: 1.09;
        color: black;
    }
}

.preview-div {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    overflow: hidden;
    transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 990px) {
    .preview-div {
        left: 0px;
    }

    .summary__card_new {
        left: 10vw !important;
    }
}

.summary__card{

    min-width: 300px;
    width: fit-content;
}
.preview-Button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #6f55ff;
    width: 130px;
    height: 30px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    border-radius: 10px;
    color: white;
    left: 41px;
    z-index: 2;

    &:hover {
        background: blue;
        border: 1px solid white;
        color: white;
        border-radius: 10px;
        transition: 0.2s ease-in-out;
    }

    i {

        font-size: 13px;
        margin-right: 15px;

    }
}


.summary {

    &__heading {
        margin-top: 20px;
        font-size: 16px;
        padding-left: 20px;
        font-weight: 700;
    }

    &__card_new {
        background-color: white;
        color: #525f7f;
        border: 0.5px solid red;
        border-radius: 5px;
        padding: 15px 11px;
        width: 900px;
        min-width: 750px;
        position: absolute;
        top: 15vh;
        left: 23vw;

        &--footer {
            font-size: 10px;
            margin-bottom: -30px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--body {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--wholerow_new {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            min-width: 100%;
            min-height: 50px;
            overflow: hidden;

            .tile-row {
                width: 30%;
                min-height: 50px;
                height: 20px;
                overflow: hidden;
            }
        }

        .summary__card--row_tile-row_new {
            min-width: 250px;
            width: 30%;
            height: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }

        &--boldtcv {
            color: #525f7f;
            font-weight: 700 !important;
            font-style: italic;
            width: 31%;
            font-size: 12px !important;
        }

        &--row {
            margin-top: 8px;
            align-items: center;
            display: flex;
        }

        &--heading {
            display: flex;
            color: blue;
            font-weight: 600;
            border-bottom: 0.5px solid rgba(196, 196, 196, 0.74);
            padding-bottom: 10px;

            img {
                height: 28px;
                margin-right: 10px;
            }
        }

        p {
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            overflow: hidden;
            font-size: 10px;
            width: 175px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            display: inline;
        }
    }

    &__input {
        width: 100%;
        color: #525f7f;
        font-style: italic;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    &__mainrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.summary {




    &__heading {
        margin-top: 20px;
        font-size: 16px;
        padding-left: 20px;
        font-weight: 700;
    }

    &__card {
        position: relative;
        background-color: white;
        border: 0.5px solid grey;
        border-radius: 5px;
        padding: 15px 11px;
        width: fit-content;
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 5px #c8c8c8;

        &--footer {
            font-size: 10px;
            margin-bottom: -10px;
            margin-top: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        &--body {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--wholerow {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;

            .tile-row {
                min-width: 150px;
                width: fit-content;

            }
        }

        &--boldtcv {
            min-width: 120px;
            font-weight: 700 !important;
            font-style: italic;
            color: #525f7f;
            font-size: 12px !important;
        }

        &--row {
            margin-top: 8px;
            align-items: center;
            display: flex;
        }

        &--heading {
            display: flex;
            color: blue;
            font-weight: 600;
            border-bottom: 0.5px solid rgba(196, 196, 196, 0.74);
            padding-bottom: 10px;

            img {
                height: 28px;
                margin-right: 10px;
            }
        }

        p {
            text-overflow: ellipsis;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            white-space: nowrap;
            overflow: hidden;
            font-size: 10px;
            width: 175px;
        }
    }

    &__input {
        width: 100%;
        color: #525f7f;
        font-style: italic;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    &__mainrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.health {
    padding: 2px 20px;
    color: white;
    background-color: red;
    border-radius: 4px;
    margin-left: 4px;
}

.edit {
    height: 10px;
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-radius: 50%;

    &:hover {
        scale: 1.3;
        transition: 0.2s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

.delete {
    height: 15px;
    margin-left: 10px;
    width: 15px;
    object-fit: contain;
    cursor: pointer;
    filter: invert(11%) sepia(94%) saturate(7149%) hue-rotate(359deg) brightness(98%) contrast(110%);
    transition: 0.2s ease-in-out;
    border-radius: 50%;

    &:hover {
        scale: 1.3;
        transition: 0.2s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

p {
    color: black;
}

.addColumn {
    display: flex;
    align-items: center;
    color: white;
    background: #6f55ff;
    justify-content: center;
    margin-right: 10px;
    border-radius: 10px;
    text-align: center;
    width: 130px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 290px;
}

.addColumn:hover {
    background-color: blue;
    color: white;
}

.addRow {
    color: blue;
    margin-top: 20px;
    cursor: pointer;
    width: 200px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid blue;

}

input {
    border: none;
    width: 100%;
    color: #525f7f;
    font-style: italic;
    font-weight: 700;

}

input:focus {
    outline: none;
    border-bottom: 0.5px solid rgba(196, 196, 196, 0.74);

}

.tile-row {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.footer {
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 10px;
    justify-content: flex-end;

    .saveBtn {
        background: #6f55ff;
        color: white;
        cursor: pointer;
        width: 50% !important;
        height: 30px;
        /* width: 200px; */
        margin: 5px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 12px;
            margin-right: 15px;
        }
    }

    .saveBtnFalse {
        border: 1px solid $login-button-color;
        color: $login-button-color;
        cursor: pointer;
        width: 130px;
        height: 30px;
        /* width: 200px; */
        margin: 5px;
        border-radius: 10px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;

        i {
            font-size: 12px;
            margin-right: 15px;
        }
    }

    .saveBtn:hover {
        background-color: blue;
        color: white;
    }

    .saveBtn:focus {
        outline: none;
    }
}

.modalContent {
    width: 1000px;

    .modal-body {
        padding-top: 80px !important;
    }
}

.mymodal {
    width: 1400px;
}

.summary__card--footer {
    align-items: center;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 21px;
    left: 0px;
    bottom: 0px;
    background-color: blue;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: lightblue;
}

input:focus+.slider {
    box-shadow: 0 0 1px #101010;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.mycard {
    margin-left: -4px !important;
    background-color: white;
    border-radius: 20px;
    padding: 15px 11px;
    width: 90%;
    min-width: 1000px;
    box-shadow: 0px 0px 5px #c8c8c8;
}

.summaryNameDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4e3e3;
    height: 40px;
}

.multiselect {
    margin-top: 0px !important;
    width: 100% !important;
}

.addDiv {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 20px;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.aiDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
}

multiselect {
    width: 100% !important;
    max-width: 100% !important;
}

.CongStyle.multiselect {
    width: 100%;
    max-width: 100vw;
}

.multiselect-tag {
    font-size: 0.8rem !important;
    color: white !important;
}

.multiselect {
    margin-top: 12px !important;
    width: 33% !important;
}

.multiselect-option {
    span {
        color: black !important;
        font-size: 1rem !important;
    }
}

.footerMultiselectDiv {
    margin-top: -10px;
    display: flex;
    width: 500px;

    .multiselect {
        margin-top: 12px !important;
        width: 53% !important;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center;

    }

}

.summaryName {
    display: block;
    margin-bottom: -20px;
    border-radius: 15px 15px 0px 0px;
    /* color: white; */
    /* background: grey; */
    padding: 5px;
    font-weight: 550 !important;
}

.deleteButton {
    position: absolute;
    right: 30px;
    color: red;
    cursor: pointer;
}

span.visibilityName {
    position: absolute;
}

.visibilityName {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 30px;
}

.multiselect {
    width: 100% !important;
    min-width: 230px !important;
    margin-top: 0px !important;
}

.summaryProfileName[data-v-56ec1242] {

    padding: 7px;
    border-radius: 5px;
    max-width: 80% !important;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.saveProfileButton{
    width: 70px;
    height: 30px;
    border-radius: 5px;
    margin-left: 10px;
}
.profileBlock{
    border: 0.3px solid;
    min-height: 30px ;
    min-width: 100px;
    margin: 10px;
    padding: 5px;
    border-radius: 5px ;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    width: 140px;

}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}
.grid-item {
  text-align: center;


}
.trends_multiselect{
    .multiselect{
        width: 10rem !important;
        min-width: 0px !important;
    }
    .multiselect__tags{
        width: 10rem !important;
        min-width: 0px !important;
    }
}
.attachProfileButton{
    position: absolute;
    right:10px;
    width:auto;
    height: 30px;
    background: white;
    color:#4e5fff;
    border: 1px solid #4e5fff;
    border-radius: 5px;
}
.trends-options{
    display: flex;
    align-items: center;
    margin-left: 10px;
}
</style>
<style>
.trendModal{
    .modal-dialog{
    margin: 0px;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;

  }
.modal-content{
    display: block;
    margin: auto;

width: 60vw !important;
    height: 55vh !important;}
}
</style>