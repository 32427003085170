<template>
    <div>
        <h2 style="text-align:center;margin-top:10px">Subscriptions</h2>
        <table style="margin-top: 20px;" class="table tablesorter">
            <thead>
                <slot name="columns">
                    <th v-for="column in columns" :key="column">{{ column }}</th>
                </slot>
            </thead>
            <tbody>
                <tr>
                    <td >
                        {{plans.label}}
                    </td>
                    <td >
                        {{getHumanDate(plans.created_date)}} ago
                    </td>
                    <td >
                      {{ plans.currency.toString().toUpperCase()}} {{plans.amount_paid}} 
                    </td>
                    <td >
                        {{plans['total_seats']}} Seats Booked
                    </td>
                    <td >
                        {{getStatus(plans.created_date,plans.validity)}}
                    </td>
                </tr>
            </tbody>
        </table>
        
      
   </div>
 </template>
 <script>
 
 import Server from './API/Server';
 import moment from "moment";
 
 export default {
   data(){
     return {
       plans:{},
       columns:['Plan','Subscribed At','Amount Paid','Users','Valid Till']
     }
   },
   methods: {
    getHumanDate: function (date) {
      var date = new Date(parseInt(date)*1000)
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = Math.floor(seconds / 31536000);

      if (interval > 1) {
        return interval + " years";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " months";
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " days";
      }
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) {
        return interval + " hours";
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
        getStatus(date,validity){
            var x = new moment(date);
            var y = new moment(Date.now());
            var duration = moment.duration(y.diff(x))
            let duration_days = Math.abs(Math.floor(duration.asDays()))

            console.log(moment.unix(date).format("MM/DD/YYYY"));
            return moment.unix(date).format("ll");
        }
         
     },
     async mounted(){
       try{
         const res = await Server.getSubscriptions();
         if(res && res.code==200){
           this.plans = res.data;
         }
       }catch(e){
         console.log(e);
       }
       
         
     }
     
 }
 </script>
 <style lang="scss" scoped>
 hr {
   height: 1px;
   border: 0;
   color: #d2d2d2;
   background-color: #d2d2d2;
 }
 
 span {
   font-size: 2vw;
   vertical-align: middle;
   padding-right: 0.2em;
 }
 
 h5 {
   margin: 15px;
 }
 
 h4.card-title {
   margin: 25px 0 15px 0;
 }
 
 .price {
   font-size: 3.5em;
   color: hsl(234, 14%, 30%);
   margin: 0 0 20px 0;
 }
 
 .master-price {
   margin-bottom: 25px;
 }
 
 
 
 .title {
   padding: 2rem 0.2rem 0.2rem 0.2rem;
   color: hsl(233, 13%, 49%);
 }
 
 .title>h1 {
   margin-bottom: 10px;
 }
 
 .sub-title {
   margin-bottom: 0;
   margin-top: 0;
   color: hsl(234, 14%, 74%);
 }
 
 .text, .card-title {
   color: hsl(233, 13%, 49%);
 }
 
 /* ------------ Grid ------------*/
 .grid-parent {
   margin-top: 60px;
   display: grid;
   grid-template-columns: repeat(2, 50%);
   justify-content: center;
 }
 
 /* ------------ Cards ------------*/
 .card {
   border-radius: 8px;
   padding: 0 10%;
 }
 
 .basic-card, .master-card {
   background-color: white;
   color: rgb(83, 83, 83);
   min-height: 60vh;
 }
 
 
 /* ------------ Buttons Style ------------*/
 .button {
   background-image: linear-gradient(to right, hsl(236, 72%, 79%), hsl(237, 63%, 64%));
   width: 100%;
   height: 35px;
   border: none;
   border-radius: 5px;
   color: white;
   padding: 5px 30px;
   text-align: center;
   text-decoration: none;
   margin: 20px 0 25px 0;
   font-weight: 700;
   letter-spacing: 1.3px;
 }
 
 
 .pro-btn:hover {
   border: 1px solid white;
   background-image: linear-gradient(to right, hsl(236, 78%, 73%), hsl(237, 63%, 64%));
   color: white;
   cursor: pointer;
 }
 
 .btn:hover {
   background-image: linear-gradient(to right, hsl(0, 0%, 100%), hsl(0, 0%, 100%));
   color: hsl(237, 63%, 64%);
   border: 1px solid hsl(237, 63%, 64%);
   cursor: pointer;
 }
 
 /* The switch - the box around the slider */
 .switch {
   position: relative;
   display: inline-block;
   width: 35px;
   height: 20px;
   bottom: 3px;
   margin-left: 20px;
   margin-right: 20px;
 }
 
 /* Hide default HTML checkbox */
 .switch input {
   opacity: 0;
   width: 0;
   height: 0;
 }
 
 /* The slider */
 .slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-image: linear-gradient(to right, hsl(236, 78%, 73%), hsl(237, 63%, 64%));
   transition: .4s;
 }
 
 .slider:hover {
   opacity: 0.5;
 }
 
 .slider:before {
   position: absolute;
   content: "";
   height: 16px;
   width: 16px;
   left: 3px;
   bottom: 2px;
   background-color: white;
   transition: .4s;
 }
 
 input:checked+.slider {
   background-image: linear-gradient(to right, hsl(235, 82%, 80%), hsl(237, 67%, 67%));
 }
 
 input:focus+.slider {
   box-shadow: 0 0 1px hsl(237, 63%, 64%);
 }
 
 input:checked+.slider:before {
   transform: translateX(13px);
 }
 
 /* Rounded sliders */
 .slider.round {
   border-radius: 34px;
 }
 
 .slider.round:before {
   border-radius: 50%;
 }
 
 /* ------------ Footer ------------*/
 footer {
   margin-top: 50px;
 }
 
 .attribution {
   font-size: 10px;
   text-align: center;
   display: block;
   color: grey;
   padding-bottom: 15px;
 }
 
 .attribution a {
   text-decoration: none;
   color: grey;
 }
 .heading_for_plans{
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 150px;
   padding: 10px;
   border-bottom: 0.1px solid;
   
   .name{
     font-size: 24px;
   }
 
   .price{
     margin-bottom: 0px;
   }
 
   .rate{
     font-size: 16px;
     margin-top: 0px;
   }
 }
 
 .oddOne{
   border-right: 0.1px solid;
 }
 
 .basic-card{
   background-color: white;
   .wrapper{
     display: flex;
     flex-direction: column;
     padding: 10px;
 
     ul{
       list-style-type: none;
     }
   }
 }
     
 </style>