<template lang="">
    <div class="card red-card" style="padding:12px 20px !important;min-height: 75vh" >
        <div style="display:flex;align-items:center; margin-top: 1vw;">
        <div style="color:#737373;font-size:40px;display:flex;align-items:center;margin-left:1.1vw;margin-bottom:4px">
                                    [ 
                                    <div style="background:#737373;color:#737373;border-radius:50%;width:27px;font-size:1vw;margin-top:1px;">R</div>
                                    ]</div>
        <div style="display: flex; flex-direction: column; align-items: baseline; margin-left: 1vw;">
            <h2 id="red_condition_header" style="text-align:center;margin-bottom:2px">Red Condition
           
        </h2>
        <span style="text-align:center">If any of the conditions is met, the customer will be marked as RED</span>
        </div>
        </div>
        <div data-v-8606c4fa="" style="display: flex; align-items: center; justify-content: space-between; margin-top: 1rem; width: 100.5%;">
            <GlobalTabs @selectTab="handleSelectTab($event)" :notIdx="true" :tabs="topTabs"/>
        </div>
        <div v-if="selectedTab=='Configured'" style="display:flex;width:100%;justify-content:center;flex-direction:column; height: 68vh;">  
            <GlobalTable v-if="configuredData.length>0" :columns="columns" :showAllTooltips="true" :tableData="configuredData" @handleAction="handleAction($event)"/>
            <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                <img src="../icons/noData.svg" style="height: 12vw;" alt="">
                <div>No data to show</div>
            </div>
    </div>

    <div v-else style="display:flex;width:100%;justify-content:center;flex-direction:column; height: 68vh;">  
        <GlobalTable v-if="notConfiguredData.length>0" :columns="columns" :tableData="notConfiguredData" @handleAction="handleAction($event)"/>
        <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <img src="../icons/noData.svg" style="height: 12vw;" alt="">
            <div>No data to show</div>
        </div>
    </div>
        <Modal  class="functionClass" :show="openRedConditon" v-if="openRedConditon">
            <i @click="openRedConditon = false" class="tim-icons close-icon icon-simple-remove"></i>

            <RedConditionForTicket @close="handleClose" :selectedBObj="selectedBusinessObject"/>
        </Modal>

</div>
</template>
<script>
import { functions, operetors } from "./Functions"
import Modal from "../components/Modal.vue";
import Server from "../components/API/Server";
import RedConditionForTicket from './RedConditionForTicket.vue';
import GlobalTabs from "../layout/dashboard/GlobalTabs.vue";
import RedConditionForOpportunities from './RedConditionForOpportunities.vue';
import GlobalTable from "../layout/dashboard/GlobalTable.vue";
// import SocketioService from "../socket.js"
export default {
    name: "red-condtion",
    components: {
        RedConditionForTicket,
        RedConditionForOpportunities,
        Modal,
        GlobalTabs,
        GlobalTable
    },
    computed: {

    },
    data() {
        return {
            columns: [{title: "Business Object", key: "business_object_name", width: '25%'}, {title: "Condition", key: "condition", width: '40%'}, {title: "Action", key: "action", width: '15%'}],
            tabs: [{ name: "Ticket", label: "ticket" },
            { name: "Oppurtunities", label: "Opportunities" }],
            selectedTabName: '',
            selectedBusinessObject: '',
            topTabs: ['Configured', 'Available'],
            selectedTab: 'Configured',
            configuredData: [],
            notConfiguredData: [],
            openRedConditon: false
        }
    },
    methods: {
        async handleClose() {
            const data = await Server.googleSheetCreds();

            localStorage.setItem("ALL_CREDS", JSON.stringify(data.data));

            var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
            let newArr = []
            creds.map(data => newArr.push(data))
            console.log(newArr)
            this.tabs = newArr;
            this.openRedConditon = false;


        },
        handleSelectTab(tab){
            this.selectedTab = tab;
        },
        handleAction(action){
            switch (action[0]) {
                case 'openEdit':
                    this.handleEdit(action[1]);
                    break;
            
                default:
                    break;
            }
        },

        changeTab(tab){
            this.selectedTab = tab;
        },

        handleEdit(u) {
            this.selectedBusinessObject = u.business_object_name
            this.openRedConditon = true
        },
        queryBuilderStr(d) {
            let str = ''
            let values = []
            let rows = []
            let keys = []
            if (!d && d.red_condition != null) {
                return ''
            }
            if (d.red_condition) {
                console.log(d.red_condition)
                let redCondition = JSON.parse(d.red_condition)
                let op
                let key
                redCondition["$and"].map((red, index) => {
                    const sleected = [];

                    values.push([])
                    if(red["$or"])
                    red["$or"].map(async (re, i) => {
                        key = Object.keys(re)[0]
                        op = operetors.filter(o => {
                            return o.query == Object.keys(re[key])[0]
                        })
                        if(op.length>0)
                        sleected.push(re[key][op[0].query])

                    })
                    rows.push({ 'key': key, operator: op[0], value: sleected })

                })
                rows.forEach((data, index) => {
                    if (data['key'] && data['operator']) {
                        str += 'IF ( '
                        str += data['key'] + ' ' + data['operator']['value'] + ' ' + data['value'] + ' )'
                        if (index != rows.length - 1)
                            str += ' AND '
                    }
                })
                return str
            }
            return 'Not Configured'
        }
    },
    async mounted() {
        const data = await Server.googleSheetCreds();

        localStorage.setItem("ALL_CREDS", JSON.stringify(data.data));
        // SocketioService.setupSocketConnection();
        console.log(this.$route.query.business_object_name, "asdasd")

        var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        let newArr = []
        creds.map(data => newArr.push(data))
        let tempArr = [];
        let nonTempArr = [];
        for(let i=0;i<newArr.length;i++){
            if(!newArr[i].red_condition){
                tempArr.push(newArr[i]);
            }else{
                nonTempArr.push(newArr[i]);
            }
        }
        this.tabs = [...nonTempArr, ...tempArr];
        console.log(this.tabs);
        for(let i=0;i<this.tabs.length;i++){
            if(this.queryBuilderStr(this.tabs[i])=='Not Configured'){
                this.notConfiguredData.push({...this.tabs[i], action: [{'img':'Edit.png', 'action': 'openEdit', 'hover': 'Edit Red Condition'}], condition: 'Not Configured'});
            }else{
                this.configuredData.push({...this.tabs[i], action: [{'img':'Edit.png', 'action': 'openEdit', 'hover': 'Edit Red Condition'}], condition: this.queryBuilderStr(this.tabs[i])});
            }
        }

    }
}
</script>
<style lang="scss">
.tabs {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid black;
    justify-content: space-around;
}

.tab {
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: 600;
    text-align: center;

    &.selected {
        margin-bottom: -2px;
        border-bottom: 3px solid blue;
    }
}

.red-card {
    padding: 15px;
}
.rowdisabled{
    opacity: 0.4;
}
</style>