<template>
    <div style="background: white;width: 70%;left: 12%;position: fixed;top: 18%; border-radius: 10px; padding: 20px;">
        <div style="display: flex; align-items: center; justify-content: space-between;">
            <div style="display: flex; justify-content: end; align-items: center; width: 60%; font-size: 20px;">Configure settings</div>
            <div><img src="../icons/Close.svg" style="cursor: pointer; height: 20px;" @click="handleClose()" alt=""></div>
        </div>
        <div style="height: 46vh;">
            <div v-if="!loading" style="display: flex;justify-content: space-between;margin-top: 2rem;">
                <div style="border: 1px solid grey;width: 40%;height: 300px;overflow: auto;padding: 10px;cursor: pointer;">
                    <div :style="{ 'border': leftSelected.includes(col) ? '1px solid green' : '', 'padding': '10px', 'font-size': '16px' }"
                        @click="handleLeftSelected(col)" v-for="col of bkeys">
                        {{ col }}
                    </div>
                </div>
                <div style="height: 300px;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                    <span @click="handleTransfer('right')"
                        :style="{ 'opacity': leftSelected.length == 0 ? '0.5' : '', 'cursor': 'pointer' }"
                        class="material-symbols-outlined">
                        <img src="../icons/downarrow.png" style="cursor: pointer; height: 20px; transform: rotate(-90deg);" alt="">
                    </span>
                    <br />
                    <span @click="handleTransfer('left')"
                        :style="{ 'opacity': rightSelected.length == 0 ? '0.5' : '', 'cursor': 'pointer' }"
                        class="material-symbols-outlined">
                        <img src="../icons/downarrow.png" style="cursor: pointer; height: 20px; transform: rotate(90deg);" alt="">
                    </span>
                </div>
                <div style="border: 1px solid grey;width: 40%;height: 300px;overflow: auto;padding: 10px;cursor: pointer;">
                    <div v-for="col of columns" style="display: flex; justify-content: space-between;">
                        <span @click="handleRightSelected(col)" :style="{ 'border': rightSelected.includes(col) ? '1px solid green' : '', 'font-size': '16px', 'padding': '10px', 'margin-top': '10px', 'display': 'flex', 'justify-content': 'space-between', 'width': '75%' }">
                            {{ col }}
                        </span>
                        <span style="padding: 10px; margin-top: 10px; display: flex; justify-content: space-between; width: 40px;">
                            <span @click="sendSelectedToTop(col)" :style="{color: (columns[0] == col) ? '#a2a5a2' : ''}" class="material-symbols-outlined"><img src="../icons/uparrow.png" style="cursor: pointer; height: 13px;" alt=""></span>
                            <span @click="sendSelectedToBottom(col)" :style="{color: (columns[columns.length-1] == col) ? '#a2a5a2' : ''}" class="material-symbols-outlined"><img src="../icons/downarrow.png" style="cursor: pointer; height: 13px;" alt=""></span>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else style="display: flex; align-items: center; justify-content: center;">
                <img src="../icons/loadingripple.svg" style="height: 200px;" alt="">
            </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: end;">
            <button @click="handleClose" style="cursor: pointer; border: 1px solid #cfcfcf; background: white; color: black; font-size: 16px; padding: 8px; width: 110px; border-radius: 8px; margin-right: 20px;">Cancel</button>
            <button @click="handleSave" style="cursor: pointer; font-size: 16px; border: none; background-color: #8484f7; color: white; padding: 8px; width: 110px; border-radius: 8px;">Save</button>
        </div>
    </div>
</template>
<script>
import MyServer from "./API/Server";
export default{
    props: {
        bObj: '',
        selectedKeys: [],
        viewType: 'user'
    },
    methods: {
        handleClose(){
            this.$emit('close');
        },
        handleTransfer(method) {
            if (method == 'right') {
                this.leftSelected.map(data => {
                    if (this.selectedBObject == "ticket" || this.selectedBObject == "Customer Invoice" || this.selectedBObject == "Opportunities") {
                        if (this.columns.length >= 15) {
                            this.$toasted.error("Only 15 columns are allowed")
                            return;
                        }
                        if (this.columns.length < 10) {
                            this.$toasted.error("Minimum 10 columns are required")
                            return;
                        }
                        this.columns.splice(this.columns.length - 2, 0, data)
                    } else {
                        const isNotUnique = this.columns.some(col => col === data);
                        if (!isNotUnique) {
                            this.columns.splice(this.columns.length - 2, 0, data)
                        }
                        this.columns = this.columns.filter(function (v, i, self) {
                            return i == self.indexOf(v);
                        });
                    }
                    const idx = this.bkeys.findIndex(d => d == data)
                    this.bkeys.splice(idx, 1)
                })
                if(this.leftSelected.length!==0){
                    this.$toasted.success("Added to right")
                }
                this.leftSelected = [];
            } else {
                const valuesArr = this.columns.filter((value, index) => {
                    return this.rightSelected.findIndex(d => d == value) == -1;
                })
                this.rightSelected.map(data=>{
                    const isNotUnique = this.bkeys.some(col => col === data);
                if (!isNotUnique) {
                    this.bkeys.splice(this.bkeys.length - 2, 0, data)
                }
                })
                this.columns = valuesArr
                if(this.rightSelected.length!==0){
                    this.$toasted.success("Added to left")
                }
                this.rightSelected = [];
                this.bkeys.sort();
            }
        },
        sendSelectedToTop(fc){
            if(fc == this.columns[0]){
                return;
            }
            console.log(fc);
            console.log(this.columns);
            const idx = this.columns.findIndex(d => d == fc);
            this.columns.splice(idx, 1);
            this.columns.unshift(fc);
            console.log(this.columns)
        },
        sendSelectedToBottom(fc){
            if(fc == this.columns[this.columns.length-1]){
                return;
            }
            console.log(fc);
            console.log(this.columns);
            const idx = this.columns.findIndex(d => d == fc);
            this.columns.splice(idx, 1);
            this.columns.push(fc);
            console.log(this.columns)
        },

        handleLeftSelected(fc) {
            if (this.leftSelected.filter(d => d == fc).length > 0) {
                const idx = this.leftSelected.findIndex(d => d == fc);
                this.leftSelected.splice(idx, 1);
                return;
            }
            this.leftSelected.push(fc);
        },
        handleRightSelected(fc) {
            if (fc == 'CSM' || fc.toLowerCase() == 'health' || fc.toLowerCase() == 'pulsesettings' || fc.toLowerCase() == 'actions') {
                this.$toasted.error("Sorry , You cannot select predefined fields")
                return;
            }
            if (this.rightSelected.includes(fc)) {
                const idx = this.rightSelected.findIndex(d => d == fc)
                this.rightSelected.splice(idx, 1)
                return;
            }
            this.rightSelected.push(fc);
        },
        generateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        async handleSave(){
            console.log('code here', this.columns);
            if(this.viewType=='user'){
                this.$emit('save', this.columns);
                this.$emit('close');
            }else{
                this.$emit('save', this.columns);
                this.$emit('close');
            }
        }
    },
    data(){
        return {
            leftSelected: [],
            rightSelected: [],
            bkeys: [],
            columns: [],
            loading: false,
            currentViewType: '',
            selectedBObject: ''
        }
    },
    async mounted(){
        this.currentViewType = this.viewType;
        console.log(this.currentViewType);
        this.selectedBObject = this.bObj;
        this.loading=true;
        if(this.currentViewType=='admin'){
            let [data1, data2] = await Promise.all([MyServer.getConfiguratorKeys(this.selectedBObject), MyServer.getConfigurator()]);
            this.bkeys = data1.data.keys;
            console.log(this.bkeys);
            let data = data2;
            console.log(data);
            if (data.data.length > 0) {
                const idx=data.data[0].feeds_configuration.findIndex(d=>d['business_object_name']==this.selectedBObject);
                if (idx>-1) {
                    let bObjKeys = data.data[0].feeds_configuration[idx].keys;
                    this.columns = [...bObjKeys];
                    console.log(this.columns)
                    for(let i=0;i<this.columns.length;i++){
                        if(this.bkeys.includes(this.columns[i])){
                            let idx = this.bkeys.indexOf(this.columns[i]);
                            this.bkeys.splice(idx, 1);
                        }
                    }
                    this.loading=false;
                    return;
                }
            }
        }else{
            let [data1] = await Promise.all([MyServer.getConfiguratorKeys(this.selectedBObject)]);
            this.bkeys = data1.data.keys;
            console.log(this.bkeys);
            let data = this.selectedKeys;
            console.log(data);
            if (data.length > 0) {
                    this.columns = this.selectedKeys;
                    for(let i=0;i<this.columns.length;i++){
                        if(this.bkeys.includes(this.columns[i])){
                            let idx = this.bkeys.indexOf(this.columns[i]);
                            this.bkeys.splice(idx, 1);
                        }
                    }
                    this.loading=false;
                    return;
            }
        }
            this.columns.unshift('account_domain');
            if (this.selectedBObject == 'Net Promoter Score') {
                this.columns = ["account_domain", "timestamp", "Email address", "score", "score_reason", "Actions"]
                this.columns = [...new Set(this.columns)]
            }
            if (this.selectedBObject == 'pulse') {
                this.columns = ["account_domain", "created_date", "Account Pulse", "reason", "description", "Created By", "Actions"]
                this.columns = [...new Set(this.columns)]
            }
            if (this.selectedBObject == 'accounts') {
                this.columns = ["account_domain", "Name", "CreatedDate", "Id", "LastModifiedDate", "Actions"]
                this.columns = [...new Set(this.columns)]
            }
            if (this.selectedBObject == 'tasks') {
                this.columns = ["account_domain", "task_type", 'task_name', 'desc', 'status', 'duedate', 'assignee', "Actions"]
                this.columns = [...new Set(this.columns)]
            }
            if (this.selectedBObject == 'ticket') {
                this.columns = ["account_domain", "created_at", "subject", 'priority', 'status', 'openDays', 'requester.email', 'assignee.email', "Actions"]
            }
            if (this.selectedBObject == 'Opportunities') {
                this.columns = ["account_domain", "createdAt", 'dealname', 'dealstage', 'amount', "closedate", "Actions"]
            }
            if (this.selectedBObject == 'Contacts') {
                this.columns = ["account_domain", "createdAt", "firstname", 'lastname', 'email', "phone", "Actions"]
            }
            this.columns.push('Actions');
            this.columns = [...new Set(this.columns)]
            console.log(this.columns);
            for(let i=0;i<this.columns.length;i++){
                if(this.bkeys.includes(this.columns[i])){
                    let idx = this.bkeys.indexOf(this.columns[i]);
                    this.bkeys.splice(idx, 1);
                }
            }
            this.loading=false;
    }
}
</script>