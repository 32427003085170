<template>
    <div v-if="!showLabel" class="objectField">
        <div class="objectField__title">
            <h2 style="text-align: center;">Select a field</h2>
        </div>
        <div class="objectField__body">
            <div class="objectField__left">
                <div class="objectField__left--block">
                    <div class="" style="display: flex;align-items: center;justify-content: space-between;">
                        <div style="margin-left: 10px;" @click="selectedTab = 1;handleSelectBusinessObject()" :class="selectedTab == 1 ? 'selected' : ''"
                            class="objectField__left--card">
                            <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                (appeqSupportedApplication.toLowerCase().includes('zoho') ? 'zoho' : appeqSupportedApplication) +
                '.com&sz=48'
                " />                            <!-- <img style="height: 20px;" src="../assets/appeq.png" /> -->
                            CRM Fields
                        </div>
                        <div style="margin-left: 10px;" @click="selectedTab = 0;handleSelectBusinessObject()" :class="selectedTab == 0 ? 'selected' : ''"
                            class="objectField__left--card">
                            <img style="height: 20px;" src="../assets/appeq.png" />
                            AppEQ Custom Fields
                        </div>
                    
                    </div>
                </div>
                <div v-if="selectedTab==0" class="" style="display: flex;align-items: end;justify-content: flex-end;">
                    <!-- <span style="color: blue;font-size: 10px;cursor: pointer;">Want to create a custom field?</span> -->
                </div>
            </div>
            <div class="objectField__right">
                <div :style="{height:selectedKey?'25vh':'40vh'}" v-if="selectedBObject" class="objectField__keys" style="display: flex; justify-content: center;">
                    <table  v-if="!loading && bkeys.length>0" style="width: 100%;">
                        <tr>
                            <th style="padding: 8px;"></th>
                            <th style="padding: 8px;">Key</th>
                            <th style="padding: 8px;">Label</th>
                            <th v-if="selectedTab==0" style="padding: 8px;">Desc</th>
                        </tr>
                        <tr style="cursor: pointer;" @click="selectedKey=key.key;enteredLabel=key.label" v-for="key of bkeys">
                            <td style="padding: 8px;" v-for="col of ['action', 'key', 'label','desc']">
                                <div class="" v-if="col == 'action'">
                                    <input v-model="selectedKey" style="margin-right: 10px;" type="radio" name="keys" :value="key.key">
                                    <span v-tooltip="key.type">
                                        <i class="material-symbols-outlined" style="font-size: 18px;margin: 0px;margin-right: 5px;">{{
                            giveIcon(key.type) }}</i>
                                    </span>
                                </div>
                                <div class="" v-else>
                                    {{ key[col] }}
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div v-else-if="!loading && bkeys.length==0" style="display: flex; align-items: center; justify-content: center;">
                        <img src="../icons/noData.svg" style="height: 200px;" alt="">
                    </div>
                    <div class="" v-else>
                        <img src="../assets/loadingRipple.svg" />
                    </div>
                </div>
                <div class="" v-else>
                    Select a business object first
                </div>
                <!-- <div class="objectField__keys">
                    <div style="display: flex;align-items: center;justify-content: space-between;" class="">
                        <div class="">Action</div>
                        <div class="">Key</div>
                        <div class="">Label</div>
                    </div>
                    <div class="key" v-for="key of keys">
                        <input style="margin-right: 10px;" type="radio" name="keys" value="key">
                        <div class="">{{ key.key }}</div>
                        <div class="">{{ key.label }}</div>

                    </div>
                </div> -->

            </div>
        </div>
        <span  v-if="selectedKey"  style="margin-top: 30px;font-size: 14px;font-weight: 600;">Label
        <span style="color: red !important;">*</span>
        </span>
        <input v-if="selectedKey" class="labelInput" v-model="enteredLabel" placeholder="Enter Label"/>
        <div class="objectField__footer">
            <button @click="handleNext" style="width: 20%;height: 35px;border-radius: 12px;">Save</button>

        </div>
    </div>
    <div class="" v-else>
        <h2 style="text-align: center;">Enter Label</h2>
        <span style="margin-top: 20px;font-size: 14px;font-weight: 600;">Selected Key</span>
        <div class="">
            {{selectedBObject}} - {{ selectedKey }}
        </div>
        <span style="margin-top: 30px;font-size: 14px;font-weight: 600;">Label</span>
        <input class="labelInput" v-model="enteredLabel" placeholder="Enter Label"/>
        <div style="margin-top: 35%;" class="objectField__footer">
            <button @click="handlePrev" style="width: 20%;height: 35px;border-radius: 12px;border: 1px solid #4e5fff;color: #4e5fff;background-color: white;">Prev</button>

            <button @click="handleNext" style="width: 20%;height: 35px;border-radius: 12px;margin-left: 10px;">Save</button>

        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from './API/Server';
import LocalUtils from './Utils/LocalUtils';
export default {
    components: { Multiselect },
    data() {
        return {
            selectedTab: 0,
            keys: [],
            showLabel:false,
            selectedKey:"",
            bkeys: [],
            icon: {
                date: 'calendar_month',
                number: 'calculate',
                text: 'text_format',
                string: 'text_format',
                object: 'data_object',
                boolean: 'toggle_on'
            },
            bObjects: [],
            enteredLabel:"",
            selectedBObject: "accounts",
            crmSelected: "",
            appeqSupportedApplication: '',
            loading:false,
        }
    },
    async mounted() {
        let credItems = JSON.parse(localStorage.getItem('ALL_CREDS'));
        console.log(credItems, 'credItems here');
        this.appeqSupportedApplication = credItems[0].appeq_supported_application;

        // const data = await Server.googleSheetCredentials(LocalUtils.getEmail())
        // console.log(data)
        // this.bObjects = data.data.map(d => d.business_object_name)
        console.log(this.selectedSumm)
        this.selectedTab=1
        this.handleSelectBusinessObject()
        if(this.selectedSumm){
            this.enteredLabel=this.selectedSumm['appeq_summary_label']
            this.selectedKey=this.extractFunc(this.selectedSumm['appeq_summary_value'])
        }
    },
    props:{
        selectedSumm:""
    },
    methods: {
        extractFunc(data){
            if(data){
                const bObject=data.toString().split("(").length>0?data.toString().split("(")[1].split(",")[0]:""
                if(data.toString().split("(").length>0){
                    return data.toString().split("(")[1].split(",")[1].replace(")","")
                }else{
                    return ""
                }
            }
            return ""
        },
        giveIcon(icon) {
            console.log(icon, 'icon type')
            if (!this.icon[icon]) {
                return "format_size"
            }
            else {
                return this.icon[icon]
            }
        },
        handleNext(){
            if(!this.enteredLabel){
                this.$toasted.error("Cannot save please enter label")
                return
            }
                this.$emit("onsave",{
                enteredLabel:this.enteredLabel,selectedObject:this.selectedBObject, selectedKey:this.selectedKey, selectedFunc:'getKeys',type:{}
            })
        },
        handlePrev(){
            this.showLabel=false
        },
        async handleSelectBusinessObject() {
            this.loading=true
                if(this.selectedTab==0){
                    this.bkeys=[]
                    const dt=(await Server.getCustomAccountfields(this.selectedBObject)).data.data
                    dt.map(d=>{
                        d.appeqKey.map(key=>{
                            this.bkeys.push({
                        key:key,
                        label:d.name,
                        desc:d.category+" "+(d.aggregateOptions?d.aggregateOptions:d.key)+(d.aggregateOptions?(" of "+ d.bObject+" " +d.performOperationOnKey):"")
                    })
                        })
                        })
                }else{
                const data = await Server.getFieldsApi("accounts")
                console.log(data.data.data)
               
                    this.bkeys = data.data.data.map(d => {
                        return {
                            key: d.key,
                            label: d.label,
                            type:d.datatype,
                        }
                    })
            }
            this.bkeys.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            this.loading=false
            
        }
    }
}
</script>
<style lang="scss" scoped>
.objectField {
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

    }

    &__keys {
        height: 40vh;
        overflow: scroll;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__left {
        flex: 1;

        &--card {
            flex: 1;
            border: 1px solid #e8e8e8;
            padding: 10px;
            border-radius: 8px;
            cursor: pointer;
        }
    }

    &__right {
        flex: 1;
        margin-left: 20px;
        height: 100%;
    }

}

.selected {
    border: 1px solid blue;
}
.labelInput{
    min-height: 50px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
    margin-right: auto;
    width: 100%;
}

tr:hover{
    background-color: #e8e8e8;
}
.key {
    display: flex;
    align-items: center;
    justify-content: space-between;
}</style>