<template>
    <div class="redConditionDiv">
        <h3>Red Conditions for <span style="text-transform: capitalize;">{{ selectedBObj }}</span></h3>
        <span>You can configure your health condition to show red based on your needs</span>
        <div class="redbody">
            <div class="row">
                <span>Key</span>
                <span>Operator</span>
                <span>Value</span>
            </div>
            <div v-for="(row, index) of rows" class="row">

                <div class="dropdown1">
                    <multiselect :loading="loading" :searchable="true" @select="onSelectKeys(index)" v-model="row['key']"
                    :hide-selected="true"
                        :options="keys[index][0]" placeholder="Choose keys">
                    </multiselect>
                </div>
                <div class="dropdown1">
                    <multiselect :searchable="true" v-if="row['function'] != 'VALUE'" v-model="row['operator']"
                    :hide-selected="true"
                        :options="operetors" placeholder="Choose value"></multiselect>
                </div>
                <div class="dropdown1">
                    <multiselect :append-new-option="true" :create-tag="true" :searchable="true" mode="tags"
                    :hide-selected="true"
                        :options="values[index]" v-model="row['value']" placeholder="Choose value"></multiselect>
                </div>
                <!-- <div class="dropdown1">
                    <input v-if="row['function'] != 'VALUE'" v-model="row['count']">
                </div> -->
                <div @click="addRows()" class="add"> + ADD</div>
                <div v-if="keys.length > 1" @click="handleRemoveClick(index)" class="addField">
                    <img src="../assets/img/feed_close.svg" />
                </div>
                <div class="addField" v-else>
                    <img style="opacity: 0.5;" src="../assets/img/feed_close.svg" />
                </div>
            </div>
        </div>
        <br />
        <span>Your query to show red condition : </span>
        <br />
        <span class="queryBuilder">{{ queryBuilderStr }}</span>
        <!-- <span v-if="!selectedFunction">The query will show up once you enter any data</span> -->
        <div class="finalrow">
            <div class="clear">Clear All</div>
            <button @click="saveRed()">
                SAVE
            </button>
        </div>


    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { functions, operetors } from "./Functions"
import Server from '@/components/API/Server.js';
import LocalUtils from '../components/Utils/LocalUtils';
export default {
    props: {
        selectedBObj: {
            type: String
        }
    },
    components: {
        Multiselect
    },
    computed: {
        queryBuilderStr() {
            let str = ''
            this.rows.forEach((data, index) => {
                if (data['key']) {
                    str += 'IF ( '
                    str += data['key'] + ' ' + data['operator'] + ' ' + data['value'] + ' )'
                    if (index != this.rows.length - 1)
                        str += ' AND '
                }
            })
            return str
        }
    },
    data() {
        return {
            loading: false,
            keys: [[]],
            data: [],
            rows: [{ function: '', key: '', value: [''], operator: '', count: '' }],
            operetors: operetors,

            selectedCount: 1,
            selectedFunction: '',
            selectedValue: '',
            values: [[]],
            selectedKey: '',
            functions: functions
        }
    },
    watch: {
        selectedBObj: function () {
            this.getInitData()
        }
    },
    mounted() {
        this.getInitData()
    },
    methods: {
        async saveRed() {
            try {
                let finalQuery = { "$and": [] }

                this.rows.map(data => {
                    const op = operetors.filter(d => d.value == data['operator'])[0]['query']
                    let orQ = { "$or": [] }
                    data['value'].map(d => orQ["$or"].push({ [data.key]: { [op]: d } }))
                    finalQuery["$and"].push(orQ)
                })
                await Server.insertRedCond(finalQuery, this.selectedBObj)
                this.$toasted.success("Configuration saved successfully");
                this.$emit("close")
                Server.onResync(LocalUtils.getEmail(), "Opportunities")
                // setTimeout(() => {
                //     window.location.reload()                    
                // }, 1000);
            } catch (error) {
                console.log(error)
                this.$toasted.error("No configuration defined to be saved");
            }
        },

        addRows() {
            this.keys.push([...this.keys[this.keys.length - 1]])
            this.values.push([...this.values[this.values.length - 1]])
            this.rows.push({ function: '', key: '', value: [''], operator: '', count: '' })

            console.log(this.keys);
        },
        async getInitData() {
            try {
                this.loading = true;
                const creds = await Server.googleSheetCreds()
                console.log(creds)
                const data = await Server.getConfiguratorKeys(this.selectedBObj)
                this.keys[0].push(data.data.keys.map((d) => d));

                creds.data.forEach(d => {
                    if (d.business_object_name == 'Opportunities' && d.red_condition) {
                        this.rows = []
                        this.values = []
                        d.red_condition = JSON.parse(d.red_condition)
                        let op
                        let key
                        d.red_condition["$and"].map((red, index) => {
                            this.values.push([])
                            red["$or"].map((re, i) => {
                                key = Object.keys(re)[0]
                                op = operetors.filter(o => {
                                    console.log(o)
                                    return o.query == Object.keys(re[key])[0]
                                })
                                this.values[index].push(re[key][op[0].query])

                            })
                            this.rows.push({ 'key': key, operator: op[0].value, value: this.values[index] })
                            this.keys.push([...this.keys[this.keys.length - 1]])

                        })
                    }
                })
                this.loading = false;
            } catch (error) {
                console.log(error)
            }
        },
        async onSelectKeys(index) {
            const res = await Server.getValue(this.rows[index].key, this.selectedBObj);
            console.log(res);
            this.values[index] = [...res.data]
            this.values[index] = this.values[index].filter(d => d != null)
            if (this.rows[index]['function'] == 'AGE') {
                this.values[index] = [];
                this.values[index] = ['TODAY()']
            }
            console.log(this.values[index]);
        },
        handleSelectFunc(index) {
            this.selectedCount = 1;
            if (this.selectedFunction == 'AGE') {
                this.keys[index] = this.keys[index].filter(d => d.toString().toLowerCase().includes("date"))
            }
            console.log(this.keys)
        },
        handleRemoveClick(index) {
            try {
                if (this.rows.length == 1) {
                    return;
                }
                this.rows.splice(index, 1);
            } catch (e) {
                condole.log(e);
            }
        },
    }

}
</script>

<style lang="scss" scoped>
.redConditionDiv {
    margin-top: 50px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    padding-left: 5px;
    width: 100%;

    span {
        flex: 0.31;
        text-align: left;

        &:first-child {
            margin-left: 10px;
        }
    }
}

.dropdown1 {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
}

.lastrow {
    display: flex;
    align-items: center;
}

.clear {
    color: blue;
    cursor: pointer;
}

.add {
    color: blue;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.remove {
    color: red !important;
}

input {
    align-items: center;
    background: var(--ms-bg, #fff);
    border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
    border-radius: var(--ms-radius, 4px);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: var(--ms-font-size, 1rem);
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2);
    outline: none;
    position: relative;
    width: 100%;
}

.finalrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        margin-left: 10px;
        background-color: blue;
        color: white;
        border-radius: 20px;
        width: 120px;
        height: 30px;
        border: none;
        cursor: pointer;
    }

    span {
        cursor: pointer;

    }
}

.queryBuilder {
    font-weight: 600;
    font-size: 18px;
}

.redbody {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-thumb {
        width: 0px;
        background-color: white;
    }
}

::-webkit-scrollbar-thumb {
    width: 0px;
}
.addField{
    cursor: pointer;
}
</style>